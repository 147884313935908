/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { MenuItem } from "./MenuItem";
import { KTSVG } from "../../../helpers";

export function MenuInner2() {

    const handlesignout = () => {
        sessionStorage.clear();
        window.location.reload();
    }
    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="menu-item d-flex flex-row justify-content-start align-items-center mb-1">
                        <MenuItem to="/auth/login" title="">
                            <h3 className="card-title symbol symbol-40px me-5">
                <span className="symbol-label bg-primary">
                  <KTSVG
                      className="svg-icon-1 svg-icon-white"
                      path="/media/icons/duotone/Communication/Address-card.svg"
                  />
                </span>
                            </h3>
                            <h1 className="fs-5 fw-bolder">Espace Médecin</h1>
                        </MenuItem>
                    </div>
                </div>
            </div >
        </>
    );
}
