import React, { useState, useEffect } from "react";
import { Link, Route, useLocation } from "react-router-dom";
import axios from "../axios";
import { PageTitle } from "../../_start/layout/core";
import i18next from "i18next";
import SyncLanguage from "./SyncLanguage";
import { CliniqueName, ServiceData } from "../../_start/layout/core";
import { sortArrayByFirstLetter } from "./Sortlogic";
import TitlePage from "./Goback";
import {KTSVG} from "../../_start/helpers";
import {datacliniques} from "./DataClinique";


export function SelectService() {
  useEffect(() => {
    GetServices();
  }, []);

  SyncLanguage();
  // window.history.pushState({}, document.title, window.location.href);
  // window.onpopstate = function(event: PopStateEvent) {
  //     window.history.go(1);
  //   };

  const [clinicName, setclinic] = useState(
    sessionStorage.getItem("cliniqueName")
  );

  const [services, setServices] = useState<ServiceData[]>([]);
  const [error, seterror] = useState("");

  const [tokenclinic, settokenclinic] = useState(
    sessionStorage.getItem("tokenConsolidationClinic")
  );
  const params = {
    tokenConsolidationClinic: tokenclinic, // Replace with the actual value
  };
  const matchingClinic = datacliniques.find(
      (localClinic) => localClinic.clinic_name === clinicName
  );
 console.log(matchingClinic)

  const getTokenService = (tokenservice: string, service_name: string) => {
    sessionStorage.setItem("tokenConsolidationService", tokenservice);
    sessionStorage.setItem("service_name", service_name);
    console.log("servowa", tokenservice);
  };

  const GetServices = () => {
    axios.get("/service/services-by-clinic", { params }).then((response) => {
      if (response.data.params) {
        setServices(response.data.params);
      } else if (response.status === 300) {
        seterror(i18next.t("error1_login"));
      } else {
        alert("An error occurred.");
      }
    }).catch((error) => {
      console.error("Axios Error:", error);
      seterror("Network Error");
    });
  };
  const SortedServices = sortArrayByFirstLetter(services);

  return (
    <>
      <div className={`card mb-6   bg-success shadow`} >
        {/* <!--begin::Header--> */}
        <div
            className={`card-header border-0 p-7  `}
            style={{ justifyContent: "start" }}
        >
          <div
              className="d-flex">
              <div className="symbol symbol-40px symbol-2by3 me-4 border border-muted shadow "
                   style={{ height: "70px", width: "100px" }}>
                  <img
                      alt=""
                      src={matchingClinic?.img2}
                      className="mw-100"
                      style={{ height: "70px", width: "100px" }}
                  />
              </div>

            {/* begin::Section */}
            <div className="d-flex align-items-start">
              {/* begin::Title */}
              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                <a
                    href=""
                    className="text-white fw-bolder  fs-6 "
                >
                  {matchingClinic?.clinic_name}
                </a>

                <span className="text-muted fw-bold fs-7">
                                <div className="rating-label checked">
                                  <KTSVG
                                      path="/media/icons/duotone/General/Star.svg"
                                      className="svg-icon svg-icon-3 align-text-bottom"
                                  />{" "}
                                  <span className="text-white fw-bold">
                                    {matchingClinic?.rating}
                                  </span>
                                  <span className="text-white fw-bold">
                                    , ({matchingClinic?.avis}) avis
                                  </span>
                                </div>
                              </span>
                <span className="text-white fw-bold fs-7 my-1">
                                Ouvert 24/7
                              </span>
              </div>
              {/* end::Title */}

              {/* begin::Info */}
              <div className="text-end py-lg-0 py-2">
                {/* <span className="text-gray-800 fw-bolder fs-3">24,900</span>
              <span className="text-muted fs-7 fw-bolder d-block">votes</span> */}
              </div>
              {/* end::Info */}
            </div>
            {/* end::Section */}
          </div>
        </div>

        {/* <!--end::Header--> */}

        {/* <!--begin::Body--> */}
      </div>


      <div className="col-lg-12">
        <div className="row g-5">
          {SortedServices.map((service, index) => (
            <div
              className="col-sm-4"
              key={index}
              onClick={() =>
                getTokenService(
                  service.token_consolidation_service,
                  service.service_name
                )
              }
            >
              <Link
                to="/dashboard"
                href=""
                className="card card-custom bg-white text-black  hoverable min-h-125px shadow mb-5"
              >
                <div className="card-body d-flex flex-column flex-center">
                  <h3 className="fs-6 mb-2  fw-bolder">
                    {service ? (
                      <h3 className="card-title fs-6 text-black text-center">
                        {service.service_name} ({service.nmbr_patients}) 
                      </h3>
                    ) : (
                      "Aucun Service"
                    )}
                  </h3>
                  {/* <p className="mb-0 text-gray-600">onth</p> */}
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
