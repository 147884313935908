import React, { useState, useEffect } from "react";
import { Link, Route, useLocation } from "react-router-dom";
import axios from "../axios";
import { PageTitle } from "../../_start/layout/core";
import i18next from "i18next";
import SyncLanguage from "./SyncLanguage";
import { LocationState } from "../../_start/layout/core";

interface ClinicData {
  clinic: string;
  tokenconsolidationclinic: string;
}

export function Selectfeuille() {
  SyncLanguage();
  const location = useLocation();
  const Onedossiermedicale = (location.state as LocationState)?.Onedossiermedical;
  {
    console.log(Onedossiermedicale,"select feuille");
  }
  return (
    <>
      <PageTitle>{i18next.t("select-feuille")}</PageTitle>
      {/* <div className="row g-5">
        <div className="col-lg-4">
            {clinic.map((clinique,index)=>(
                <Link to="/dashboard" key={index} >
                <div className="card card-custom shadow mb-5 active" onClick={() => getTokenClinic(clinique.tokenconsolidationclinic)}>
                    <div className="card-header text-center">
                      {clinic ? <h3 className="card-title text-hover-primary">{clinique.clinic}</h3> : "Aucun Hopital" }
                        
                    </div>
                   
                </div>
            </Link>
            ))}
            
        </div>
    </div> */}
      <div className="col-lg-12">
        <div className="row g-5">
          {Onedossiermedicale?.map((Onedossier, index) => (
            <div
              className="col-sm-4"
              key={index}
             
            >
              <Link
                className="card card-custom gradients hoverable min-h-125px shadow-none mb-5"
                to={{
                  pathname: `/DetailFeuilledesoin`,
                  state: { OnedossiermedicaltoDetail: Onedossiermedicale[0]  },
                }}
              >
                <div className="card-body d-flex flex-column flex-center">
                  <h3 className="fs-3 mb-2 text-white fw-bolder">
                    {Onedossier ? (
                      <h3 className="card-title text-center text-hover-inverse white">
                        {Onedossier.type.replace('CDM', '').replace(/([A-Z])/g, ' $1').trim()}
                      </h3>
                    ) : (
                      "Aucun Hopital"
                    )}
                  </h3>
                 
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
