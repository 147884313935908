export const lesfeuilles = {
  CDMFeuilleSurveillance: {
    abbreviation: "Rea",
    color: "fs",
    clean_name: "Feuille Surveillance",
  },
  CDMFeuilleSurveillanceRea: {
    abbreviation: "Rea",
    color: "fsr",
    clean_name: "Feuille Surveillance Rea",
  },
  CDMFeuilleAnesthesieBloc: {
    abbreviation: "Bloc",
    color: "fabi",
    clean_name: "Feuille Anesthesie Bloc",
  },
  CDMFeuilleSurveillanceHospit: {
    abbreviation: "Hospit",
    color: "raspberry",
    clean_name: "Feuille Surveillance Hospit",
  },
  CDMFeuilleMaternité: {
    abbreviation: "Maternité",
    color: "blueSky",
    clean_name: "Feuille Maternité",
  },
  CDMFeuilleBiologique: {
    abbreviation: "Biologique",
    color: "green",
    clean_name: "Feuille Biologique",
  },
  CDMFeuilleRadiologique: {
    abbreviation: "Radiologique",
    color: "gris",
    clean_name: "Feuille Radiologique",
  },
  CDMFeuilleChimioThérapie: {
    abbreviation: "FCT",
    color: "orange",
    clean_name: "Feuille ChimioThérapie",
  },
  CDMFeuilleSurveillanceUsi: {
    abbreviation: "Usi",
    color: "brown",
    clean_name: "Feuille Surveillance Usi",
  },
  CDMFicheNaissance: {
    abbreviation: "Naissance",
    color: "vanusa",
    clean_name: "Feuille Naissance",
  },
  CDMFeuilleGazDuSang: {
    abbreviation: "FGS",
    color: "red",
    clean_name: "Feuille Gaz Du Sang",
  },
  CDMFeuilleBactériologie: {
    abbreviation: "FBC",
    color: "yellow",
    clean_name: "Feuille Bactériologie",
  },
  CDMFeuilleUrgence: {
    abbreviation: "Urgence",
    color: "urgence",
    clean_name: "Feuille Surveillance Urgence",
  },
  // Add more types and their corresponding abbreviations and colors as needed
};
