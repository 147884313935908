import React, { useEffect, useState } from "react";
import { KTSVG } from "../../_start/helpers";
import { Dropdown1 } from "../../_start/partials";
import { Link } from "react-router-dom";
import { Reglement } from "../../_start/layout/core";
import SyncLanguage from "./SyncLanguage";
import axios from "../axios";
import moment from "moment/moment";
import { LocationState } from "../../_start/layout/core";
import { useLocation } from "react-router-dom";
import { sortArrayAsc } from "./Sortlogic";


export function MeshonorairesDetails() {  
  useEffect(() => {
    GetreglenemtDetails();
  }, []);
  const itemsPerPage = 15;
  const [reglementdetails, setreglementdetails] = useState<Reglement[]>([]);
  const [errors, seterror] = useState("");
  const location = useLocation();
  const detailsreglement = (location.state as LocationState)?.detailsreglement;
  const [counter, setCounter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  SyncLanguage();
  const toggleSortByNumReg = () => {
    setreglementdetails(sortArrayAsc(reglementdetails, "num_reg", counter));
    setCounter(counter + 1);
  };

  const totalPages = Math.ceil(reglementdetails.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = reglementdetails.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };




  const params = {
    num_reglement: detailsreglement?.num_reg, // Replace with the actual value
  };

  const GetreglenemtDetails = () => {
    axios
      .get("/patient/details-reglements-honoraires", { params })
      .then((response) => {
        if (response.data.params) {
          // console.log(response.data.params)
          setreglementdetails(response.data.params);
        } else if (response.status === 300) {
          seterror("error");
        } else {
          alert("An error occurred.");
        }
      });
  };

  SyncLanguage();
  let totalMtBrut = 0;
  let totalMtnet = 0;

  return (
    <div className={`card`}>
      {/* begin::Header*/}
      <div className="card-header align-items-center border-0 mt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">Mes Honoraires </span>
          <div
            className="col-lg-12 d-flex justify-content-between"
            style={{ width: "200px" }}
          >
            <div className="row">
              {reglementdetails.slice(0, 1).map((regl, index) => {
                return (
                  <>
                    <span className="text-muted mt-2 fw-bold fs-7">
                      Num reglement : {regl.num_reglement}
                    </span>
                    <span className="text-muted mt-2 fw-bold fs-7">
                      Date de paiement :{" "}
                      {moment(regl.date_paiement, "YYYY-MM-DD HH:mm:ss").format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                    <span className="text-muted mt-2 fw-bold fs-7">
                      Type de reglement : {regl.type_reg}
                    </span>
                  </>
                );
              })}
            </div>
          </div>
        </h3>
        <div className="card-toolbar">
          {/* begin::Dropdown*/}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="hover"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTSVG
              path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg"
              className="svg-icon-1"
            />
          </button>
          <Dropdown1 />
          {/* end::Dropdown*/}
        </div>
      </div>
      <div className="p-2">
        {/* <ul className="nav nav-tabs nav-line-tabs mb-5 m-5 fs-6">
  <li className="nav-item">
    <a
      className="nav-link active"
      data-bs-toggle="tab"
      href="#kt_tab_pane_1"
    >
      Link 1
    </a>
  </li>
  <li className="nav-item">
    <a
      className="nav-link"
      data-bs-toggle="tab"
      href="#kt_tab_pane_2"
    >
      Link 2
    </a>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div
    className="tab-pane fade active show"
    id="kt_tab_pane_1"
    role="tabpanel"
  > */}

        <div className="table-responsive">
          <table className="table table-stripe  gy-7 gs-7 text-center align-middle">
            <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                <th className="min-w-100px">Numero de dossier</th>
                <th className="min-w-100px">Date entree/sortie</th>
                <th className="min-w-100px">Patient</th>
                <th className="min-w-100px">Montant Brut</th>
                <th className="min-w-100px">Montant Retenu</th>
                <th className="min-w-100px">Montant Net</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((regl, index) => {
                totalMtBrut += parseFloat(regl.mt_brut);
                if (regl.mt_net !== null) {
                  totalMtnet += parseFloat(regl.mt_net);
                }
                return (
                  <tr key={index}>
                    <td>{regl.numero_dossier}</td>
                    <td>
                      {moment(regl.date_entree, "YYYY-MM-DD HH:mm:ss").format(
                        "DD/MM/YYYY"
                      )}
                      --
                      {moment(regl.date_sortie, "YYYY-MM-DD HH:mm:ss").format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                    <td>{regl.patient}</td>
                    <td>{parseInt(regl.mt_brut).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DH</td>
                    <td>{regl.retenu !== null ? parseInt(regl.retenu).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " DH" : 0}</td>
                    <td>{parseInt(regl.mt_net).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DH</td>
                  </tr>
                );
              })}
              {console.log(totalMtBrut)}
              <tr>
                <td></td>
                <td></td>
                <td>
                  <span className="badge gradients fs-5">TOTAL</span>
                </td>
                <td>{totalMtBrut} DH</td>
                <td></td>
                <td>{totalMtnet} DH</td>
              </tr>
            </tbody>
          </table>
          <ul className="pagination mb-2" >
            <li
              className={`page-item previous ${
                currentPage === 1 ? "disabled" : ""
              }`}
              onClick={() => handlePreviousPage()}
            >
              <span className="page-link">
                <i className="previous"></i>
              </span>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                className={
                  currentPage === index + 1 ? "page-item active" : "page-item"
                }
                key={index}
                onClick={() => handlePageChange(index + 1)}
              >
                <span className="page-link">{index + 1}</span>
              </li>
            ))}

            <li
              className={`page-item next ${
                currentPage === totalPages ? "disabled" : ""
              }`}
              onClick={() => handleNextPage()}
            >
              <span className="page-link">
                <i className="next"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
    2
  </div>
</div>
</div> */}
    </div>
  );
}
