import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "../axios";
import i18next from "i18next";
import SyncLanguage from "./SyncLanguage";
import { Reglement, NonReglé } from "../../_start/layout/core";
import { calculateSumOfProperty, filterObjectsByDateRange } from "./Sortlogic";
import ContentLoader from "react-content-loader";
import TitlePage from "./Goback";

interface ClinicData {
  clinic: string;
  tokenconsolidationclinic: string;
}

type Props = {
  className: string;
};

export const MeshonorairesAccueil: React.FC<Props> = ({ className }) => {
  const [reglement, setreglement] = useState<Reglement[]>([]);
  const [reglemente, setreglemente] = useState<Reglement[]>([]);
  const [reglementes, setreglementes] = useState<Reglement[]>([]);
  // const [reglementnonregle, setreglementnonregle] = useState<NonReglé[]>([]);
  const [errors, seterror] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [isActive, setisactive] = useState(58);
  const [loaderspinner, setloaderspinner] = useState(true);

  useEffect(() => {
    Getreglenemt();
    // GetreglenemtNonRegle();
  }, []);

  const Getreglenemt = () => {
    axios.get("/patient/reglements-honoraires").then((response) => {
      if (response.data.params) {
        // console.log(response.data.params)
        setreglement(response.data.params);
        setreglemente(response.data.params);
        setreglementes(response.data.params);
        setloaderspinner(false);
      } else if (response.status === 300) {
        seterror("error");
      } else {
        alert("An error occurred.");
      }
    }).catch((error) => {
      console.error("Axios Error:", error);
      seterror("Network Error");
    });
  };
  // const GetreglenemtNonRegle = () => {
  //   axios.get("/medecin/honoraire-non-regler").then((response) => {
  //     if (response.data.params) {
  //       setreglementnonregle(response.data.params);
  //     } else if (response.status === 300) {
  //       seterror("error");
  //     } else {
  //       alert("An error occurred.");
  //     }
  //   }).catch((error) => {
  //     console.error("Axios Error:", error);
  //     seterror("Network Error");
  //   });
  // };

  const totalhonoraire = useMemo(
    () => calculateSumOfProperty(reglementes, "mt_net"),
    [reglementes]
  );
  // const totalhonoraireNonRegle = useMemo(
  //   () => calculateSumOfProperty(reglementnonregle, "mt_brut"),
  //   [reglementnonregle]
  // );
  SyncLanguage();
  function getUniqueCodeClinicValues(objects: Reglement[]): string[] {
    const uniqueValues: Set<string> = new Set();
    objects.forEach((obj) => {
      uniqueValues.add(obj.code_clinic);
    });
    const uniqueValuesArray: string[] = Array.from(uniqueValues);
    return uniqueValuesArray;
  }
  const uniqueCodeClinicValues = getUniqueCodeClinicValues(reglemente);

  const handleFilterperClinic = (clinique: string, idx: number) => {
    setreglementes(reglement);
    setisactive(idx);
    setreglementes((prevReglements) =>
      prevReglements.filter((item) => item.code_clinic === clinique)
    );
    console.log(reglement, "filter per clinic");
  };

  const handleResetFilter = () => {
    setreglementes(reglement);
    setisactive(58);
  };
  return (
    <>
      <TitlePage title={i18next.t("meshonoraire-accueil")} />

      <div className={`card ${className}`}>
        <div className="" style={{ padding: "1.8rem" }}>
          {loaderspinner ? (
            <>
              <ContentLoader />
              <ContentLoader />
              
            </>
          ) : reglement.length === 0  ? (
            <div className="text-center text-muted">No honoraires records</div>
          ) : (
            <div className="col-lg-12">
              <div className="toolbar pb-5" id="kt_toolbar">
                <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                  <div className="d-flex align-items-center flex-nowrap text-nowrap overflow-auto py-1">
                    <button
                      aria-current="page"
                      className={`btn btn-active-secondary border fw-bolder ms-3 ${
                        isActive === 58 ? "active" : ""
                      }`}
                      onClick={handleResetFilter}
                    >
                      Tous
                    </button>
                    {uniqueCodeClinicValues.map((clinique, idx) => {
                      return (
                        <button
                          aria-current="page"
                          className={`btn btn-active-secondary border fw-bolder ms-3 ${
                            isActive == idx ? "active" : ""
                          }`}
                          onClick={() => {
                            handleFilterperClinic(clinique, idx);
                          }}
                        >
                          {clinique}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row g-5">
                <div className="col-sm-4">
                  <div className="card card-custom gradients hoverable min-h-125px shadow-none mb-5">
                    <div
                      className="card-body d-flex flex-start"
                      style={{
                        paddingTop: "2rem",
                        paddingRight: "2.25rem",
                        paddingBottom: "7px",
                        /* paddingLeft: '0.25rem' (optional, commented out) */
                      }}
                    >
                      <h3 className="fs-4 mb-2 text-white fw-bolder">
                        <h3 className="card-title text-center text-hover-inverse white">
                          Total Honoraires :{" "}
                        </h3>
                      </h3>
                      {/* <p className="mb-0 text-gray-600">onth</p> */}
                    </div>
                    <div
                      className="d-flex flex-start justify-content-end"
                      style={{ padding: "1rem 2.25rem" }}
                    >
                      <h1 className="fs-5 mb-2 text-white fw-bolder">
                        <h1
                          className="text-center text-hover-inverse white"
                          style={{ fontSize: "2.5rem" }}
                        >
                          {(totalhonoraire )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                          DH{" "}
                        </h1>
                      </h1>
                      {/* <p className="mb-0 text-gray-600">onth</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <Link
                    to="/meshonoraires"
                    className="card card-custom gradients hoverable min-h-125px shadow-none mb-5"
                  >
                    <div
                      className="card-body d-flex flex-start"
                      style={{
                        paddingTop: "2rem",
                        paddingRight: "2.25rem",
                        paddingBottom: "7px",
                        /* paddingLeft: '0.25rem' (optional, commented out) */
                      }}
                    >
                      <h3 className="fs-4 mb-2 text-white fw-bolder">
                        <h3 className="card-title text-center text-hover-inverse white">
                          Dossiers Réglé (XX) :{" "}
                        </h3>
                      </h3>
                      {/* <p className="mb-0 text-gray-600">onth</p> */}
                    </div>
                    <div
                      className="d-flex flex-start justify-content-end"
                      style={{ padding: "1rem 2.25rem" }}
                    >
                      <h1 className="fs-5 mb-2 text-white fw-bolder">
                        <h1
                          className="text-center text-hover-inverse white"
                          style={{ fontSize: "2.5rem" }}
                        >
                          {totalhonoraire
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                          DH{" "}
                        </h1>
                      </h1>
                      {/* <p className="mb-0 text-gray-600">onth</p> */}
                    </div>
                  </Link>
                </div>
                {/* <div className="col-sm-4">
                  <Link
                    to="/MeshonorairesNonRegle"
                    className="card card-custom gradients hoverable min-h-125px shadow-none mb-5"
                  >
                    <div
                      className="card-body d-flex flex-start"
                      style={{
                        paddingTop: "2rem",
                        paddingRight: "2.25rem",
                        paddingBottom: "7px",
                        
                      }}
                    >
                      <h3 className="fs-4 mb-2 text-white fw-bolder">
                        <h3 className="card-title text-center text-hover-inverse white">
                          Dossiers non Réglé (XX) :{" "}
                        </h3>
                      </h3>
                     
                    </div>
                    <div
                      className="d-flex flex-start justify-content-end"
                      style={{ padding: "1rem 2.25rem" }}
                    >
                      <h1 className="fs-5 mb-2 text-white fw-bolder">
                        <h1
                          className="text-center text-hover-inverse white"
                          style={{ fontSize: "2.5rem" }}
                        >
                          {totalhonoraireNonRegle
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                          DH{" "}
                        </h1>
                      </h1>
                     
                    </div>
                  </Link>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
