import React from 'react';
import moment from 'moment/moment'; // Make sure to import moment
import { KTSVG } from '../../../_start/helpers';
import { Operationdata } from '../../../_start/layout/core';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
 // Adjust the path accordingly



interface OperationsMypage {
  operation: Operationdata[];
  loaderspinner: boolean;
}

const OperationsMypage: React.FC<OperationsMypage> = ({ operation, loaderspinner }) => {
  return (
    <div className="col-lg-4">
      <div className="card card-custom card-flush shadow mb-5">
        <div className="card-header">
          <h3 className="card-title symbol symbol-40px me-5">
            <span className="symbol-label bg-success">
              <i className="fas fa-procedures fs-1x text-white" style={{ fontSize: "18px" }}></i>
            </span>
            <span className="text-success fw-bolder" style={{ marginLeft: "10px" }}>
              Opération <span className="fs-6 text-success fw-bold">({operation.length})</span>
            </span>
          </h3>
          <div className="card-toolbar">
            {/* view all */}
            <button
                type="button"
                className="btn btn-sm btn-color-white"
                style={{ display: "contents" }}
            >
              {loaderspinner ? (
              <ContentLoader />
          ) : operation.length === 0 ? (
              <div className="text-center text-muted"></div>
          ): (
              <Link
                  className="text-muted"
                  to={{
                    pathname: `/Operation`,
                    state: { operationdata: operation },
                  }}
              >
                Voir Tous
              </Link>)}
            </button>
            {/* end::Dropdown */}
          </div>
        </div>
        <div className="card-body">
          {loaderspinner ? (
            <ContentLoader />
          ) : operation.length === 0 ? (
            <div className="text-center text-muted">No operations</div>
          ) : (
            operation.map((operatione, index) => (
              <div key={index}>
                <span className="text-success fw-bold fs-5">
                  {operatione.acte}
                </span>
                <div className="details">
                  <span className="text-black fw-bold fs-7"> Le {moment(operatione.date_operation, 'YYYY-MM-DD HH:mm:ss').format('D MMMM YYYY')}</span>
                  <br />
                  <span className="text-black fw-bold fs-7">{operatione.operateur}</span>
                  <br />
                  <span className="text-black fw-bold fs-7">{operatione.b_salle_op}</span>
                </div>
                {index !== operation.length - 1 && index !== 2 && (
                    <div className="separator separator-dotted my-3"></div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default OperationsMypage;
