import React, { useEffect, useState } from "react";

import { NonReglé } from "../../_start/layout/core";
import SyncLanguage from "./SyncLanguage";
import axios from "../axios";
import TitlePage from "./Goback";


type Props = {
  className: string;
};

export const MeshonorairesNonRegle: React.FC<Props> = ({ className }) => {
  const itemsPerPage = 20;

  const [reglementnonregle, setreglementnonregle] = useState<NonReglé[]>([]);
  const [filteredData, setFilteredData] =
    useState<NonReglé[]>(reglementnonregle);
  console.log(filteredData, "filtred");
  const [errors, seterror] = useState("");
  const [counter, setCounter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    GetreglenemtNonRegle();
  }, []);

  const GetreglenemtNonRegle = () => {
    axios.get("/medecin/honoraire-non-regler").then((response) => {
      if (response.data.params) {
        setreglementnonregle(response.data.params);
        setFilteredData(response.data.params); // Set filtered data initially
        console.log(response.data.params, "initial data"); // Log the initial data
      } else if (response.status === 300) {
        seterror("error");
      } else {
        alert("An error occurred.");
      }
    });
  };

  SyncLanguage();
  //   const toggleSortByNumReg = () => {
  //     setreglementnonregle(sortArrayAsc(reglementnonregle, "num_reg", counter));
  //     setCounter(counter + 1);
  //   };

  const totalPages = Math.ceil(reglementnonregle.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = reglementnonregle.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };
  const thTdStyle = {
    position: "sticky",
    left: "0px",
    backgroundColor: "grey",
  };

  const [selectedFilter, setSelectedFilter] = useState<string>("all");
  useEffect(() => {
    console.log(filteredData);
  }, [filteredData]);
  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(event.target.value);
    applyFilter(event.target.value);
  };

  const applyFilter = (filterType: string) => {
    switch (filterType) {
      case "enInstance":
        setFilteredData(
          reglementnonregle.filter((item) => item.eninstance === 1)
        );
        break;
      case "impute":
        setFilteredData(
          reglementnonregle.filter((item) => item.impute !== "NULL")
        );
        break;
      default:
        setFilteredData(reglementnonregle);
        break;
    }
  };

  return (
    <>
    <TitlePage title="Les Honoraires Non Reglé"/> 
    <div className={`card ${className}`}>
      {/* begin::Header*/}
      <div className="card-header align-items-center border-0 mt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">
            {" "} Les Honoraires Non Reglé
          </span>
        </h3>
        <div className="card-toolbar">
          {/* begin::Dropdown*/}
          <select
            id="filterSelect"
            className="form-select"
            value={selectedFilter}
            onChange={handleFilterChange}
          >
            <option value="all">All</option>
            <option value="enInstance">En Instance</option>
            <option value="impute">Impute</option>
          </select>

          {/* end::Dropdown*/}
        </div>
      </div>
      <div className="p-1">
        <div className="table-responsive">
          <table className="table table-striped gy-7 gs-7 text-center">
            <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                <th
                  className="min-w-100px"
                  style={{
                    position: "sticky",
                    left: 0,
                    backgroundColor: "white",
                  }}
                >
                  <a
                    // onClick={() => toggleSortByNumReg()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Libelle{" "}
                    <i
                      className="fas fa-caret-up"
                      style={{ fontSize: "24px", marginLeft: "4px" }}
                    ></i>
                  </a>
                </th>

                <th className="min-w-100px">
                  <a
                    // onClick={() => toggleSortByNumReg()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Montant Brut
                  </a>
                </th>
                <th className="min-w-100px">
                  <a
                    // onClick={() => toggleSortByNumReg()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Type Reg
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((regl, index) => (
                <tr key={index}>
                  <td
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "white",
                    }}
                  >
                    {regl.libelle}
                    {/* <Link
                      to={{
                        pathname: `meshonorairesDetails/${regl.num_reg}`,
                        state: { detailsreglement: reglement[index] },
                      }}
                    >
                      {regl.num_reg}
                    </Link> */}
                  </td>

                  <td>{parseInt(regl.mt_brut).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DH</td>
                  <td>
                    {regl.impute === "NULL" ? "-" : <span className="badge gradients">{regl.impute}</span>}
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ul className="pagination mb-2">
          <li
            className={`page-item previous ${
              currentPage === 1 ? "disabled" : ""
            }`}
            onClick={() => handlePreviousPage()}
          >
            <span className="page-link">
              <i className="previous"></i>
            </span>
          </li>
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              className={
                currentPage === index + 1 ? "page-item active" : "page-item"
              }
              key={index}
              onClick={() => handlePageChange(index + 1)}
            >
              <span className="page-link">{index + 1}</span>
            </li>
          ))}

          <li
            className={`page-item next ${
              currentPage === totalPages ? "disabled" : ""
            }`}
            onClick={() => handleNextPage()}
          >
            <span className="page-link">
              <i className="next"></i>
            </span>
          </li>
        </ul>
      </div>
    </div></>
  );
   
};
