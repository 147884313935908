/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { Link } from "react-router-dom";
import { Dropdown2 } from "../../content/dropdown/Dropdown2";

type Props = {
  className: string;
  innerPadding?: string;
};

const StatsWidget6: React.FC<Props> = ({
  className,
  innerPadding = "",
  children,
}) => {
  const [is_intern_extern, setisintern] = useState<number | null>(null);

  useEffect(() => {
    const is_intern_str = sessionStorage.getItem("is_intern");
    const is_intern_num = is_intern_str ? parseInt(is_intern_str, 10) : null;
    setisintern(is_intern_num);
  }, []);

  return (
    <>
      <div className={`card   mb-5 ${className}`}>
        <div className={`card-body ${innerPadding}`}>
          <div className="">
            <Link to="/select-hospital">
              <div className="d-flex align-items-center">
                {/* begin::Symbol*/}
                <div className="symbol symbol-100px symbol-lg-70px symbol-fixed position-relative me-9">
                  <img
                    src={toAbsoluteUrl("/media/imgs/mespatients.jpg")}
                    alt="image"
                  />
                </div>
                {/* end::Symbol*/}

                {/* begin::Info*/}
                <div className="d-flex flex-column flex-grow-1">
                  <a href="" className=" text-muted mb-1 fs-3 fw-bolder">
                    Mes Patients
                  </a>
                </div>
                {/* end::Info*/}
              </div>
            </Link>
          </div>
        </div>
      </div>
      {is_intern_extern === 0 ? (
        <div className={`card ${className}`}>
          <div className={`card-body ${innerPadding}`}>
            <div className="">
              <Link to="/meshonoraire">
                <div className="d-flex align-items-center">
                  {/* begin::Symbol*/}
                  <div className="symbol symbol-100px symbol-lg-70px symbol-fixed position-relative me-9">
                    <img
                      src={toAbsoluteUrl("/media/imgs/meshonoraires.jpg")}
                      alt="image"
                    />
                  </div>
                  {/* end::Symbol*/}

                  {/* begin::Info*/}
                  <div className="d-flex flex-column flex-grow-1">
                    <a href="#" className=" text-muted mb-1 fs-3 fw-bolder">
                      Mes Honoraires
                    </a>
                  </div>
                  {/* end::Info*/}
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export { StatsWidget6 };
