import React, { useState, useEffect } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import axios from "../axios";
import { PatientData, DataService } from "../../_start/layout/core";
import i18next from "i18next";
import SyncLanguage from "./SyncLanguage";
import { KTSVG } from "../../_start/helpers";
import moment from "moment/moment";
import { toAbsoluteUrl } from "../../_start/helpers";
import { datacliniques } from "./DataClinique";
import ContentLoader from "react-content-loader";
import { PatientComponent } from "../../_start/partials/widgets";
import Noresults from "./components/Noresults";

type Props = {
  className: string;
  innerPadding?: string;
};

interface ClinicData {
  clinic: string;
  tokenconsolidationclinic: string;
}

export const SelectHopital: React.FC<Props> = ({
  className,
  innerPadding = "",
}) => {
  const [data, setData] = useState<PatientData[]>([]);
  const [services, setservices] = useState<DataService[]>([]);
  const [error, seterror] = useState("");
  const [loading, setloading] = useState(true);
  const [tokenclinic, settokenclinic] = useState(
    sessionStorage.getItem("tokenConsolidationClinic")
  );
  const [tokenservice, settokenservice] = useState(
    sessionStorage.getItem("tokenConsolidationService")
  );
  const [serviceName, setserviceName] = useState(
    sessionStorage.getItem("service_name")
  );
  const params = {
    tokenConsolidationService: tokenservice,
  };

  const Getdata = () => {
    axios
      .get("/patient/my-patients")
      .then((response) => {
        if (response.data.params) {
          setData(response.data.params);
          setFilteredData(response.data.params); // Move setFilteredData here
        } else if (response.status === 300) {
          seterror(i18next.t("error1_login"));
        } else {
          alert("An error occurred.");
        }
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        seterror("Network Error");
      });
  };

  const handleparam = (index: number) => {
    console.log(index);
  };
  let history = useHistory();
  SyncLanguage();

  const [clinic, setClinic] = useState<ClinicData[]>([]);

  const getTokenClinic = (tokenclinic: string, clinicName: string) => {
    sessionStorage.setItem("tokenConsolidationClinic", tokenclinic);
    sessionStorage.setItem("cliniqueName", clinicName);
  };

  const GetClinic = () => {
    axios
      .get("/service/clinics-by-medicine")
      .then((response) => {
        if (response.data.params) {
          setClinic(response.data.params);
          setloading(false);
        } else {
          alert("An error occurred.");
        }
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        seterror("Network Error");
      });
  };
  const [selectedRow, setSelectedRow] = useState(null);

  const handleMouseEnter = (index) => {
    setSelectedRow(index);
  };

  const handleMouseLeave = () => {
    setSelectedRow(null);
  };

  const inlineStyle = (index) => ({
    transform: selectedRow === index ? "scale(1.02)" : "scale(1)",
    color: selectedRow === index ? "black" : "black",
    transition: "transform 0.2s ease, color 0.2s ease",
    zIndex: selectedRow === index ? "1" : "0",

    // Add any other styles you want here
  });
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchInput(searchTerm);
  
    const filtered = data.filter((item) => {
      const fullName = `${item.patient_last_name} ${item.patient_first_name}`.toLowerCase();
      const searchTermsArray = searchTerm.split(' ');
  
      // Check if all search terms are present in the combined name
      return searchTermsArray.every((term) => fullName.includes(term));
    });
  
    setFilteredData(filtered);
  }

  useEffect(() => {
    // Initialize filteredData with the initial data when the component mounts
    setFilteredData(data);
  }, []);

  useEffect(() => {
    Getdata();
    GetClinic();
  }, []);
  const calculateAge = (dateString:string) => {
    const birthDate = new Date(dateString);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the current date is before the birthday in the current year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };
  return (
    <>
      <ul
        className="nav nav-tabs nav-line-tabs mb-5 fs-6"
        style={{
          backgroundColor: "",
          borderRadius: "25px",
          justifyContent: "center",
          display: "flex",
          padding: "7px",
        }}
      >
        <li
          className="nav-item"
          style={{
            flex: "0 0 40%",
            textAlign: "center",
          }}
        >
          <a
            className="nav-link active "
            data-bs-toggle="tab"
            href="#kt_tab_pane_1"
          >
            Vue Clinique
          </a>
        </li>
        <li
          className="nav-item"
          style={{
            flex: "0 0 40%",
            textAlign: "center",
          }}
        >
          <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2">
            Vue Patients
          </a>
        </li>
      </ul>

      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade active show"
          id="kt_tab_pane_1"
          role="tabpanel"
          style={{ marginTop: "30px" }}
        >
          <Link to="/select-service">
            <div
              className=""
              style={{ marginTop: "25px", borderRadius: "30px" }}
            >
              {loading ? (
                <ContentLoader />
              ) : clinic.length > 0 ? (
                clinic.map((clinique, index) => {
                  const matchingClinic = datacliniques.find(
                    (localClinic) => localClinic.clinic_name === clinique.clinic
                  );
                  return (
                    <div
                      className={`card mb-6 ${className}  bg-success shadow`}
                      onClick={() =>
                        getTokenClinic(
                          clinique.tokenconsolidationclinic,
                          clinique.clinic
                        )
                      }
                    >
                      {/* <!--begin::Header--> */}
                      <div
                        className={`card-header border-0 p-7  ${innerPadding} `}
                        style={{ justifyContent: "start" }}
                      >
                        <div
                          className="d-flex flex-row justify-content-start align-items-start"
                          key={index}
                          onClick={() =>
                            getTokenClinic(
                              clinique.tokenconsolidationclinic,
                              clinique.clinic
                            )
                          }
                        >

                          <div className="symbol symbol-40px symbol-2by3 me-4 border border-muted shadow "
                               style={{ height: "70px", width: "100px" }}>
                            <img
                                alt=""
                                src={matchingClinic?.img2}
                                className="mw-100"
                                style={{ height: "70px", width: "100px" }}
                            />
                          </div>

                          {/* begin::Section */}
                          <div className="d-flex align-items-start">
                            {/* begin::Title */}
                            <div className="d-flex flex-column flex-grow-1 my-lg-0  pe-3">
                              <a
                                href=""
                                className="text-white fw-bolder  fs-6 "
                              >
                                {clinique.clinic}
                              </a>

                              <span className="text-muted fw-bold fs-7">
                                <div className="rating-label checked">
                                  <KTSVG
                                    path="/media/icons/duotone/General/Star.svg"
                                    className="svg-icon svg-icon-3 align-text-bottom"
                                  />{" "}
                                  <span className="text-white fw-bold">
                                    {matchingClinic?.rating}
                                  </span>
                                  <span className="text-white fw-bold">
                                    , ({matchingClinic?.avis}) avis
                                  </span>
                                </div>
                              </span>
                              <span className="text-white fw-bold fs-7 my-1">
                                Ouvert 24/7
                              </span>
                            </div>
                            {/* end::Title */}

                            {/* begin::Info */}
                            <div className="text-end py-lg-0 py-2">
                              {/* <span className="text-gray-800 fw-bolder fs-3">24,900</span>
              <span className="text-muted fs-7 fw-bolder d-block">votes</span> */}
                            </div>
                            {/* end::Info */}
                          </div>
                          {/* end::Section */}
                        </div>
                      </div>

                      {/* <!--end::Header--> */}

                      {/* <!--begin::Body--> */}
                    </div>
                  );
                })
              ) : (
                <Noresults key="noResults"  />
              )}
            </div>
          </Link>

          {/*{loading ? (
            <ContentLoader />
          ) : clinic.length > 0 ? (
            clinic.map((clinique, index) => {
              const matchingClinic = datacliniques.find(
                (localClinic) => localClinic.clinic_name === clinique.clinic
              );
              return (
                <ListsWidget2
                  className="border border-primary mt-7"
                  title={clinique.clinic}
                  description=""
                  des2=""
                  additional={`${matchingClinic?.rating}`}
                  number1=""
                  ToRoute="/service/"
                  imageUrl="/media/imagescliniques/clinique/05.jpg"
                />
              );
            })
          ) : (
            <div>no clinics</div>
          )}*/}
        </div>

        <div
          className="tab-pane fade"
          id="kt_tab_pane_2"
          role="tabpanel"
          style={{ marginTop: "30px" }}
        >
          <div className="" style={{ marginTop: "25px" }}>
            {/* <a href="" className="btn btn-primary mb-5 rounded-pill"><i className="fas fa-arrow-left fs-1x text-white ml-5" style={{ fontSize: '13px' }}></i>Select Service</a> */}
            <div
              className={`card ${className}`}
              style={{ borderRadius: "100px" }}
            >
              {/* <!--begin::Header--> */}
              <div
                className={`card-header border-0 d-flex align-items-center ${innerPadding} `}
              >
                {/* Add the search bar */}

                {/* <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-dark fs-3">
                    <a
                      className="btn btn-icon btn-color-black  btn-active-color-grey btn-sm"
                      style={{ marginRight: "10px" }}
                      onClick={() => history.goBack()}
                    >
                      <KTSVG
                        path="/media/icons/duotone/Navigation/Arrow-left.svg"
                        className="svg-icon-4"
                      />
                    </a>
                    Mes Patients{" "}
                    <span className="text-muted mt-2 fw-bold fs-8">
                      {data.length === 1
                        ? `( ${data.length} Patient )`
                        : data.length > 1
                        ? `(${data.length} Patients)`
                        : "Aucun patient"}
                    </span>
                  </span>
                  

                  
                </h3> */}

                {/* First Icon */}
                <div style={{ marginRight: "10px" }}>
                  {/* Replace 'YourFirstIconComponent' with your actual icon component */}
                  <a
                      className="btn btn-icon btn-color-black  btn-active-color-grey btn-sm"
                      style={{ marginRight: "10px" }}
                      onClick={() => history.goBack()}
                    >

                  <KTSVG
                    path="/media/icons/duotone/Navigation/Arrow-left.svg"
                    className="svg-icon-4"
                  />
                  </a>
                </div>

                {/* Input */}

                <input
                  style={{
                    padding: "8px",
                    marginRight: "10px",
                    marginLeft: "8px",
                    borderRadius: "25px",
                    border: "transparent",
                    flex: 1,
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search Patients"
                  value={searchInput}
                  onChange={handleSearch}
                />

                {/* Second Icon */}
                <div style={{}}>
                  {/* Replace 'YourSecondIconComponent' with your actual icon component */}
                  <KTSVG
                    path="/media/icons/duotone/General/Search.svg"
                    className="svg-icon-2"
                  />
                </div>
              </div>

              {/* <!--end::Header--> */}

              {/* <!--begin::Body--> */}
            </div>
          </div>

          <div className="" style={{ marginTop: "25px" }}>
            {/* <a href="" className="btn btn-primary mb-5 rounded-pill"><i className="fas fa-arrow-left fs-1x text-white ml-5" style={{ fontSize: '13px' }}></i>Select Service</a> */}
            <div
              className={`card ${className}`}
              style={{ backgroundColor: "transparent" }}
            >
              {/* <!--begin::Header--> */}

              {/* <!--end::Header--> */}
              {filteredData?.map((item, index) => (
                <PatientComponent
                  data={item}
                  className="border border-primary mt-7 shadow"
                  title={item.patient_first_name + " " + item.patient_last_name +" " +`(${calculateAge(item.naissance)}) ans`}
                  description={`${item.service_name || ''}${item.service_name && (item.chambre_nom || item.lit_nom) ? ' ' : ''}${item.chambre_nom || ''}${item.chambre_nom && item.lit_nom ? ' ' : ''}${item.lit_nom || ''}${item.service_name && (item.chambre_nom || item.lit_nom) ? '' : ''}`}
                  des2={item.motif}
                  additional={item.entree_reelle}
                  number1=""
                  ToRoute={`/patient/${item.num_admission}`}
                  imageUrl="/media/icons/duotone/Communication/Add-user.svg"
                  
                />
              ))}
              {/* <!--begin::Body--> */}
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
