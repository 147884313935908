/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { Link, Redirect } from "react-router-dom";
import axios from "../../../../app/axios";
import i18n from "../../../../app/i18n";
import i18next from "i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";


import {
  PatientData,
  Detail,
  ServiceRoom,
  DataService,
} from "../../../layout/core";

type Props = {
  className: string;
  innerPadding?: string;
};

const Mespatients: React.FC<Props> = ({ className, innerPadding = "" }) => {
  useEffect(() => {
    Getdata();
    // GetServices();
  }, []);
  const [data, setData] = useState<PatientData[]>([]);
  const [services, setservices] = useState<DataService[]>([]);
  const [error, seterror] = useState("");
  const [tokenclinic, settokenclinic] = useState(
    sessionStorage.getItem("tokenConsolidationClinic")
  );
  const [tokenservice, settokenservice] = useState(
    sessionStorage.getItem("tokenConsolidationService")
  );
  const [serviceName, setserviceName] = useState(
    sessionStorage.getItem("service_name")
  );
  const params = {
    tokenConsolidationService: tokenservice, // Replace with the actual value
  };

  const Getdata = () => {
    axios.get("/patient/my-patients", { params }).then((response) => {
      if (response.data.params) {
        setData(response.data.params);
      } else if (response.status === 300) {
        seterror(i18next.t("error1_login"));
      } else {
        alert("An error occurred.");
      }
    });
  };

  // const GetServices=()=>{
  //   axios.get('/service/services-by-clinic',{params})
  //       .then((response) => {
  //         if (response.data.params) {
  //           setservices(response.data.params);
  //           console.log(response.data.params);
  //         } else if (response.status ===300) {
  //           seterror(i18next.t('error1_login'));
  //         }
  //          else {
  //           alert('An error occurred.');
  //         }

  //       })
  // }

  const handleparam = (index: number) => {
    console.log(index);
  };
  let history = useHistory()
  return (
    <div className="mt-n5">
      {/* <a href="" className="btn btn-primary mb-5 rounded-pill"><i className="fas fa-arrow-left fs-1x text-white ml-5" style={{ fontSize: '13px' }}></i>Select Service</a> */}
      <div className={`card ${className}`}>
        {/* <!--begin::Header--> */}
        <div className={`card-header border-0 pt-5 ${innerPadding} `}>
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-dark fs-3">
              
                <a className="btn btn-icon btn-color-black  btn-active-color-grey btn-sm" style={{ marginRight:"10px" }} onClick={() => history.goBack()}>
                  <KTSVG
                    path="/media/icons/duotone/Navigation/Arrow-left.svg"
                    className="svg-icon-4"
                  />
                </a>
              
              Mes Patients{" "}
              <span className="text-muted mt-2 fw-bold fs-8">
                {data.length === 1
                  ? `( ${data.length} Patient )`
                  : data.length > 1
                  ? `(${data.length} Patients)`
                  : "Aucun patient"}
              </span>
            </span>

            {/* <span className="text-muted mt-2 fs-7">
              {serviceName ? `${serviceName}` : "no service selected"}
            </span> */}
          </h3>

          {/* <div className="card-toolbar">
        <select className="form-select" aria-label="Select example">
          <option>Service</option>
          {services.map((service,index)=>(
          <option value="">{service.service_name}</option>
          ))}
        </select> */}
          {/* filter menu  */}
          {/* <ul className="nav nav-pills nav-pills-sm nav-light">
            <li className="nav-item">
              <a
                className="nav-link btn btn-active-light btn-color-muted py-2 px-4 fw-bolder me-2 active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1_1"
              >
                Day
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link btn btn-active-light btn-color-muted py-2 px-4 fw-bolder me-2"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1_2"
              >
                Week
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link btn btn-active-light btn-color-muted py-2 px-4 fw-bolder"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1_3"
              >
                Month
              </a>
            </li>
          </ul> */}
          {/* </div> */}
        </div>
        {/* <!--end::Header--> */}

        {/* <!--begin::Body--> */}
        <div className="card-body pt-2 pb-0 mt-n3">
          <div className="tab-content mt-5" id="myTabTables1">
            {/* <!--begin::Tap pane--> */}
            <div
              className="tab-pane fade active show"
              id="kt_tab_pane_1_1"
              role="tabpanel"
              aria-labelledby="kt_tab_pane_1_1"
            >
              {/* <!--begin::Table--> */}
              <div className="table-responsive">
                <table className="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th className="p-0 w-50px"></th>

                      <th className="p-0 min-w-100px"></th>
                      {/* <th className="p-0 min-w-40px"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr key={index}>
                        <Link
                          to={{
                            pathname: `/patient/${item.num_admission}`,
                            state: { patientData: data[index] },
                          }}
                        >
                          <th className="px-0 py-3">
                            <div className="symbol symbol-65px me-5">
                              <span className="symbol-label bg-light-success">
                                <KTSVG
                                  path="/media/icons/duotone/Communication/Add-user.svg"
                                  className="svg-icon-1 svg-icon-success"
                                />
                              </span>
                            </div>
                          </th>
                        </Link>

                        <td className="ps-0">
                          <Link
                            to={{
                              pathname: `/patient/${item.num_admission}`,
                              state: { patientData: data[index] },
                            }}
                          >
                            <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                              {item.patient_last_name} {item.patient_first_name}
                            </a>
                            {item.details
                              ? item.details.map((itemdetail, index) => {
                                  return (
                                    <>
                                      <span className="text-muted fw-bold d-block mt-1">
                                        {itemdetail.motif_dmission}
                                      </span>
                                      <span className="text-muted fw-bold d-block mt-1">
                                        {itemdetail.services_rooms.map(
                                          (itemdetailser, index) => {
                                            return (
                                              <span className="text-muted fw-bold d-block mt-1">
                                                {itemdetailser.lit_name}
                                              </span>
                                            );
                                          }
                                        )}
                                      </span>
                                    </>
                                  );
                                })
                              : ""}
                            <span className="text-muted fw-bold d-block mt-1">
                              Le{" "}
                              {moment(
                                item.entree_reelle,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("D MMMM YYYY")}
                            </span>
                          </Link>
                        </td>

                        {/* <td>
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                        Motif d'Admission
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                        test 
                      </span>
                    </td> */}
                        {/* <td>
                    <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                    Date D'entrée
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                       Le {moment(item.entree_reelle, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}
                      </span>
                    </td> */}
                        <td className="text-start pe-0">
                          <Link
                            to={{
                              pathname: `/patient/${item.num_admission}`,
                              state: { patientData: data[index] },
                            }}
                          >
                            <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                              <KTSVG
                                path="/media/icons/duotone/Navigation/Arrow-right.svg"
                                className="svg-icon-4"
                              />
                            </a>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <!--end::Table--> */}
            </div>
            {/* <!--end::Tap pane--> */}

            {/* <!--begin::Tap pane--> */}
            {/* <div
            className="tab-pane fade"
            id="kt_tab_pane_1_2"
            role="tabpanel"
            aria-labelledby="kt_tab_pane_1_1"
          >
            
            <div className="table-responsive">
              <table className="table table-borderless align-middle">
                <thead>
                  <tr>
                    <th className="p-0 w-50px"></th>
                    <th className="p-0 min-w-200px"></th>
                    <th className="p-0 min-w-100px"></th>
                    <th className="p-0 min-w-40px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="ps-0 py-3">
                      <div className="symbol symbol-65px me-3">
                        <span className="symbol-label bg-light-success">
                          <KTSVG
                            path="/media/icons/duotone/Communication/Add-user.svg"
                            className="svg-icon-1 svg-icon-success"
                          />
                        </span>
                      </div>
                    </th>
                    <td className="ps-0">
                      <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                        New Users
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                        HTML/CSS/JS, Python
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 me-3">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="text-dark me-2 fs-6 fw-bolder">
                            Progress
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="progress h-6px  w-100 bg-light-success">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: "53%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            53%
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-end pe-0">
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Arrow-right.svg"
                          className="svg-icon-4"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th className="ps-0 py-3">
                      <div className="symbol symbol-65px me-3">
                        <span className="symbol-label bg-light-danger">
                          <KTSVG
                            path="/media/icons/duotone/Home/Library.svg"
                            className="svg-icon-1 svg-icon-danger"
                          />
                        </span>
                      </div>
                    </th>
                    <td className="ps-0">
                      <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                        Weekly Bestsellers
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                        HTML/CSS/JS, Python
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 me-3">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="text-dark me-2 fs-6 fw-bolder">
                            Progress
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="progress h-6px  w-100 bg-light-danger">
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{ width: "92%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            92%
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-end pe-0">
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Arrow-right.svg"
                          className="svg-icon-4"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th className="ps-0 py-3">
                      <div className="symbol symbol-65px me-3">
                        <span className="symbol-label bg-light-primary">
                          <KTSVG
                            path="/media/icons/duotone/Communication/Group-chat.svg"
                            className="svg-icon-1 svg-icon-primary"
                          />
                        </span>
                      </div>
                    </th>
                    <td className="ps-0">
                      <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                        Top Authors
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                        HTML/CSS/JS, Python
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 me-3">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="text-dark me-2 fs-6 fw-bolder">
                            Progress
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="progress h-6px  w-100 bg-light-primary">
                            <div
                              className="progress-bar bg-primary"
                              role="progressbar"
                              style={{ width: "46%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            46%
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-end pe-0">
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Arrow-right.svg"
                          className="svg-icon-4"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="ps-0 py-3">
                      <div className="symbol symbol-65px me-3">
                        <span className="symbol-label bg-light-warning">
                          <KTSVG
                            path="/media/icons/duotone/Layout/Layout-4-blocks.svg"
                            className="svg-icon-1 svg-icon-warning"
                          />
                        </span>
                      </div>
                    </td>
                    <td className="ps-0">
                      <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                        Popular Authors
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                        HTML, VueJS, Laravel
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 me-3">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="text-dark me-2 fs-6 fw-bolder">
                            Progress
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="progress h-6px  w-100 bg-light-warning">
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{ width: "87%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            87%
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-end pe-0">
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Arrow-right.svg"
                          className="svg-icon-4"
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
          </div> */}

            {/* <div
            className="tab-pane fade"
            id="kt_tab_pane_1_3"
            role="tabpanel"
            aria-labelledby="kt_tab_pane_1_1"
          > */}

            {/* <div className="table-responsive">
              <table className="table table-borderless align-middle">
                <thead>
                  <tr>
                    <th className="p-0 w-50px"></th>
                    <th className="p-0 min-w-200px"></th>
                    <th className="p-0 min-w-100px"></th>
                    <th className="p-0 min-w-40px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="ps-0 py-3">
                      <div className="symbol symbol-65px bg-light-warning me-3">
                        <span className="symbol-label">
                          <KTSVG
                            path="/media/icons/duotone/Layout/Layout-4-blocks.svg"
                            className="svg-icon-1 svg-icon-warning"
                          />
                        </span>
                      </div>
                    </td>
                    <td className="ps-0">
                      <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                        Popular Authors
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                        HTML, VueJS, Laravel
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 me-3">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="text-dark me-2 fs-6 fw-bolder">
                            Progress
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="progress h-6px  w-100 bg-light-warning">
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              style={{ width: "87%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            87%
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-end pe-0">
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Arrow-right.svg"
                          className="svg-icon-4"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th className="ps-0 py-3">
                      <div className="symbol symbol-65px bg-light-success me-3">
                        <span className="symbol-label">
                          <KTSVG
                            path="/media/icons/duotone/Communication/Add-user.svg"
                            className="svg-icon-1 svg-icon-success"
                          />
                        </span>
                      </div>
                    </th>
                    <td className="ps-0">
                      <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                        New Users
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                        HTML/CSS/JS, Python
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 me-3">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="text-dark me-2 fs-6 fw-bolder">
                            Progress
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="progress h-6px  w-100 bg-light-success">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: "53%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            53%
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-end pe-0">
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Arrow-right.svg"
                          className="svg-icon-4"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th className="ps-0 py-3">
                      <div className="symbol symbol-65px bg-light-primary me-3">
                        <span className="symbol-label">
                          <KTSVG
                            path="/media/icons/duotone/Communication/Group-chat.svg"
                            className="svg-icon-1 svg-icon-primary"
                          />
                        </span>
                      </div>
                    </th>
                    <td className="ps-0">
                      <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                        Top Authors
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                        HTML/CSS/JS, Python
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 me-3">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="text-dark me-2 fs-6 fw-bolder">
                            Progress
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="progress h-6px  w-100 bg-light-primary">
                            <div
                              className="progress-bar bg-primary"
                              role="progressbar"
                              style={{ width: "46%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            46%
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-end pe-0">
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Arrow-right.svg"
                          className="svg-icon-4"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th className="ps-0 py-3">
                      <div className="symbol symbol-65px bg-light-danger me-3">
                        <span className="symbol-label">
                          <KTSVG
                            path="/media/icons/duotone/Home/Library.svg"
                            className="svg-icon-1 svg-icon-danger"
                          />
                        </span>
                      </div>
                    </th>
                    <td className="ps-0">
                      <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                        Weekly Bestsellers
                      </a>
                      <span className="text-muted fw-bold d-block mt-1">
                        HTML/CSS/JS, Python
                      </span>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 me-3">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="text-dark me-2 fs-6 fw-bolder">
                            Progress
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="progress h-6px  w-100 bg-light-danger">
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{ width: "92%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                          <span className="text-muted fs-7 fw-bold ps-3">
                            92%
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-end pe-0">
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <KTSVG
                          path="/media/icons/duotone/Navigation/Arrow-right.svg"
                          className="svg-icon-4"
                        />
                      </a>
                    </td>
                  </tr>
                  </tbody>
                  </table>
                 </div> */}
            {/* <!--end::Table--> */}
          </div>
          {/* <!--end::Tap pane--> */}
        </div>
      </div>
    </div>
  );
};

export { Mespatients };
