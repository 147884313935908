/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  EngageWidget5,
  ListsWidget2,
  StatsWidget1,
  StatsWidget2,
  TablesWidget1,
  TablesWidget2,
} from "../../../../_start/partials/widgets";
import { CreateAppModal } from "../_modals/create-app-stepper/CreateAppModal";
import { PageTitle } from "../../../../_start/layout/core";
import { datacliniques } from "../../DataClinique";
import { KTSVG } from "../../../../_start/helpers";


export const StartDashboardPage: React.FC = () => {
  const [servicename, setservicename] = useState(
    sessionStorage.getItem("service_name")
  );
  const matchingClinic = datacliniques.find(
    (localClinic) => localClinic.clinic_name === servicename
  );
  const [show, setShow] = useState(false);
  return (
    <>
      {/* begin::Row */}
      <div className="row g-0 g-xl-5 g-xxl-8">
        
      <div className={`card mb-6   bg-success shadow`} >
        {/* <!--begin::Header--> */}
        <div
            className={`card-header border-0 p-7  `}
            style={{ justifyContent: "start" }}
        >
          <div
              className="d-flex">
            <div className="symbol symbol-40px symbol-2by3 me-4 border border-muted shadow "
                 style={{ height: "70px", width: "100px" }}>
              <img
                  alt=""
                  src={matchingClinic?.img2}
                  className="mw-100"
                  style={{ height: "70px", width: "100px" }}
              />
            </div>

            {/* begin::Section */}
            <div className="d-flex align-items-start">
              {/* begin::Title */}
              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                <a
                    href=""
                    className="text-white fw-bolder  fs-6 "
                >
                  {servicename}
                </a>

                {/* <span className="text-muted fw-bold fs-7">
                                <div className="rating-label checked">
                                  <KTSVG
                                      path="/media/icons/duotone/General/Star.svg"
                                      className="svg-icon svg-icon-3 align-text-bottom"
                                  />{" "}
                                  <span className="text-white fw-bold">
                                    {matchingClinic?.rating}
                                  </span>
                                  <span className="text-white fw-bold">
                                    , ({matchingClinic?.avis}) avis
                                  </span>
                                </div>
                              </span> */}
                <span className="text-white fw-bold fs-7 my-1">
                                Ouvert 24/7
                              </span>
              </div>
              {/* end::Title */}

              {/* begin::Info */}
              <div className="text-end py-lg-0 py-2">
                {/* <span className="text-gray-800 fw-bolder fs-3">24,900</span>
              <span className="text-muted fs-7 fw-bolder d-block">votes</span> */}
              </div>
              {/* end::Info */}
            </div>
            {/* end::Section */}
          </div>
        </div>

        {/* <!--end::Header--> */}

        {/* <!--begin::Body--> */}
      </div>
        {/* <PageTitle>{servicename}</PageTitle> */}
        <div className="col-xl-12">
          <TablesWidget1 className="card-stretch mb-5 mb-xxl-8" />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-4">
          <StatsWidget1 className="card-stretch mb-5 mb-xxl-8" />
        </div>

        <div className="col-xl-8">
          <StatsWidget2 className="card-stretch mb-5 mb-xxl-8" />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-12">
          <ListsWidget2 className="card-stretch mb-5 mb-xxl-8" />
        </div> */}

      {/* <div className="col-xl-8">
          <TablesWidget2 className="card-stretch mb-5 mb-xxl-8" />
        </div> */}
      {/* </div> */}
      {/* end::Row */}

      {/* begin::Modals */}
      {/* <CreateAppModal show={show} handleClose={() => setShow(false)} /> */}
      {/* end::Modals */}
    </>
  );
};
