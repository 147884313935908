import React, { useState } from "react";
import { EngageWidget5 } from "../../../../_start/partials/widgets";
import { ListsWidget2 } from "../../../../_start/partials/widgets";
import {
  FeedsWidget2,
  TablesWidget3,
} from "../../../../_start/partials/widgets";

export function Overview() {
  const [show, setShow] = useState(false);
  return (

    <div className="row g-0 g-xl-5 g-xxl-8">
      <div className="col-xl-12">
        <FeedsWidget2 className="card-custom card-flush mb-5 mb-xxl-8" />
      </div>

      <div className="col-xl-12">
        <TablesWidget3 className="card-custom mb-5 mb-xxl-8" />
      </div>
    </div>





  );
}