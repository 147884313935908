import React, { useEffect, useState } from "react";
import { KTSVG } from "../../_start/helpers";
import { Dropdown1 } from "../../_start/partials";
import { Link } from "react-router-dom";
import { Reglement } from "../../_start/layout/core";
import SyncLanguage from "./SyncLanguage";
import axios from "../axios";
import moment from "moment/moment";
import { calculateSumOfProperty, sortArrayAsc } from "./Sortlogic";
import TitlePage from "./Goback";


type Props = {
  className: string;
};

export const Meshonoraire: React.FC<Props> = ({ className }) => {
  const itemsPerPage = 35;

  const [reglement, setreglement] = useState<Reglement[]>([]);
  const [errors, seterror] = useState("");
  const [counter, setCounter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filteredData, setFilteredData] = useState<Reglement[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("lastMonth");

  useEffect(() => {
    Getreglenemt();
  }, []);

  useEffect(() => {
    // Set filteredData whenever reglement changes
    handleFilter(selectedFilter);
  }, [reglement, selectedFilter]);

  const Getreglenemt = () => {
    axios.get("/patient/reglements-honoraires").then((response) => {
      if (response.data.params) {
        setreglement(response.data.params);
      } else if (response.status === 300) {
        seterror("error");
      } else {
        alert("An error occurred.");
      }
    }).catch((error) => {
      console.error("Axios Error:", error);
      seterror("Network Error");
    });
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(event.target.value);
  };

  const handleFilter = (filterType: string) => {
    const currentDate = new Date();

    switch (filterType) {
      case "lastYear":
        setFilteredData(
          reglement.filter(
            (item) =>
              new Date(item.date_paiement) >=
              new Date(currentDate.getFullYear() - 1, 0, 1)
          )
        );
        break;
      case "lastMonth":
        setFilteredData(
          reglement.filter(
            (item) =>
              new Date(item.date_paiement) >=
              new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
          )
        );
        break;
      case "all":
        setFilteredData(reglement);
        break;
      case "last6Months":
        setFilteredData(
          reglement.filter(
            (item) =>
              new Date(item.date_paiement) >=
              new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1)
          )
        );
        break;
      default:
        setFilteredData(reglement);
        break;
    }
  };
  const toggleSortByNumReg = () => {
    setreglement(sortArrayAsc(reglement, "num_reg", counter));
    setCounter(counter + 1);
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  return (
    <>
    <TitlePage  title="Mes Honoraires"/>
    <div className={`card ${className}`}>
      {/* begin::Header*/}
      <div className="card-header align-items-center border-0 mt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">Mes Honoraires </span>
          <span className="text-muted mt-2 fw-bold fs-7">
            ({reglement.length}) Dossiers
          </span>
        </h3>
        <div className="card-toolbar">
          {/* begin::Dropdown*/}
          <select
            value={selectedFilter}
            onChange={handleFilterChange}
            className="form-select"
          >
            <option value="lastMonth">Last Month</option>
            <option value="last6Months">Last 6 Months</option>
            <option value="lastYear">Last Year</option>
            <option value="all">All</option>
          </select>
          {/* end::Dropdown*/}
        </div>
      </div>
      <div className="p-1">
        <div className="table-responsive">
          <table className="table table-striped gy-7 gs-7 text-center">
            <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                <th
                  className="min-w-100px"
                  style={{
                    position: "sticky",
                    left: 0,
                    backgroundColor: "white",
                  }}
                >
                  <a
                    onClick={() => toggleSortByNumReg()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Num règlement{" "}
                    <i
                      className="fas fa-caret-up"
                      style={{ fontSize: "24px", marginLeft: "4px" }}
                    ></i>
                  </a>
                </th>
                <th className="min-w-100px">
                  <a>Date règlement</a>
                </th>
                <th className="min-w-100px">
                  <a>Type règlement</a>
                </th>
                <th className="min-w-100px">Montant Brut</th>
                <th className="min-w-100px">Montant Retenu</th>
                <th className="min-w-100px">
                  <a
                    onClick={() => toggleSortByNumReg()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Montant Net
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((regl, index) => (
                <tr key={index}>
                  <td
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "white",
                    }}
                  >
                    <Link
                      to={{
                        pathname: `meshonorairesDetails/${regl.num_reg}`,
                        state: { detailsreglement: reglement[index] },
                      }}
                    >
                      {regl.num_reg}
                    </Link>
                  </td>
                  <td>
                    {moment(regl.date_paiement, "YYYY-MM-DD HH:mm:ss").format(
                      "DD/MM/YYYY"
                    )}
                  </td>
                  <td>
                    <span className="badge gradients">{regl.type_reg}</span>
                  </td>
                  <td>{parseInt(regl.mt_brut).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DH</td>
                  <td>{regl.retenu !== null ? parseInt(regl.retenu).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " DH" : 0}</td>
                  <td>{parseInt(regl.mt_net).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DH</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ul className="pagination mb-2">
          <li
            className={`page-item previous ${
              currentPage === 1 ? "disabled" : ""
            }`}
            onClick={() => handlePreviousPage()}
          >
            <span className="page-link">
              <i className="previous"></i>
            </span>
          </li>
          {Array.from({ length: totalPages }).map((_, index) => (
            <li
              className={
                currentPage === index + 1 ? "page-item active" : "page-item"
              }
              key={index}
              onClick={() => handlePageChange(index + 1)}
            >
              <span className="page-link">{index + 1}</span>
            </li>
          ))}

          <li
            className={`page-item next ${
              currentPage === totalPages ? "disabled" : ""
            }`}
            onClick={() => handleNextPage()}
          >
            <span className="page-link">
              <i className="next"></i>
            </span>
          </li>
        </ul>
      </div>
    </div></>
    
  );
};
