import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LocationState } from "../../_start/layout/core";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import { Viewer } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
import { CapacitorHttp } from '@capacitor/core';
import { attachmentPlugin } from '@react-pdf-viewer/attachment';
import '@react-pdf-viewer/attachment/lib/styles/index.css';



import {
  fullScreenPlugin,
  FullScreenIcon,
  ExitFullScreenIcon,
} from "@react-pdf-viewer/full-screen";
import { scrollModePlugin } from "@react-pdf-viewer/scroll-mode";

export const AffichePdfBilan = () => {
  const attachmentPluginInstance = attachmentPlugin();

  useEffect(() => {
    doGet();
  }, []);
  const toolbarPluginInstance = toolbarPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const location = useLocation();
  

  const PdfNameAffiche = (location.state as LocationState)?.pdfaffiche;
  let history = useHistory();
  console.log(PdfNameAffiche);
  const { Attachments } = attachmentPluginInstance;
  const docs = `${PdfNameAffiche}`;
  const doGet = () => {
    const options = {
      url: `${PdfNameAffiche}`,
      headers: { 'Token-group': '1GV' },
      Authorization: { size: 'XL' },
    };

    const response = CapacitorHttp.request({ ...options, method: 'GET' });
    console.log(response);
    return response;
  };
  console.log(doGet(),"alors");

  useEffect(() => {
    doGet();
  }, []);
  
  

  return (
    <div
      className="rpv-core__viewer"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#eeeeee",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          display: "flex",
          padding: "4px",
        }}
      >
        <Toolbar>
          {(props: ToolbarSlot) => {
            const {
              CurrentPageInput,
              Download,
              EnterFullScreen,

              GoToNextPage,
              GoToPreviousPage,
              NumberOfPages,
              Print,
              ShowSearchPopover,
              Zoom,
              ZoomIn,
              ZoomOut,
            } = props;
            return (
              <>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomOut />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <Zoom />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomIn />
                </div>
                <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                  <GoToPreviousPage />
                </div>
                <div style={{ padding: "0px 2px", width: "4rem" }}>
                  <CurrentPageInput />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  / <NumberOfPages />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <GoToNextPage />
                </div>
                <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                  <EnterFullScreen />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <Download />
                </div>
              </>
            );
          }}
        </Toolbar>
      </div>
      <div className="container">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer
            fileUrl={docs}
            plugins={[toolbarPluginInstance, fullScreenPluginInstance]}
            
          />
        </Worker>
      </div>
    </div>
  );
};
