import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { handlesignout } from "../../../../app/pages/Handlesignout";

export function HeaderUserMenu() {
  const [userdata, setuserdata] = useState(
    sessionStorage.getItem("user_detail")
  );
  const handlesignoute = () => {
    handlesignout();
  };
  const [is_intern_extern, setisintern] = useState<number | null>(null);

  useEffect(() => {
    const is_intern_str = sessionStorage.getItem("is_intern");
    const is_intern_num = is_intern_str ? parseInt(is_intern_str, 10) : null;
    setisintern(is_intern_num);
  }, []);

  let profileImage: string;
  // if (userdata.sexe === 'm') {
  //   profileImage = toAbsoluteUrl("/media/avatars/avatar_doc_man.jpg");
  // } else if (userdata.sexe === 'f') {
  //   profileImage = toAbsoluteUrl("/media/avatars/avatar_doc_female.jpg");
  // } else {
  //   // If sexe is not "m" or "f", display a default image
    
  // }
profileImage = toAbsoluteUrl("/media/avatars/avatar_doc_man.jpg");
  return (
    <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-300px"
      data-kt-menu="true"
    >
      <div className="menu-content bg-info fw-bold d-flex align-items-center bgi-no-repeat bgi-position-y-top rounded-top bg-info">
        <div className="symbol symbol-45px mx-5 py-5">
          <div
              className="btn btn-icon btn-sm symbol symbol-40px"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
          >
            <img
              alt="Logo"
              src={profileImage}
            />
          </div>

        </div>
        <div className="">
          <span className="text-white fw-bolder fs-4">Bonjour
          </span>
          <span className="text-white fw-bold fs-7 d-block">
            Dr.
            {" "}{userdata ? JSON.parse(userdata).user_first_name : "undefdddined"} {" "}
            {userdata ? JSON.parse(userdata).user_last_name : "undefdddined"}
          </span>
        </div>
      </div>

      {/* begin::Row */}
      <div className="row row-cols-2 g-0">
        <Link
          to="/profile"
          className="border-bottom border-end text-center py-10 btn btn-active-color-primary rounded-0"
          data-kt-menu-dismiss="true"
        >
          <KTSVG
            className="svg-icon-3x me-n1"
            path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg"
          />
          <span className="  fw-bolder fs-6 d-block pt-3">My Profile</span>
        </Link>


        {is_intern_extern === 0 ? (
          <Link
            to="/meshonoraire"
            className="col text-center border-end py-10 btn btn-active-color-primary rounded-0"
            data-kt-menu-dismiss="true"
          >
            <KTSVG
              className="svg-icon-3x me-n1"
              path="/media/icons/duotone/Communication/fin002.svg"
            />
            <span className="fw-bolder fs-6 d-block pt-3">Mes Honoraires</span>
          </Link>
        ) : (
          ""
        )}

        <Link
          to="/logout"
          className="col text-center py-10 btn btn-active-color-primary rounded-0"
          data-kt-menu-dismiss="true"
          onClick={handlesignoute}
        >
          <KTSVG
            className="svg-icon-3x me-n1"
            path="/media/icons/duotone/Navigation/Sign-out.svg"
          />

          <span className="fw-bolder fs-6 d-block pt-3">Se déconnecter</span>
        </Link>
      </div>
      {/* end::Row */}
    </div>
  );
}
