import React, { useState } from "react";
import { KTSVG } from "../../../helpers";
import { toAbsoluteUrl } from "../../../helpers";
import {
  HeaderNotificationsMenu,
  SearchModal,
  HeaderUserMenu,
  InboxCompose,
} from "../../../partials";
import { useTheme } from "../../core";

export function Topbar() {
  const { config } = useTheme();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showInboxComposeModal, setShowInboxComposeModal] = useState(false);
  const [userdata,setuserdata]= useState(sessionStorage.getItem('user_detail'))

  let profileImage: string;
  // if (userdata.sexe === 'm') {
  //   profileImage = toAbsoluteUrl("/media/avatars/avatar_doc_man.jpg");
  // } else if (userdata.sexe === 'f') {
  //   profileImage = toAbsoluteUrl("/media/avatars/avatar_doc_female.jpg");
  // } else {
  //   // If sexe is not "m" or "f", display a default image
   
  // }
 profileImage = toAbsoluteUrl("/media/avatars/avatar_doc_man.jpg");
  return (
    <>
      {/* begin::Search */}
      {/* <button
        className="btn btn-icon btn-sm btn-active-bg-accent ms-1 ms-lg-6"
        onClick={() => setShowSearchModal(true)}
      >
        <KTSVG
          path="/media/icons/duotone/General/Search.svg"
          className="svg-icon-1 svg-icon-dark"
        />
      </button>
      <SearchModal
        show={showSearchModal}
        handleClose={() => setShowSearchModal(false)}
      /> */}
      {/* end::Search */}

      {/* begin::Message */}
      {/* <button
        className="btn btn-icon btn-sm btn-active-bg-accent ms-1 ms-lg-6"
        onClick={() => setShowInboxComposeModal(true)}
      >
        <KTSVG
          path="/media/icons/duotone/Communication/Chat6.svg"
          className="svg-icon-1 svg-icon-dark"
        />
      </button>
      <InboxCompose
        show={showInboxComposeModal}
        handleClose={() => setShowInboxComposeModal(false)}
      /> */}
      {/* end::Message */}

      {/* begin::User */}
      <div className="ms-1 ms-lg-6">
        {/* begin::Toggle */}
        <div
          className="btn btn-icon btn-sm symbol symbol-35px"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >
          <img
              src={profileImage}
            alt=""
          />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>

      {/* end::User */}

      {/* begin::Notifications */}
      {/* <div className="ms-1 ms-lg-6">
        
        <button
          className="btn btn-icon btn-sm gradients fw-bolder pulse pulse-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >
          <span className="position-absolute text-white fs-6">3</span>
          <span className="pulse-ring"></span>
        </button>
        <HeaderNotificationsMenu />
        
      </div> */}
      {/* end::Notifications */}

      {/* begin::Aside Toggler */}
      {/* {config.aside.display && (
        <button
          className="btn btn-icon btn-sm btn-active-bg-accent d-lg-none ms-1 ms-lg-6"
          id="kt_aside_toggler"
        >
          <KTSVG
            path="/media/icons/duotone/Text/Menu.svg"
            className="svg-icon-1 svg-icon-dark"
          />
        </button>
      )} */}
      {/* end::Aside Toggler */}

      {/* begin::Sidebar Toggler */}
      {/* {config.sidebar.display && (
        <button
          className="btn btn-icon btn-sm btn-active-bg-accent d-lg-none ms-1 ms-lg-6"
          id="kt_sidebar_toggler"
        >
          <KTSVG
            path="/media/icons/duotone/Text/Menu.svg"
            className="svg-icon-1 svg-icon-dark"
          />
        </button>
      )} */}
      {/* end::Sidebar Toggler */}
    </>
  );
}
