import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "title_login": "Log in",

      "newHere_login": " New here?",
      "receivepass_login": "Receive password",
      "cin_login": "UserName",
      "password_login": "Password",
      "forgoutPassword_login": "Forgout password ?",
      "login_login": "Login",
      "slogan_login":"Your health, our priority",
      "pleasewait_login":"Please Wait ...",
      "error1_login":"Unauthorized access. Please check your credentials.",
      //""""""""""""""""""""""""""""""""""""""""
      "bienvenue_dashboard":"Welcome",
      //-------------------------------------
      "profile":"Profile",
      //-------------------------------------
      "select-hospital":"Please choose a clinic",
      //-------------------------------------
      "select-service":"Please choose a Service",
      "meshonoraire-accueil":"My Fees",
       //-------------------------------------
       "select-feuille":"Select a Page",
       //-------------------------------------

    }
  },
  fr: {
    translation: {
      "title_login": "Connectez-vous",
      "newHere_login": " Nouveau ici ?  ",
      "receivepass_login": "Recevoir le mot de passe",
      "cin_login": "Nom d'Utilisateur",
      "password_login": "Mot de passe",
      "forgoutPassword_login": "Mot de passe oublié ?",
      "login_login": "Se connecter",
      "slogan_login":"Votre santé, notre priorité",
      "pleasewait_login":"Veuillez Patienter",
      "error1_login":"Accès non autorisé. Veuillez vérifier vos informations d'identification",


      //""""""""""""""""""""""""""""""""""""""""
      "bienvenue_dashboard":"bienvenue",
      //-------------------------------------
      "profile":"Profil",
      //-------------------------------------
      "select-hospital":"Veuillez choisir un clinique",
      //-------------------------------------
      "select-service":"Veuillez choisir un Service",
      "meshonoraire-accueil":"Mes Honoraires",
      //-------------------------------------
      "select-feuille":"Veuillez choisir une feuille",
      //-------------------------------------

    }
  },
  ar: {
    translation: {
      "title_login": "قم بتسجيل الدخول",
      "newHere_login": " جديد هنا؟",
      "receivepass_login": "استقبل كلمة المرور",
      "cin_login": "اسم المستخدم",
      "password_login": " كلمة المرور",
      "forgoutPassword_login": "هل نسيت كلمة المرور؟",
      "login_login": " تسجيل الدخول",
      "slogan_login":"صحتكم، أولويتنا",
      "pleasewait_login":"يرجى الانتظار ... ",
      "error1_login":"يرجى التحقق من بيانات الاعتماد الخاصة بك",
      //""""""""""""""""""""""""""""""""""""""""
      "bienvenue_dashboard":"مرحباً",
      //-------------------------------------
      "profile":"الحساب الشخصي",
      //-------------------------------------
      "select-hospital":"يرجى اختيار  المستشفى",
      //-------------------------------------
      "select-service":"يرجى اختيار  خدمة",
      "meshonoraire-accueil":"معلومات المعاملة" ,
      //-------------------------------------
      "select-feuille":"الرجاء اختبار ورقة ",
      //-------------------------------------

    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ar", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;