import React, { useEffect, useRef, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { LocationState } from "../../_start/layout/core";
import { FeedsWidget4 } from "../../_start/partials/widgets";
import { KTSVG } from "../../_start/helpers";
import axios from "../axios";
import Swal from "sweetalert2";

export const Tousavisspec = () => {
  
  const history = useHistory();
  const location = useLocation();
  const avismedical = (location.state as LocationState)?.avismedical;
  {
    console.log(avismedical);
  }

  useEffect(() => {
    getuserdetailFromSessionStorage();
  }, []);

  const getuserdetailFromSessionStorage = () => {
    const userDetailString = sessionStorage.getItem("user_detail");
    return userDetailString ? JSON.parse(userDetailString) : null;
  };
  const [error, seterror] = useState('');

  const [inputValue, setInputValue] = useState('');
  const buttonRef = useRef<HTMLAnchorElement  | null>(null);
  const buttonRefs = useRef<HTMLAnchorElement  | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [notification, setnotif] = useState('');
  const [notificationajout, setnotifajout] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  const handleModifi = (
    event: React.MouseEvent<HTMLButtonElement>,
    token_avis_specialiste_response: string
  ) => {
    event.preventDefault();
    if (inputValue.trim() === "") {
      setnotif("modifier un résumé");
      return;
    }

    setnotif("Chargement...");
    console.log("token:", token_avis_specialiste_response, "inputValue :", inputValue);
    buttonRefs.current?.click();
    axios
      .post("/medecin/add-avis-specialist", {
        token_avis_specialiste_response: token_avis_specialiste_response,
        resume: inputValue,
      })
      .then((response) => {
        if (response.data.params) {
          Swal.fire({
            title: "Success",
            text: "reponse modifié par succes",
            icon: "success",
            confirmButtonText: "Ok!",
            confirmButtonColor: "#0098DA",
          });
          setnotif("Avis modifié avec succès");
          setInputValue("");
          seterror("");
          
        } else if (response.status === 300) {
          seterror("error");
        } else {
          alert("An error occurred.");
        }
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        seterror("Network Error");
        setnotif("");
      });
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>, token_avis_specialiste: string) => {
    event.preventDefault()
    if (inputValue.trim() === "") {
      setnotifajout("Ajoutez un résumé");
      return;
    }

    setnotifajout("Chargement...")
    console.log("token:", token_avis_specialiste, "inputValue :", inputValue);
    buttonRef.current?.click();
    axios
      .post("/medecin/add-avis-specialist", {
        token_avis_specialiste: token_avis_specialiste,
        resume: inputValue
      })
      .then((response) => {
        if (response.data.params) {
          Swal.fire({
            title: 'Success',
            text: 'reponse ajouté par succes',
            icon: 'success',
            confirmButtonText: 'Ok!',
            confirmButtonColor:'#0098DA'
          })
          setnotifajout("Avis ajouté avec succès");
          setInputValue("");
          seterror("");
        } else if (response.status === 300) {
          seterror("error");
        } else {
          alert("An error occurred.");
        }
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        seterror("Network Error");
        setnotifajout("");
      });
  };

  const userdetail = getuserdetailFromSessionStorage();
  return (
    <div className="col-lg-12">
      <div className="card card-custom card-stretch shadow mb-5">
        <div className="card-header ">
        <h3 className="card-title symbol symbol-40px me-5">
        <a className="btn btn-icon btn-color-success  btn-active-color-white btn-sm" style={{ marginRight:"10px" }} onClick={() => history.goBack()}>
                  <KTSVG
                    path="/media/icons/duotone/Navigation/Arrow-left.svg"
                    className="svg-icon-4"
                  />
                </a>
            <span className="symbol-label bg-success">
              <i
                className="fas fa-user-md fs-1x text-white"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span
              className="text-success fw-bolder"
              style={{ marginLeft: "10px" }}
            >
              Avis de spécialiste <span className="fs-6 text-success fw-bold">({avismedical?.length})</span>
            </span>
          </h3>
          <div className="card-toolbar">
          </div>
        </div>
        <div
          className="card-body"
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "0.5rem",
          }}
        >
          {avismedical &&
            avismedical.length > 0 &&
            avismedical.map((advice, index) => {
              return (
                <> 
                <FeedsWidget4
                  className="my-custom-class"
                  specialite={advice.specialite}
                  avis_demandeur={advice.avis_demandeur}
                  specialiste_demandeur={advice.specialiste_demandeur}
                  date_demande={advice.date_demande}
                  medecin_venu={advice.medecin_venu}
                  specialiste_avise={advice.specialiste_avise}
                  avis_reponse={advice.avis_reponse}
                />
                {advice.globalmedcinid == userdetail?.cons_fk_user_id ? (
                  <>
                    {advice.avis_reponse !== null ? (
                      <>
                        {" "}
                        <a
                          ref={buttonRefs}
                          type="button"
                          className="btn btn-primary gradients text-white"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_2"
                          onClick={() => {
                            if (advice.avis_reponse !== null) {
                              setInputValue(advice.avis_reponse);
                            } else {
                              // Handle the null case, perhaps set a default value or perform some other logic
                              console.error("advice.avis_reponse is null");
                            }
                          }}
                          style={{ marginLeft: "28px" }}
                        >
                          Modifier l'Avis
                        </a>
                        <div
                          className="modal fade"
                          tabIndex={-1}
                          id="kt_modal_2"
                        >
                          <div className="modal-dialog" style={{marginTop:"250px" }}>
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Veuillez Modifier l'Avis</h5>
                                <div
                                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <KTSVG
                                    path="/media/icons/duotone/Navigation/Close.svg"
                                    className="svg-icon svg-icon-2x"
                                  />
                                </div>
                              </div>
                              <div className="modal-body">
                                
                                <input
                                  type="text"
                                  name=""
                                  className="form-control"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />
                                <span>{notification}</span>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  data-bs-dismiss="modal"
                                >
                                  Fermer
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={(e) =>
                                    handleModifi(
                                      e,
                                      advice.token_avis_specialiste_response
                                    )
                                  }
                                >
                                  Soumettre
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <a
                          ref={buttonRef}
                          type="button"
                          className="btn btn-primary gradients text-white"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_1"
                          style={{ marginLeft: "28px" }}
                        >
                          Ajoutez un Avis
                        </a>
                        <div
                          className="modal fade"
                          tabIndex={-1}
                          id="kt_modal_1"
                        >
                          <div className="modal-dialog" style={{marginTop:"250px" }}>
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Ajouter un Avis</h5>
                                <div
                                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <KTSVG
                                    path="/media/icons/duotone/Navigation/Close.svg"
                                    className="svg-icon svg-icon-2x"
                                  />
                                </div>
                              </div>
                              <div className="modal-body">
                                <input
                                  type="text"
                                  name=""
                                  className="form-control"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />
                                <span>{notificationajout}</span>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  data-bs-dismiss="modal"
                                >
                                  Fermer
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={(e) =>
                                    handleSubmit(
                                      e,
                                      advice.token_avis_specialiste
                                    )
                                  }
                                >
                                  Soumettre
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};
