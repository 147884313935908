import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Registration } from "./components/Registration";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_start/helpers";
import { ForgotPassword } from "./components/ForgotPassword";
import i18n from "../../i18n";
import i18next from "i18next";
import SyncLanguage from "../../pages/SyncLanguage";
import { useIsMobile } from '../../../_start/assets/ts/_utils/helpers/dom-helpers/isMobileDevice';

export function AuthPage() {
    const isMobile = useIsMobile();
    SyncLanguage();


    useEffect(() => {
        document.body.classList.add("bg-white");
        return () => {
            document.body.classList.remove("bg-white");
        };
    }, []);

    return (


        <div className="d-flex flex-column flex-lg-row flex-column-fluid "
            style={{
                backgroundImage: `url('${toAbsoluteUrl("/media/illustrations/auth-background.svg")}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}>


           { isMobile ? (
                <div className="d-flex flex-column flex-lg-row-auto  w-xl-600px position-xl-relative shadow  bg-success"
                     style={{
                         borderRadius: '0 0 15px 15px',
                         marginBottom: '60px',
                         height: '200px'
                     }}>

                {/* Wrapper */}
                <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
                    {/* Header */}
                    <div className="d-flex flex-row-fluid flex-column text-center logoauth  p-5 p-lg-10">
                        {/* Logo */}
                        <a href="/home" className="py-2 py-lg-20">
                            <img alt="Logo" src={toAbsoluteUrl("/media/logos/akdital_logo.png")} className="h-40px h-lg-60px" />
                        </a>


                    </div>
                    {/* Illustration */}


                </div>
            </div>
            ) : (
                <div className="d-flex flex-column flex-lg-row-auto  w-xl-600px position-xl-relative shadow  bg-success"
                style={{
                    background: `linear-gradient(to bottom, #FFFFFF, transparent 50%, #0376b9),
            url('${toAbsoluteUrl("/media/illustrations/authbg2.jpeg")}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '0  30px 30px 0',
                }}>

                {/* Wrapper */}
                <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
                    {/* Header */}
                    <div className="d-flex flex-row-fluid flex-column text-center logoauth  p-5 p-lg-10">
                        {/* Logo */}
                        <a href="/home" className="py-2 py-lg-20">
                            <img alt="Logo" src={toAbsoluteUrl("/media/logos/logo_akdital2.svg")} className="h-30px h-lg-50px" style={{ position: 'relative', top: '-50px' }}/>
                        </a>


                    </div>
                    {/* Illustration */}

                </div>
            </div>
            )}

           
            {/* Aside */}

            <div className="d-flex flex-column flex-lg-row-fluid">
                <div className="d-flex flex-column flex-lg-row-fluid "

                >

                    <Switch>
                        <Route path="/auth/login" component={Login} />
                        <Route path="/auth/registration" component={Registration} />
                        <Route path="/auth/forgot-password" component={ForgotPassword} />
                        <Redirect from="/auth" exact={true} to="/auth/login" />
                        <Redirect to="/auth/login" />
                    </Switch>
                </div>


            </div>
        </div>




    );


}
