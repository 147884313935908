import React, { useEffect, useState } from "react";
import { KTSVG } from "../../_start/helpers";
import { Dropdown1 } from "../../_start/partials";
import { Link, useLocation } from "react-router-dom";
import {
  DetailFeuilleSurRea,
  PrescriptionData,
  DataExamenLabo,
  Laboratoire,
  Consignes,
  GazduSang,
  Radiodata,
  AvisData,
  CTS,
  PharmacyTableProps,
  GroupConstant,
  GroupConstante,
  LaboData,
} from "../../_start/layout/core";
import SyncLanguage from "./SyncLanguage";
import axios from "../axios";
import moment from "moment/moment";
import {
  accumulateAndSortDates,
  calculateSumOfProperty,
  sortArrayAsc,
} from "./Sortlogic";
import { LocationState } from "../../_start/layout/core";
import { Rotate } from "../modules/docs/pages/base/Rotate";
import { toAbsoluteUrl } from "../../_start/helpers";
import LoaderSpinner from "./LoaderSpinner";
import ContentLoader from "react-content-loader";
import { FeedsWidget4 } from "../../_start/partials/widgets";
import TitlePage from "./Goback";
import BootstrapCardDataTable from "./DetaillfeuilleSoinLoader";
import { Browser } from "@capacitor/browser";
import CustomIndicator from "./components/CustomIndicator";

type Props = {
  className: string;
};

export const DetailFeuilledesoin: React.FC<Props> = ({ className }) => {
  const [DetailFeuilleSurRea, setDetailFeuilleSurRea] = useState<
    DetailFeuilleSurRea[]
  >([]);
  const [DetailPharmaci, setDetailPharmacie] = useState<PrescriptionData[]>([]);
  const [DetailsLabo, setDetailLabo] = useState<Laboratoire[]>([]);
  const [DetailsRadio, setDetailsRadio] = useState<Radiodata[]>([]);
  const [DetailsConsignes, setDetailsConsiges] = useState<Consignes[]>([]);
  const [DetailsGazDuSang, setDetailsGazDuSang] = useState<GazduSang[]>([]);
  const [DetailsAvisSpec, setDetailsAvisSpec] = useState<AvisData[]>([]);
  const [DetailsCTS, setDetailsCTS] = useState<CTS[]>([]);
  const [loaderspinner, setloaderspinner] = useState(true);
  const [errors, seterror] = useState("");
  const [typefeuille, settypefeuille] = useState("");
  const [uniqueDatees, setUniqueDates] = useState<string[]>([]);

  const [ios, setIos] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      setIos(true);
      console.log("is ios");
    } else {
      setIos(false);
      console.log("is not ios");
    }

    return () => {
      setIsMounted(false);
    };
  }, []);

  const openBrowser = async (url: string) => {
    try {
      const pov = await Browser.open({ url: url });
      if (isMounted) {
        // Update state or perform other actions if component is still mounted
      }
      return pov;
    } catch (error) {
      console.error("Error opening browser:", error);
    }
  };

  useEffect(() => {
    GetDetailFeuille();

    const scrollToLastColumn = () => {
      const table = document.querySelector("#yourTableId") as HTMLTableElement; // Type casting
      if (table) {
        const lastColumnIndex = table.rows[0].cells.length - 1;
        const lastColumn = table.rows[0].cells[lastColumnIndex];
        lastColumn.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    // Call the function with a delay of 3 seconds
    const delay = 3000; // 3 seconds
    const timeoutId = setTimeout(() => {
      scrollToLastColumn();
    }, delay);

    // Clear the timeout if the component is unmounted before the delay
    return () => clearTimeout(timeoutId);
  }, []);

  const location = useLocation();
  const Onedossiermedicale = (location.state as LocationState)
    ?.OnedossiermedicaltoDetail;
  const params = {
    id: Onedossiermedicale?._id,
    type: Onedossiermedicale?.type,
    date_creation: Onedossiermedicale?.date.slice(0, 10), // modif here to be sure
  };
  console.log(params);

  const GetDetailFeuille = () => {
    axios.get("/patient/constante-feuille", { params }).then((response) => {
      if (response.data.params) {
        setloaderspinner(false);
        settypefeuille(response.data.debug.type);
        setDetailPharmacie(response.data.params.pharmacies);
        setDetailFeuilleSurRea(response.data.params.constantes);
        setDetailLabo(response.data.params.laboratoire);
        setDetailsConsiges(response.data.params.consignes);
        setDetailLabo(response.data.params.laboratoire);
        setDetailsGazDuSang(response.data.params.gazDuSang);
        setDetailsRadio(response.data.params.radiologie);
        setDetailsAvisSpec(response.data.params.medicalAdvice);
        setDetailsCTS(response.data.params.cts);
        console.log(DetailsLabo, "autre");
      } else if (response.status === 300) {
        seterror("error");
      } else {
        alert("An error occurred.");
      }
    });
  };

  SyncLanguage();

  const uniqueHeaders = Array.from(
    new Set(DetailFeuilleSurRea.map((item) => item.date_prescription))
  ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const uniquedatepharmacie = Array.from(
    new Set(DetailPharmaci.map((item) => item.dateconsommation))
  ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  console.log(uniqueHeaders, "unique headers ");
  var colorArray = [
    "#dadff7",
    "#e5f4e3",
    "#fffdd0",
    "#dddbcb",
    "#e8e8e8",
    "#fffdd0",
    "#dadff7",
  ];

  const transformToGroupConstantArray = () => {
    const result: GroupConstant[] = [];

    DetailFeuilleSurRea.forEach((item) => {
      const existingGroup = result.find((group) => group.group === item.group);

      if (!existingGroup) {
        result.push({
          group: item.group,
          constante: [item.constante],
        });
      } else if (!existingGroup.constante.includes(item.constante)) {
        existingGroup.constante.push(item.constante);
      }
    });

    result.sort((a, b) => {
      const rangA =
        DetailFeuilleSurRea.find((item) => item.group === a.group)
          ?.rang_groupe || 0;
      const rangB =
        DetailFeuilleSurRea.find((item) => item.group === b.group)
          ?.rang_groupe || 0;
      return rangA - rangB;
    });

    result.forEach((group) => {
      group.constante.sort((constA, constB) => {
        const rangA =
          DetailFeuilleSurRea.find(
            (item) => item.group === group.group && item.constante === constA
          )?.rang_constante || 0;
        const rangB =
          DetailFeuilleSurRea.find(
            (item) => item.group === group.group && item.constante === constB
          )?.rang_constante || 0;
        return rangA - rangB;
      });
    });

    return result;
  };

  const groupedDatapharma: Record<string, PrescriptionData[]> = {};
  console.log(groupedDatapharma, "zawasss");

  DetailPharmaci.forEach((item) => {
    const articleName = item.articlenom || "Unknown";
    if (!groupedDatapharma[articleName]) {
      groupedDatapharma[articleName] = [];
    }
    groupedDatapharma[articleName].push(item);
  });

  console.log(groupedDatapharma, "hjgfvhgj");
  const groupedData = transformToGroupConstantArray();
  console.log(groupedData, "taaw");

  const observations: string[] = [];

  // Iterate through constantes and add non-null observation values to the array
  DetailFeuilleSurRea.forEach((constante) => {
    if (constante.observation !== null) {
      observations.push(constante.observation);
    }
  });

  console.log(observations, "observa");

  const Labodata = {};

  DetailsLabo.forEach((item) => {
    const { date, data_examen_labo, file } = item;

    // Extract only the date part
    const onlyDate = date.split(" ")[0];

    // If date is not in Labodata, create an entry
    if (!Labodata[onlyDate]) {
      Labodata[onlyDate] = { date: onlyDate, libelles: [], file: [] };
    }

    // Accumulate libelles for the date
    data_examen_labo.forEach((laboItem) => {
      Labodata[onlyDate].libelles.push(laboItem.libelle);
    });

    // Accumulate file for the date
    Labodata[onlyDate].file.push(file);
  });

  // Convert the Labodata object to an array
  const ArrayLaboData: LaboData[] = Object.values(Labodata);

  // Output the result
  console.log(ArrayLaboData, "arraylabo");

  return (
    <>
      <TitlePage title="Feuille de Soin" />
      <div className={`card ${className}`}>
        {/* begin::Header*/}
        <div className="card-header align-items-center border-0 mt-5 justify-content-center">
          <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 w-100 justify-content-between">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
              >
                Constantes
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_2"
              >
                Pharmacie
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_3"
              >
                Autre
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade active show"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <div className=""></div>
            <div className="p-1">
              <div className="table-responsive table-row-bordered border ">
                {loaderspinner ? (
                  <BootstrapCardDataTable /> // Replace LoaderComponent with your loading indicator
                ) : DetailFeuilleSurRea.length > 0 ? (
                  <table
                    className="table  gy-7 gs-7 text-center"
                    id="yourTableId"
                  >
                    <thead>
                      <tr className="fw-bold fs-4 text-gray-800 border-bottom-2 border-gray-200">
                        {groupedData.length > 1 ? (
                          <th
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "white",
                            }}
                          >
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              Group
                            </a>
                          </th>
                        ) : (
                          ""
                        )}

                        <th
                          className="min-w-100px text-primary"
                          style={{
                            position: "sticky",
                            left: 0,
                            backgroundColor: "white",
                          }}
                        >
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Constantes
                          </a>
                        </th>
                        {uniqueHeaders.map((header, index) => (
                          <th key={index}>
                            <a>
                              {moment(header, "YYYY-MM-DD HH:mm:ss").format(
                                "HH"
                              )}
                              h
                            </a>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {groupedData.map((group, groupIndex) => (
                        <React.Fragment key={groupIndex}>
                          <tr>
                            {group.group !== "Constante étage" ? (
                              <td
                                className="fw-bold fs-5 text-gray-800 border-gray-200"
                                style={{
                                  position: "sticky",
                                  left: 0,
                                  backgroundColor: `${colorArray[groupIndex]}`,
                                  padding: "0.4px",
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                  wordBreak: "break-word",
                                }}
                                rowSpan={group.constante.length + 1}
                              >
                                <span>{group.group}</span>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                          {group.constante.map((cte, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  position: "sticky",
                                  left: 0,
                                  backgroundColor: `${colorArray[groupIndex]}`,
                                }}
                              >
                                {cte}
                              </td>
                              {uniqueHeaders.map((header) => {
                                const matchingData = DetailFeuilleSurRea.find(
                                  (item) =>
                                    item.constante === cte &&
                                    item.date_prescription === header &&
                                    item.group === group.group
                                );
                                const cellValue = matchingData
                                  ? matchingData.valeur
                                  : "-";
                                const normalizedValue = cellValue
                                  ? cellValue.replace(/,|g/g, ".")
                                  : "";

                                return (
                                  <td key={header} className="text-gray-700">
                                    {matchingData?.min === null &&
                                    matchingData?.max === null ? (
                                      <span>{cellValue}</span>
                                    ) : parseFloat(normalizedValue) >
                                      (matchingData?.max ?? 0) ? (
                                      <span className="text-danger">
                                        {cellValue}
                                      </span>
                                    ) : parseFloat(normalizedValue) <
                                      (matchingData?.min ?? 0) ? (
                                      <span className="text-primary">
                                        {cellValue}
                                      </span>
                                    ) : (
                                      <span>{cellValue}</span>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No records</p>
                )}
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade p-1 "
            id="kt_tab_pane_2"
            role="tabpanel"
          >
            <div className=""></div>
            <div className="">
              <div className="table-responsive table-row-bordered  border ">
                <table
                  className="table  gy-7 gs-7 text-center"
                  id="yourTableId"
                >
                  <thead>
                    <tr className="fw-bold fs-4 text-gray-800 border-bottom-2 border-gray-200 text-primary">
                      <th
                        style={{
                          position: "sticky",
                          left: 0,
                          backgroundColor: "white",
                        }}
                      >
                        <a
                          className="text-primary"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          Article
                        </a>
                      </th>
                      {uniquedatepharmacie.map((date) => (
                        <th key={date}>
                          {" "}
                          <a>
                            {moment(date, "YYYY-MM-DD HH:mm:ss").format("HH")}h
                          </a>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(groupedDatapharma).map(
                      ([articleName, dataRows], index) => (
                        <tr
                          key={articleName}
                          className="border-bottom"
                          style={{
                            backgroundColor:
                              dataRows[0]?.traitement_habituel === 1
                                ? "#e5f4e3"
                                : "white",
                            borderBottom: "1px solid black", // Set blue background when ishabituel equals 1

                            // Set a lower zIndex value
                          }}
                        >
                          <td
                            className="fw-bold fs-5 text-gray-800 border-gray-200"
                            style={{
                              position: "sticky",
                              left: 0,

                              verticalAlign: "middle",
                              textAlign: "center",
                              zIndex: 1, // Set a higher zIndex value
                              overflow: "hidden",
                              padding: "0.5rem",
                              backgroundColor:
                                dataRows[0]?.traitement_habituel === 1
                                  ? "#e5f4e3"
                                  : "white", // Hide overflow content
                            }}
                          >
                            <span>
                              {articleName}
                              <span className="fw-thin fs-8 text-primary">
                                <br />
                                {dataRows[0].observation}
                              </span>
                            </span>
                          </td>
                          {uniquedatepharmacie.map((date) => {
                            const matchingItem = dataRows.find(
                              (item) => item.dateconsommation === date
                            );
                            return (
                              <td
                                key={date}
                                style={{
                                  color:
                                    matchingItem?.isenarret === 1
                                      ? "danger"
                                      : matchingItem?.isenattente === 1
                                      ? "primary"
                                      : "default",

                                  // Set a lower zIndex value
                                }}
                              >
                                {matchingItem?.isconsomme === "0" &&
                                (matchingItem?.issap === 0 ||
                                  matchingItem?.issap === null) ? (
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      className="fas fa-caret-up"
                                      style={{
                                        fontSize: "36px",
                                        color: "white",
                                        WebkitTextStroke:
                                          matchingItem?.isenarret === 1
                                            ? "3px red"
                                            : matchingItem?.isenattente === 1
                                            ? "3px blue"
                                            : "3px black", // Adding a black stroke
                                      }}
                                    ></i>
                                    <sub>({matchingItem.qteparprise})</sub>
                                  </span>
                                ) : matchingItem?.issap === 1 ? (
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      alt="Logo"
                                      src={toAbsoluteUrl(
                                        "/media/logos/serum.png"
                                      )}
                                      className="h-24px"
                                    />
                                    <sub>({matchingItem.vitesse})</sub>
                                  </span>
                                ) : matchingItem?.isconsomme === "1" &&
                                  (matchingItem?.issap === 0 ||
                                    matchingItem?.issap === null) ? (
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <i
                                      className="fas fa-caret-up "
                                      style={{
                                        fontSize: "36px",
                                        color: "black",
                                      }}
                                    ></i>
                                    <sub>({matchingItem.qteparprise})</sub>
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="tab-pane fade p-4" id="kt_tab_pane_3" role="tabpanel">
            <div className="col-lg-12">
              <div className="accordion " id="kt_accordion_1">
                {/* labo */}

                <div className="accordion-item mb-5 ">
                  <h2
                    className="accordion-header "
                    id="kt_accordion_1_header_1"
                  >
                    <button
                      className="accordion-button fs-4 fw-bold collapsed  text-white  fw-bolder"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_1"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_1"
                    >
                      <h3 className="card-title symbol symbol-40px">
                        <span className="symbol-label bg-light-success">
                          <i
                            className="fas fa-microscope fs-1x text-info"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </h3>

                      <span
                        className="text-primary  fw-bolder"
                        style={{ marginLeft: "10px" }}
                      >
                        Laboratoire{" "}
                        <span className="fs-6 text-primary  fw-bold">
                          ({DetailsLabo.length})
                        </span>
                      </span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_1"
                    className="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_1"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div className="accordion-body">
                      {DetailsLabo.length === 0 ? (
                        <div className="text-center text-muted">
                          No radiology records
                        </div>
                      ) : (
                        ArrayLaboData.map((datalabo, index) => (
                          <div key={index}>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div className="d-flex flex-column justify-content-center align-items-between">
                                <span className="text-muted fw-bold fs-5">
                                  Le{" "}
                                  {moment(
                                    datalabo.date,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("D MMMM YYYY")}
                                </span>
                                <div className="details">
                                  {datalabo.libelles.map(
                                    (detail, detailIndex) => (
                                      <React.Fragment key={detailIndex}>
                                        {detailIndex > 0 ? (
                                          <span className="text-black  fw-bold  fs-7"></span>
                                        ) : null}
                                        <span className="text-black  fw-bold  fs-5 ">
                                          - {detail}
                                          <br />
                                        </span>
                                      </React.Fragment>
                                    )
                                  )}
                                </div>

                                <span className="text-muted  fw-bold  fs-7">
                                  {datalabo.sejour_id}
                                </span>
                              </div>
                              {datalabo.file !== null && (
                                <>
                                  {ios ? (
                                    <span
                                      onClick={() => {
                                        openBrowser(
                                          `${process.env.REACT_APP_DOMAINNAME}/${datalabo.file[0]}`
                                        );
                                      }}
                                    >
                                      <CustomIndicator />
                                    </span>
                                  ) : (
                                    <span
                                      onClick={() => {
                                        openBrowser(
                                          `${process.env.REACT_APP_DOMAINNAME}/${datalabo.file[0]}`
                                        );
                                      }}
                                    >
                                      <CustomIndicator />
                                    </span>
                                  )}
                                </>
                              )}
                              {console.log(ArrayLaboData, "hah")}
                            </div>

                            {index !== DetailsLabo.length - 1 && ( // Check if this is not the last element
                              <div className="separator my-3"></div>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>

                <div className="accordion-item mb-5">
                  <h2 className="accordion-header" id="kt_accordion_1_header_2">
                    <button
                      className="accordion-button fs-4 fw-bold collapsed  text-white  fw-bolder"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_2"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_2"
                    >
                      <h3 className="card-title symbol symbol-40px">
                        <span className="symbol-label bg-light-success">
                          <i
                            className="fas fa-x-ray fs-1x text-info"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </h3>

                      <span
                        className="text-primary  fw-bolder"
                        style={{ marginLeft: "10px" }}
                      >
                        Consignes{" "}
                        <span className="fs-6 text-primary  fw-bold">
                          ({DetailsConsignes.length})
                        </span>
                      </span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_2"
                    className="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_2"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div className="accordion-body">
                      {DetailsConsignes.length === 0 ? (
                        <div className="text-center text-muted">
                          No radiology records
                        </div>
                      ) : (
                        DetailsConsignes.map((dataConsignes, index, array) => (
                          <div key={index}>
                            <div className="container  p-0 ">
                              <div className="row d-flex flex-row justify-content-center align-items-center  p-0">
                                <div className="col-lg-10 d-flex flex-column mb-3">
                                  <span className="text-black  fw-bold  fs-5 ">
                                    {dataConsignes.consigne}
                                  </span>
                                  <span className="text-muted  fw-bold  fs-7">
                                    Le{" "}
                                    {moment(
                                      dataConsignes.date,
                                      "YYYY-MM-DD HH:mm:ss"
                                    ).format("D MMM HH:mm")}
                                  </span>
                                  <span className="text-muted fw-bold fs-7">
                                    Validé à :{" "}
                                    <span className="text-primary fw-bolder">
                                      {moment(
                                        dataConsignes.date_validation,
                                        "YYYY-MM-DD HH:mm:ss"
                                      ).format("D MMM HH:mm")}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {index !== array.length - 1 && (
                              <div className="separator my-3"></div>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>

                <div className="accordion-item mb-5">
                  <h2 className="accordion-header" id="kt_accordion_1_header_3">
                    <button
                      className="accordion-button fs-4 fw-bold collapsed  text-white  fw-bolder"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_3"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_3"
                    >
                      <h3 className="card-title symbol symbol-40px">
                        <span className="symbol-label bg-light-success">
                          <i
                            className="fas fa-x-ray fs-1x text-info"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </h3>

                      <span
                        className="text-primary  fw-bolder"
                        style={{ marginLeft: "10px" }}
                      >
                        Observations{" "}
                        <span className="fs-6 text-primary  fw-bold">
                          ({observations.length})
                        </span>
                      </span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_3"
                    className="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_3"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div className="accordion-body">
                      {observations.length === 0 ? (
                        <div className="text-center text-muted">
                          No Observations records
                        </div>
                      ) : (
                        observations.map((observation, index) => (
                          <div key={index}>
                            <div className="container  p-0 ">
                              <div className="row d-flex flex-row justify-content-center align-items-center  p-0">
                                <div className="col-lg-10 d-flex flex-column mb-3">
                                  <span className="text-black  fw-bold  fs-5 ">
                                    {observation}
                                  </span>
                                  {/* <span className="text-muted  fw-bold  fs-7">
                                      Le{" "}
                                      {moment(
                                        dataConsignes.date,
                                        "YYYY-MM-DD HH:mm:ss"
                                      ).format("D MMM HH:mm")}   
                                    </span> */}
                                  {/* <span className="text-muted fw-bold fs-7">
                                    Validé à :{" "}
                                      <span className="text-primary fw-bolder">
                                      {moment(
                                        dataConsignes.date_validation,
                                        "YYYY-MM-DD HH:mm:ss"
                                      ).format("D MMM HH:mm")}
                                      </span>
                                    </span> */}
                                </div>
                              </div>
                            </div>
                            {index !== observations.length - 1 && (
                              <div className="separator my-3"></div>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>

                <div className="accordion-item mb-5">
                  <h2 className="accordion-header" id="kt_accordion_1_header_4">
                    <button
                      className="accordion-button fs-4 fw-bold collapsed  text-white  fw-bolder"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_4"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_4"
                    >
                      <h3 className="card-title symbol symbol-40px">
                        <span className="symbol-label bg-light-success">
                          <i
                            className="fas fa-x-ray fs-1x text-info"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </h3>

                      <span
                        className="text-primary  fw-bolder"
                        style={{ marginLeft: "10px" }}
                      >
                        Radiologie{" "}
                        <span className="fs-6 text-primary  fw-bold">
                          ({DetailsRadio.length})
                        </span>
                      </span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_4"
                    className="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_4"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div className="accordion-body">
                      {DetailsRadio.length === 0 ? (
                        <div className="text-center text-muted">
                          No Radiology records
                        </div>
                      ) : (
                        DetailsRadio.map((radio, index) => (
                          <div key={index}>
                            <div className="container  p-0 ">
                              <div className="row d-flex flex-row justify-content-center align-items-center  p-0">
                                <div className="col-lg-10 d-flex flex-column mb-3">
                                  <span className="text-black  fw-bold  fs-5 ">
                                    {radio}
                                  </span>
                                  {/* <span className="text-muted  fw-bold  fs-7">
                                      Le{" "}
                                      {moment(
                                        dataConsignes.date,
                                        "YYYY-MM-DD HH:mm:ss"
                                      ).format("D MMM HH:mm")}   
                                    </span> */}
                                  {/* <span className="text-muted fw-bold fs-7">
                                    Validé à :{" "}
                                      <span className="text-primary fw-bolder">
                                      {moment(
                                        dataConsignes.date_validation,
                                        "YYYY-MM-DD HH:mm:ss"
                                      ).format("D MMM HH:mm")}
                                      </span>
                                    </span> */}
                                </div>
                              </div>
                            </div>
                            {index !== observations.length - 1 && (
                              <div className="separator my-3"></div>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>

                <div className="accordion-item mb-5">
                  <h2 className="accordion-header" id="kt_accordion_1_header_5">
                    <button
                      className="accordion-button fs-4 fw-bold collapsed  text-white  fw-bolder"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_5"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_5"
                    >
                      <h3 className="card-title symbol symbol-40px">
                        <span className="symbol-label bg-light-success">
                          <i
                            className="fas fa-x-ray fs-1x text-info"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </h3>

                      <span
                        className="text-primary  fw-bolder"
                        style={{ marginLeft: "10px" }}
                      >
                        Avis Specialiste{" "}
                        <span className="fs-6 text-primary  fw-bold">
                          ({DetailsAvisSpec.length})
                        </span>
                      </span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_5"
                    className="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_5"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div className="accordion-body" style={{ padding: 0 }}>
                      {DetailsAvisSpec.length === 0 ? (
                        <div className="text-center text-muted">
                          No Medical advice records
                        </div>
                      ) : (
                        DetailsAvisSpec.map((advice, index) => (
                          <div key={index}>
                            <FeedsWidget4
                              className="my-custom-class"
                              specialite={advice.specialite}
                              avis_demandeur={advice.avis_demandeur}
                              specialiste_demandeur={
                                advice.specialiste_demandeur
                              }
                              date_demande={advice.date_demande}
                              medecin_venu={advice.medecin_venu}
                              specialiste_avise={advice.specialiste_avise}
                              avis_reponse={advice.avis_reponse}
                            />
                            {index !== DetailsAvisSpec.length - 1 && (
                              <div className="separator my-3"></div>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>

                <div className="accordion-item mb-5">
                  <h2 className="accordion-header" id="kt_accordion_1_header_6">
                    <button
                      className="accordion-button fs-4 fw-bold collapsed  text-white  fw-bolder"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_6"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_6"
                    >
                      <h3 className="card-title symbol symbol-40px">
                        <span className="symbol-label bg-light-success">
                          <i
                            className="fas fa-x-ray fs-1x text-info"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </h3>

                      <span
                        className="text-primary  fw-bolder"
                        style={{ marginLeft: "10px" }}
                      >
                        CTS{" "}
                        <span className="fs-6 text-primary  fw-bold">
                          ({DetailsCTS.length})
                        </span>
                      </span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_6"
                    className="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_6"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div className="accordion-body">
                      {DetailsCTS.length === 0 ? (
                        <div className="text-center text-muted">
                          No CTS records
                        </div>
                      ) : (
                        DetailsCTS.map((CTS, index) => (
                          <div key={index}>
                            <div className="container  p-0 ">
                              <div className="row d-flex flex-row justify-content-center align-items-center  p-0">
                                <div className="col-lg-10 d-flex flex-column mb-3">
                                  <span className="text-black  fw-bold  fs-5 ">
                                    Le{" "}
                                    {moment(
                                      CTS.date_utilisation,
                                      "YYYY-MM-DD HH:mm:ss"
                                    ).format("D MMM HH:mm")}{" "}
                                    ({CTS.quantite})
                                  </span>
                                  <span className="text-muted fw-bold fs-7"></span>
                                </div>
                              </div>
                            </div>
                            {index !== DetailsCTS.length - 1 && (
                              <div className="separator my-3"></div>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>

                <div className="accordion-item mb-5">
                  <h2 className="accordion-header" id="kt_accordion_1_header_7">
                    <button
                      className="accordion-button fs-4 fw-bold collapsed  text-white  fw-bolder"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_accordion_1_body_7"
                      aria-expanded="false"
                      aria-controls="kt_accordion_1_body_7"
                    >
                      <h3 className="card-title symbol symbol-40px">
                        <span className="symbol-label bg-light-success">
                          <i
                            className="fas fa-x-ray fs-1x text-info"
                            style={{ fontSize: "18px" }}
                          ></i>
                        </span>
                      </h3>

                      <span
                        className="text-primary  fw-bolder"
                        style={{ marginLeft: "10px" }}
                      >
                        Gaz du Sang{" "}
                        <span className="fs-6 text-primary  fw-bold">
                          (
                          {DetailsGazDuSang && DetailsGazDuSang.length > 0
                            ? 1
                            : 0}
                          )
                        </span>
                      </span>
                    </button>
                  </h2>
                  <div
                    id="kt_accordion_1_body_7"
                    className="accordion-collapse collapse"
                    aria-labelledby="kt_accordion_1_header_7"
                    data-bs-parent="#kt_accordion_1"
                  >
                    <div className="accordion-body">
                      <div className="">
                        <table className="table table-striped  table-row-gray-300 gy-4">
                          <thead>
                            <tr className="fw-bolder fs-6 text-gray-800">
                              <th>Libelle</th>
                              <th>Valeur</th>
                            </tr>
                          </thead>
                          <tbody>
                            {DetailsGazDuSang.length === 0 ? (
                              <div className="text-center text-muted">
                                No Sang records
                              </div>
                            ) : (
                              DetailsGazDuSang.map((gaz, index) => (
                                <tr>
                                  <td>{gaz.libelle}</td>
                                  <td>{gaz.value}</td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
