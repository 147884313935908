import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FallbackView } from "../../_start/partials";
import { StartDashboardWrapper } from "../pages/dashboards/start-dashboard/StartDashboardWrapper";
import { StartDashboardWRappers } from "../pages/dashboards/start-dashboard/StartDashboardWRappers";
import Mypage from "../pages/Mypage";
import { SelectHopital } from "../pages/SelectHopital";
import { SelectService } from "../pages/SelectService";
import { Meshonoraire } from "../pages/Meshonoraire";
import { MeshonorairesDetails } from "../pages/MeshonorairesDetails";
import { MeshonorairesAccueil } from "../pages/MeshonorairesAccueil";
import { Tousavisspec } from "../pages/Tousavisspec";
import { Tousdossiersmedical } from "../pages/Tousdossiersmedical";
import { Selectfeuille } from "../pages/Selectfeuille";
import { DetailFeuilledesoin } from "../pages/DetailFeuilledesoin";
import { TousLaboratoire } from "../pages/TousLaboratoire";
import { StartDashboardPageMespatients } from "../pages/dashboards/start-dashboard/StartDashboardPageMespatients";
import { MeshonorairesNonRegle } from "../pages/MeshonorairesNonRegle";
import { AffichePdfBilan } from "../pages/AffichePdfBilan";
import DetailFeuilleUrgence from "../pages/DetailFeuilleUrgence";
// import { LightDashboardPage } from "../pages/dashboards/light-dashboard/LightDashboardPage";
import { ProfilePage } from "../modules/profile/ProfilePage";
import { TousRadiologie } from "../pages/TousRadiologie";
import { TousOperation } from "../pages/TousOperation";




export function PrivateRoutes() {
  const ProfilePageWrapper = lazy(
    () => import("../modules/profile/ProfilePageWrapper")
  );
  const medcinString = sessionStorage.getItem("medcin");
  const medcin = medcinString ? parseInt(medcinString, 10) : null;

  const [is_intern_extern, setisintern] = useState<number | null>(null);

  useEffect(() => {
    const is_intern_str = sessionStorage.getItem("is_intern");
    const is_intern_num = is_intern_str ? parseInt(is_intern_str, 10) : null;
    setisintern(is_intern_num);
  }, []);

  return (
    <>
      {(is_intern_extern === 0 || is_intern_extern === null) && (
        <>
          <Suspense fallback={<FallbackView />}>
            <Switch>
              <Route path="/select-hospital" component={SelectHopital} />
              <Route path="/select-feuille" component={Selectfeuille} />
              <Route path="/select-service" component={SelectService} />
              <Route path="/dashboard" component={StartDashboardWrapper} />
              <Route path="/Accueil" component={StartDashboardWRappers} />
              <Route path="/profile" component={ProfilePage} />
              <Route path="/Avismedical" component={Tousavisspec} />
              <Route path="/DossiersMedical" component={Tousdossiersmedical} />
              <Route path="/Laboratoire" component={TousLaboratoire} />
              <Route path="/Radiologie" component={TousRadiologie} />
              <Route path="/Operation" component={TousOperation} />
              <Route path="/DetailFeuilleUrgence" component={DetailFeuilleUrgence} />
              <Route path="/patient/:id" component={Mypage} />
              <Route path="/DetailFeuilledesoin" component={DetailFeuilledesoin} />
              <Route path="/meshonoraire" component={MeshonorairesAccueil} />
              <Route path="/meshonoraires" component={Meshonoraire} />
              <Route path="/MeshonorairesNonRegle" component={MeshonorairesNonRegle} />
              <Route path="/AffichePdfBilan" component={AffichePdfBilan} />
              <Route path="/mespatients" component={StartDashboardPageMespatients}/>
              <Route path="/MeshonorairesDetails" component={MeshonorairesDetails}/>
              <Redirect from="/auth" to="/select-hospital" />
              <Redirect exact from="/" to="/Accueil" />
            </Switch>
          </Suspense>
        </>
      )}
      {is_intern_extern === 1 && (
        <>
          <Suspense fallback={<FallbackView />}>
            <Switch>
            <Route path="/select-hospital" component={SelectHopital} />
              <Route path="/select-feuille" component={Selectfeuille} />
              <Route path="/select-service" component={SelectService} />
              <Route path="/dashboard" component={StartDashboardWrapper} />
              <Route path="/Accueil" component={StartDashboardWRappers} />
              <Route path="/profile" component={ProfilePageWrapper} />
              <Route path="/Avismedical" component={Tousavisspec} />
              <Route path="/DossiersMedical" component={Tousdossiersmedical} />
              <Route path="/Radiologie" component={TousRadiologie} />
              <Route path="/Operation" component={TousOperation} />
              <Route path="/Laboratoire" component={TousLaboratoire} />
              <Route path="/DetailFeuilleUrgence" component={DetailFeuilleUrgence} />
              <Route path="/patient/:id" component={Mypage} />
              <Route path="/DetailFeuilledesoin" component={DetailFeuilledesoin} />
              <Route path="/AffichePdfBilan" component={AffichePdfBilan}/>
              <Route path="/mespatients" component={StartDashboardPageMespatients}/>
              <Redirect from="/auth" to="/Accueil" />
              <Redirect exact from="/" to="/Accueil" />
            </Switch>
          </Suspense>
        </>
      )}
    </>
  );
}
