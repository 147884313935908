/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { Link, Redirect } from "react-router-dom";
import axios from "../../../../app/axios";
import i18n from "../../../../app/i18n";
import i18next from "i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";


import {
  PatientData,
  Detail,
  ServiceRoom,
  DataService,
} from "../../../../_start/layout/core";
import { PatientComponent } from "../lists/PatientComponent";

type Props = {
  className: string;
  innerPadding?: string;
};

const TablesWidget1: React.FC<Props> = ({ className, innerPadding = "" }) => {
  useEffect(() => {
    Getdata();
    // GetServices();
  }, []);
  const [data, setData] = useState<PatientData[]>([]);
  const [services, setservices] = useState<DataService[]>([]);
  const [error, seterror] = useState("");
  const [tokenclinic, settokenclinic] = useState(
    sessionStorage.getItem("tokenConsolidationClinic")
  );
  const [tokenservice, settokenservice] = useState(
    sessionStorage.getItem("tokenConsolidationService")
  );
  const [serviceName, setserviceName] = useState(
    sessionStorage.getItem("service_name")
  );
  const params = {
    tokenConsolidationService: tokenservice, // Replace with the actual value
  };

  const Getdata = () => {
    axios.get("/patient/my-patients", { params }).then((response) => {
      if (response.data.params) {
        setData(response.data.params);
      } else if (response.status === 300) {
        seterror(i18next.t("error1_login"));
      } else {
        alert("An error occurred.");
      }
    });
  };

  // const GetServices=()=>{
  //   axios.get('/service/services-by-clinic',{params})
  //       .then((response) => {
  //         if (response.data.params) {
  //           setservices(response.data.params);
  //           console.log(response.data.params);
  //         } else if (response.status ===300) {
  //           seterror(i18next.t('error1_login'));
  //         }
  //          else {
  //           alert('An error occurred.');
  //         }

  //       })
  // }

  const handleparam = (index: number) => {
    console.log(index);
  };
  let history = useHistory()
  return (
    <div className="mt-n5">
      {/* <a href="" className="btn btn-primary mb-5 rounded-pill"><i className="fas fa-arrow-left fs-1x text-white ml-5" style={{ fontSize: '13px' }}></i>Select Service</a> */}
      {/* <div className={`card ${className}`}>
        <div className={`card-header border-0 pt-5 ${innerPadding} `}>
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-dark fs-3">
              
                <a className="btn btn-icon btn-color-black  btn-active-color-grey btn-sm" style={{ marginRight:"10px" }} onClick={() => history.goBack()}>
                  <KTSVG
                    path="/media/icons/duotone/Navigation/Arrow-left.svg"
                    className="svg-icon-4"
                  />
                </a>
              
              Mes Patients{" "}
              <span className="text-muted mt-2 fw-bold fs-8">
                {data.length === 1
                  ? `( ${data.length} Patient )`
                  : data.length > 1
                  ? `(${data.length} Patients)`
                  : "Aucun patient"}
              </span>
            </span>
          </h3>

        
        </div>
        <div className="card-body pt-2 pb-0 mt-n3">
          <div className="tab-content mt-5" id="myTabTables1">
            
            <div
              className="tab-pane fade active show"
              id="kt_tab_pane_1_1"
              role="tabpanel"
              aria-labelledby="kt_tab_pane_1_1"
            >
              
              <div className="table-responsive">
                <table className="table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th className="p-0 w-50px"></th>

                      <th className="p-0 min-w-100px"></th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr key={index}>
                        <Link
                          to={{
                            pathname: `/patient/${item.num_admission}`,
                            state: { patientData: data[index] },
                          }}
                        >
                          <th className="px-0 py-3">
                            <div className="symbol symbol-65px me-5">
                              <span className="symbol-label bg-light-success">
                                <KTSVG
                                  path="/media/icons/duotone/Communication/Add-user.svg"
                                  className="svg-icon-1 svg-icon-success"
                                />
                              </span>
                            </div>
                          </th>
                        </Link>

                        <td className="ps-0">
                          <Link
                            to={{
                              pathname: `/patient/${item.num_admission}`,
                              state: { patientData: data[index] },
                            }}
                          >
                            <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                              {item.patient_last_name} {item.patient_first_name}
                            </a>
                            {item.details
                              ? item.details.map((itemdetail, index) => {
                                  return (
                                    <>
                                      <span className="text-muted fw-bold d-block mt-1">
                                        {itemdetail.motif_dmission}
                                      </span>
                                      <span className="text-muted fw-bold d-block mt-1">
                                        {itemdetail.services_rooms.map(
                                          (itemdetailser, index) => {
                                            return (
                                              <span className="text-muted fw-bold d-block mt-1">
                                                {itemdetailser.lit_name}
                                              </span>
                                            );
                                          }
                                        )}
                                      </span>
                                    </>
                                  );
                                })
                              : ""}
                            <span className="text-muted fw-bold d-block mt-1">
                              {item.service_name}
                            </span>
                            <span className="text-muted fw-bold d-block mt-1">
                              Le{" "}
                              {moment(
                                item.entree_reelle,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("D MMMM YYYY")}
                            </span>
                          </Link>
                        </td>

                        <td className="text-start pe-0">
                          <Link
                            to={{
                              pathname: `/patient/${item.num_admission}`,
                              state: { patientData: data[index] },
                            }}
                          >
                            <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                              <KTSVG
                                path="/media/icons/duotone/Navigation/Arrow-right.svg"
                                className="svg-icon-4"
                              />
                            </a>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
            </div>
       
          </div>
          
        </div>
      </div> */}
      {data?.map((item, index) => (
                      <PatientComponent
                      key={index}
                      data={item}
                      className="border border-primary mt-7 shadow"
                      title={item.patient_first_name + " " + item.patient_last_name}
                      description={item.service_name}
                      des2=""
                      additional={item.entree_reelle}
                      number1=""
                      ToRoute={`/patient/${item.num_admission}`}
                      imageUrl="/media/icons/duotone/Communication/Add-user.svg"
                      
                    />
                    ))}
    </div>
  );
};

export { TablesWidget1 };
