export const sortArrayAsc = <T, K extends keyof T>(
  array: T[],
  property: K,
  counter: number
): T[] => {
  const ascending = counter % 2 === 1;

  return [...array].sort((a, b) => {
    if (ascending) {
      if (a[property] < b[property]) return -1;
      if (a[property] > b[property]) return 1;
    } else {
      if (a[property] < b[property]) return 1;
      if (a[property] > b[property]) return -1;
    }

    return 0;
  });
};

export const toggleSortByDate = <T, K extends keyof T>(
  array: T[],
  property: K
): T[] => {
  let isAscending = true;
  const sortedArray = [...array].sort((a, b) => {
    const dateA = Date.parse(String(a[property]));
    const dateB = Date.parse(String(b[property]));

    if (isAscending) {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });

  isAscending = !isAscending; // This line should be outside the sort function

  return sortedArray;
};

export const calculateSumOfProperty = (
  array: any[],
  propertyName: string
): number => {
  if (!Array.isArray(array)) {
    throw new Error("Input is not an array");
  }

  if (array.length === 0) {
    return 0; // Return 0 if the array is empty
  }

  if (!propertyName) {
    throw Error("Property name is required");
  }

  return array.reduce((accumulator, currentValue) => {
    if (propertyName in currentValue) {
      const propertyValue = currentValue[propertyName];
      if (typeof propertyValue === "number") {
        return accumulator + propertyValue;
      } else if (typeof propertyValue === "string") {
        // Attempt to convert the string to a number using parseFloat or parseInt
        const numericValue = parseFloat(propertyValue);
        if (!isNaN(numericValue)) {
          return accumulator + numericValue;
        }
      }
      // Handle other data types here or skip them
      // You can also log a warning or error if needed.
    }
    return accumulator;
  }, 0);
};

export const sortArrayByFirstLetter = (array: any[]) => {
  return array.sort((a, b) => {
    const serviceA = a.service_name;
    const serviceB = b.service_name;

    if (isNaN(Number(serviceA[0]))) {
      if (isNaN(Number(serviceB[0]))) {
        // Both start with letters, compare them normally
        return serviceA.localeCompare(serviceB);
      } else {
        // A starts with a letter, but B starts with a number, so A comes first
        return -1;
      }
    } else {
      if (!isNaN(Number(serviceB[0]))) {
        // Both start with numbers, compare them normally
        return serviceA.localeCompare(serviceB);
      } else {
        // A starts with a number, but B starts with a letter, so B comes first
        return 1;
      }
    }
  });
};

export const filterObjectsByDateRange = (
  array: any[],
  startDate: string,
  endDate: string
) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  // const filteredData = array.filter((item) => {
  //   if (item.date_paiement && item.date_paiement instanceof Date) {
  //     return item.date_paiement >= start && item.date_paiement <= end;
  //   }
  //   return false;
  // });
  const filteredData = array.filter((d) => {
    var time = new Date(d.date_paiement).getTime();
    return start.getTime() < time && time < end.getTime();
  });

  console.log(filteredData);
  return filteredData;
};

export const toggleSortByDates = <T, K extends keyof T>(
  array: T[],
  property: K
): T[] => {
  let isAscending = true;

  const sortedArray = [...array].sort((a, b) => {
    const dateA = Date.parse(String(a[property]));
    const dateB = Date.parse(String(b[property]));

    if (isAscending) {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });

  // Toggle outside the sorting function
  isAscending = !isAscending;

  return sortedArray;
};

export const accumulateAndSortDates = (array: any[]) => {
  let uniqueDates = new Set<string>();
  array.forEach((item) => {
    uniqueDates.add(item.date_prescription);
  });

  let sortedDatesArray = Array.from(uniqueDates).sort();
  return sortedDatesArray;
};

 
