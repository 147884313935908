export const handlesignout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user_detail");
    sessionStorage.removeItem("tokenConsolidationClinic");
    sessionStorage.removeItem("tokenConsolidationService");
    sessionStorage.removeItem("cliniqueName");
    sessionStorage.removeItem("service_name");
    sessionStorage.removeItem("medcin");
    sessionStorage.removeItem("TokenConsolidationSejour");
    sessionStorage.removeItem("is_intern");
    window.location.reload();
  };