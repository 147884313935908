import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LocationState } from "../../_start/layout/core";
import { FeedsWidget4 } from "../../_start/partials/widgets";
import { KTSVG } from "../../_start/helpers";
import moment from "moment/moment";
import { lesfeuilles } from "./TypeFeuilles";
import { Browser } from '@capacitor/browser';
import CustomIndicator from "./components/CustomIndicator";



export const TousRadiologie = () => {
  const [ios, setIos] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  useEffect(() => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      setIos(true);
      console.log("is ios");
    } else {
      setIos(false);
      console.log("is not ios");
    }

    return () => {
      setIsMounted(false);
    };
  }, []);
  const location = useLocation();
  const Radiology = (location.state as LocationState)?.Radiodata;
  let history = useHistory();
  {
    console.log(Radiology);
  }

  const openBrowser = async (url: string ) => {
    const pov = await Browser.open({ url: url });
    // Do something with the URL, such as navigation or storing it in state
    return pov;
  };
  




  return (
    <div className="col-lg-12">
      <div className="card card-custom card-stretch shadow mb-5">
        <div className="card-header">
        <h3 className="card-title symbol symbol-40px me-5">
        <a
              className="btn btn-icon btn-color-primary  btn-active-color-gray btn-sm"
              style={{ marginRight: "10px" }}
              onClick={() => history.goBack()}
            >
              <KTSVG
                path="/media/icons/duotone/Navigation/Arrow-left copy.svg"
                className="svg-icon-4 "
              />
            </a>
            <span className="symbol-label bg-success">
              <i className="fas fa-x-ray fs-1x text-white" style={{ fontSize: "18px" }}></i>
            </span>
            <span
              className="text-success fw-bolder"
              style={{ marginLeft: "10px" }}
            >
              Radiologie{" "}
              <span className="fs-6 text-success fw-bold">
                
              </span>
            </span>
          </h3>
          <div className="card-toolbar">
            {/* view all */}
            {/* <button
                  type="button"
                  className="btn btn-sm  btn-color-white "
                  
                >
                  <Link className="text-white"
                          to={{
                            pathname: `/Avismedical`,
                            state: { avismedical: medical_advice },
                          }}
                        >Voir Tous</Link>
                
                </button> */}

            {/* end::Dropdown */}
          </div>
        </div>

        <div
          className="card-body"
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "2rem",
          }}
        >
           {Radiology?.map((radio, index) => (
                    <div key={index}>
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                      >
                        <div>
                          <span className="text-success  fw-bold fs-5">{radio.type_examen}</span>
                          <div className="details">
                            <span className="text-black fw-bold fs-7">{radio.medecin_radiologue}</span>
                            <br />
                            <span className="text-black fw-bold fs-7">
                          Le{" "}{moment(radio.date, 'YYYY-MM-DD HH:mm:ss').format('D MMMM YYYY')}
                      </span>
                          </div>
                        </div>
                        {radio.fileCompteRendu !== null && (
                            <>
                              {ios ? (
                                  <span
                                      onClick={() => {
                                        openBrowser(
                                          `${process.env.REACT_APP_DOMAINNAME}/${radio.fileCompteRendu}`
                                        );
                                      }}
                                  >
                            <CustomIndicator/>
                        </span>
                              ) : (
                                  <a
                                  href={`${process.env.REACT_APP_DOMAINNAME}/${radio.fileCompteRendu}`}
                                  >
                                    <CustomIndicator/>
                                  </a>
                              )}
                            </>

                        )}
                      </div>
                      {index !== Radiology.length - 1 && index !== 2 && (
                          <div className="separator separator-dotted my-3"></div>
                      )}
                    </div>
                ))}
        </div>
      </div>
    </div>
  );
};
