/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import axios from "../axios";

import {
  FeuilleUrgence,
  AvisData,
  LaboratoryData,
} from "../../_start/layout/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment/moment";
import ContentLoader from "react-content-loader";
import { FeedsWidget4 } from "../../_start/partials/widgets";
import { KTSVG } from "../../_start/helpers";
import { Browser } from "@capacitor/browser";
import CustomIndicator from "./components/CustomIndicator";


const DetailFeuilleUrgence = () => {
  useEffect(() => {
    GetDetailFeuilleUrgence();
  }, []);
  const location = useLocation();
  const [DetailFeuilleurgences, setFeuilleurgence] = useState<FeuilleUrgence[]>(
    []
  );
  const [DetailFeuilleurgencesAvis, setFeuilleurgenceAvis] = useState<
    AvisData[]
  >([]);
  const [DetailFeuilleurgencesLabo, setFeuilleurgenceLabo] = useState<
    LaboratoryData[]
  >([]);
  const [error, seterror] = useState("");
  const [loaderspinner, setloaderspinner] = useState(true);

  let history = useHistory();
  const GetDetailFeuilleUrgence = () => {
    const getTokenSejourFromSessionStorage = () => {
      return sessionStorage.getItem("TokenConsolidationSejour");
    };

    const params = {
      token_consolidation_sejour: getTokenSejourFromSessionStorage(),
    };
    axios
      .get("/patient/detail-feuille-urgence", { params })
      .then((response) => {
        if (response.data.params) {
          setFeuilleurgence(response.data.params.detailFeuilleUrgence);
          setFeuilleurgenceAvis(response.data.params.avis_specialiste);
          setFeuilleurgenceLabo(response.data.params.laboratory);
          setloaderspinner(false);
        } else if (response.status === 300) {
          seterror("error");
        } else {
          alert("An error occurred.");
        }
      });
  };
  const [ios, setIos] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      setIos(true);
      console.log("is ios");
    } else {
      setIos(false);
      console.log("is not ios");
    }

    return () => {
      setIsMounted(false);
    };
  }, []);

  const openBrowser = async (url: string) => {
    try {
      const pov = await Browser.open({ url: url });
      if (isMounted) {
        // Update state or perform other actions if component is still mounted
      }
      return pov;
    } catch (error) {
      console.error("Error opening browser:", error);
    }
  };

  return (
    <>
      <React.Fragment>
        <div className="col-lg-12">
          {DetailFeuilleurgences.map((feuille, id) => (
            <div className="card card-custom card-stretch-100 shadow mb-5 gradients">
              <div className="card-body">
                {loaderspinner ? (
                  <ContentLoader />
                ) : DetailFeuilleurgences.length === 0 ? (
                  <div className="text-center text-muted">
                    No urgence records
                  </div>
                ) : (
                  <>
                    <span className="text-white fw-bold fs-7">
                      Médecin traitant(e):{" "}
                      <span className="text-white fw-bold">
                        Dr {feuille.nom_praticien} {feuille.prenom_praticien}
                      </span>
                    </span>{" "}
                    <br />
                    <span className="text-white fw-bold fs-7">
                      Nom et prénom du patient:{" "}
                      <span className="text-white fw-bold">
                        {feuille.nom_patient} {feuille.prenom_patient}
                      </span>
                    </span>
                    <br />
                    <span className="text-white fw-bold fs-7">
                      Motif d'admission:{" "}
                      <span className="text-white fw-bold">
                        {feuille.motif_admission}
                      </span>
                    </span>
                    <br />
                    <span className="text-white fw-bold fs-7">
                      Date et heure d'admission: le{" "}
                      {moment(feuille.entree, "YYYY-MM-DD HH:mm:ss").format(
                        "D MMMM YYYY"
                      )}
                    </span>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex flex-column flex-md-row">
          <div className="flex-md-row-fluid">
            {/*begin::Card*/}
            <div className="card card-custom">
              <div className="card-header align-items-center  gradients d-flex flex-row justify-content-start ">
                <h3 className="card-title symbol symbol-40px">
                  <span className="symbol-label bg-light-success">
                    <i
                      className="fas fa-user-md fs-1x text-info"
                      style={{ fontSize: "18px" }}
                    ></i>
                  </span>
                </h3>
                <span
                  className="text-white  fw-bolder"
                  style={{ marginLeft: "10px" }}
                >
                  Detail Feuille Urgence
                </span>
              </div>

              <div className="card-body py-10">
              {loaderspinner ? (
                  <ContentLoader />
                ) : ""  }
                {/* <h2 className="text-dark fw-bolder fs-1 mb-5">Popular Articles</h2>*/}
                {DetailFeuilleurgences.map((feuille, id) => (
                  <>
                    {/*begin::Accordion*/}
                    <div className="accordion accordion-icon-toggle" id="kt_accordion_1">
                      {/*begin::Item*/}
                      <div className="mb-5">
                        {/*begin::Header*/}
                        <div
                          className="accordion-header py-3 d-flex"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_1_item_1"
                        >
                          <span className="accordion-icon">
                            <KTSVG
                              className="svg-icon-4"
                              path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                          </span>
                          <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Antecedenats
                          </h3>
                        </div>
                        {/*end::Header*/}

                        {/*begin::Body*/}
                        <div
                          id="kt_accordion_1_item_1"
                          className="fs-6 collapse show ps-10"
                          data-bs-parent="#kt_accordion_1"
                        >
                          <div className="d-flex flex-column   mb-5">
                            <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                              <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                              Médicaux
                            </div>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Cardiaques{" "}
                              {feuille.ant_cardiaque_bol === 0 ? (
                                <span className="text-muted  fw-normal  fs-8 ">
                                  (Non)
                                </span>
                              ) : feuille.ant_cardiaque_bol === 1 ? (
                                <span className="text-muted  fw-normal  fs-8 ">
                                  (Oui)
                                </span>
                              ) : (
                                ""
                              )}
                              :
                              {feuille.ant_cardiaque_bol === 0
                                ? ""
                                : feuille.ant_cardiaque_bol === 1
                                ? `${feuille.ant_cardiaque_obs}`
                                : ""}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-2">
                              Diabétiques :{" "}
                              {feuille.ant_diabetique_bol === 0 ? (
                                <span className="text-muted  fw-normal  fs-8 ">
                                  (Non)
                                </span>
                              ) : feuille.ant_diabetique_bol === 1 ? (
                                <span className="text-muted  fw-normal  fs-8 ">
                                  (Oui)
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                            <span className="text-black  fw-bold  fs-7">
                              Pulmonaires :{" "}
                              {feuille.ant_pumonaire_bol === 0
                                ? "Non"
                                : feuille.ant_pumonaire_bol === 1
                                ? `Oui ${feuille.ant_pumonaire_obs}`
                                : ""}
                            </span>
                            <span className="text-black  fw-bold  fs-7">
                              Neurologiques :{" "}
                              {feuille.ant_neurologique_bol === 0
                                ? "Non"
                                : feuille.ant_neurologique_bol === 1
                                ? `Oui ${feuille.ant_neurologique_obs}`
                                : ""}
                            </span>
                            <span className="text-black  fw-bold  fs-7">
                              Tabagisme :{" "}
                              {feuille.ant_tabagique_bol === null ||
                              feuille.ant_tabagique_bol === 0
                                ? "Non"
                                : feuille.ant_tabagique_bol === 1
                                ? "Oui"
                                : "Non"}
                            </span>
                            <span className="text-black  fw-bold  fs-7">
                              Alcolisme :{" "}
                              {feuille.alcoolisme === null ||
                              feuille.alcoolisme === 0
                                ? "Non"
                                : feuille.alcoolisme === 1
                                ? "Oui"
                                : "Non"}
                            </span>

                            {feuille.ant_autre !== null && (
                              <span className="text-black fw-bold fs-7">
                                Autre : {feuille.ant_autre}
                              </span>
                            )}
                          </div>
                          <div className="d-flex flex-column mb-5">
                            <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                              <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                              Chirurgicaux
                            </div>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Cardiaques : {feuille.ant_ant_chirurgicaux}
                            </span>
                          </div>

                          <div></div>
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Item*/}

                      {/*begin::Item*/}
                      <div className="mb-5">
                        {/*begin::Header*/}
                        <div
                          className="accordion-header py-3 d-flex collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_1_item_2"
                        >
                          <span className="accordion-icon">
                            <KTSVG
                              className="svg-icon-4"
                              path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                          </span>
                          <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Traitements Habituels
                          </h3>
                        </div>
                        {/*end::Header*/}

                        {/*begin::Body*/}
                        <div
                          id="kt_accordion_1_item_2"
                          className="collapse fs-6 show ps-10"
                          data-bs-parent="#kt_accordion_1"
                        >
                          <div className="d-flex flex-column   mb-5">
                            <span className="text-black  fw-bold  fs-7 me-5">
                              {feuille.traitement_habituels !== null
                                ? feuille.traitement_habituels
                                : "Aucun traitement habituel"}
                            </span>
                          </div>
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Item*/}

                      {/*begin::Item*/}
                      <div className="mb-5">
                        {/*begin::Header*/}
                        <div
                          className="accordion-header py-3 d-flex collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_1_item_3"
                        >
                          <span className="accordion-icon">
                            <KTSVG
                              className="svg-icon-4"
                              path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                          </span>
                          <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Histoire de la maladie
                          </h3>
                        </div>
                        {/*end::Header*/}

                        {/*begin::Body*/}
                        <div
                          id="kt_accordion_1_item_3"
                          className="collapse fs-6 show ps-10"
                          data-bs-parent="#kt_accordion_1"
                        >
                          <div className="d-flex flex-column mb-5">
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Date et heure de début :{" "}
                              {feuille.symptomes_date_debut}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Symptomes : {feuille.symptomes}
                            </span>
                          </div>
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Item*/}

                      {/*begin::Item*/}
                      <div className="mb-5">
                        {/*begin::Header*/}
                        <div
                          className="accordion-header py-3 d-flex collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_1_item_4"
                        >
                          <span className="accordion-icon">
                            <KTSVG
                              className="svg-icon-4"
                              path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                          </span>
                          <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Examen clinique à l'admission
                          </h3>
                        </div>
                        {/*end::Header*/}

                        {/*begin::Body*/}
                        <div
                          id="kt_accordion_1_item_4"
                          className="collapse fs-6 show ps-10"
                          data-bs-parent="#kt_accordion_1"
                        >
                          <div className="d-flex flex-column mb-5">
                            <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                              <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                              Constantes vitales
                            </div>

                            {feuille.constant.map(
                              (constants, constantsIndex) => (
                                <React.Fragment key={constantsIndex}>
                                  <span className="text-black  fw-bold  fs-7 me-5">
                                    {constants.constante_libelle}
                                  </span>
                                  <span className="text-black  fw-bold  fs-7 me-5">
                                    {constants.valeur_constante}
                                  </span>{" "}
                                  <br />
                                </React.Fragment>
                              )
                            )}
                          </div>
                          <div className="d-flex flex-column mb-5">
                            <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                              <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                              Examen neurologique
                            </div>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Glasgow : {feuille.ex_neu_glasgow}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-2">
                              Pupilles : {feuille.ex_neu_pubilles}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Déficit : {feuille.ex_neu_deficit}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-2">
                              Signes méningés :{" "}
                              {feuille.ex_neu_signes_menengies}
                            </span>
                          </div>
                          <div className="d-flex flex-column mb-5">
                            <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                              <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                              Examen cardiovasculaire
                            </div>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Ausculation : {feuille.ex_cardio_auscultation}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-2">
                              Pouls périphériques :
                              {feuille.ex_cardio_pouls_peripheriques}{" "}
                              {feuille.ex_cardio_pouls_perepheriques_percu_bol ===
                                1 && "Percu -"}{" "}
                              {feuille.ex_cardio_pouls_perepheriques_abolie_bol ===
                                1 && "Abolie -"}{" "}
                              {feuille.ex_cardio_pouls_perepheriques_fine_bol ===
                                1 && "Fine -"}{" "}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              OMI : {feuille.ex_cardio_omi}
                            </span>
                          </div>
                          <div className="d-flex flex-column mb-5">
                            <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                              <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                              Examen pleuroulmonaire
                            </div>
                            <span className="text-black  fw-bold  fs-7 me-2">
                              Ausculation : {feuille.ex_pleuro_auscultation}{" "}
                              {feuille.ex_pleuro_auscultation_normal_bol ===
                                1 && "Normal -"}{" "}
                              {feuille.ex_pleuro_auscultation_rales_siblant_bol ===
                                1 && "Rales siblant -"}{" "}
                              {feuille.ex_pleuro_auscultation_rales_crepitant_bol ===
                                1 && "Rales crepitant -"}{" "}
                              {feuille.ex_pleuro_auscultation_rales_ronflant_bol ===
                                1 && "Rales ronflant -"}{" "}
                              {feuille.ex_pleuro_auscultation_silence_auscultatoire_bol ===
                                1 && "silence auscultatoire -"}{" "}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Sueurs : {feuille.ex_pleuro_sueurs}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Cyanose : {feuille.ex_pleuro_cyanose}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Tirage : {feuille.ex_pleuro_tirage}
                            </span>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              Dspnée : {feuille.ex_pleuro_dyspnee}
                            </span>
                          </div>
                          <div className="d-flex flex-column mb-5">
                            <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                              <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                              Examen abdominal
                            </div>
                            <span className="text-black  fw-bold  fs-7 me-5">
                              {feuille.ex_abdominal}
                            </span>
                          </div>
                          {feuille.AUTRE !== null && (
                            <div className="d-flex flex-column mb-5">
                              <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                                <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                                Autre
                              </div>
                              <span className="text-black  fw-bold  fs-7 me-5">
                                {feuille.AUTRE}
                              </span>
                            </div>
                          )}
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Item*/}

                      {/*begin::Item*/}
                      <div className="mb-5">
                        {/*begin::Header*/}
                        <div
                          className="accordion-header py-3 d-flex collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_1_item_5"
                        >
                          <span className="accordion-icon">
                            <KTSVG
                              className="svg-icon-4"
                              path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                          </span>
                          <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Avis spécialiste
                          </h3>
                        </div>
                        {/*end::Header*/}

                        {/*begin::Body*/}
                        <div
                          id="kt_accordion_1_item_5"
                          className="collapse fs-6 show ps-10"
                          data-bs-parent="#kt_accordion_1"
                        >
                          {DetailFeuilleurgencesAvis.length > 0 ? (
                            DetailFeuilleurgencesAvis.map((avis, index) => (
                              <FeedsWidget4
                                key={index} // Make sure to add a unique key for each item in the array
                                className="my-custom-class"
                                specialite={avis.specialite}
                                avis_demandeur={avis.avis_demandeur}
                                specialiste_demandeur={
                                  avis.specialiste_demandeur
                                }
                                date_demande={avis.date_demande}
                                medecin_venu={avis.medecin_venu}
                                specialiste_avise={avis.specialiste_avise}
                                avis_reponse={avis.avis_reponse}
                              />
                            ))
                          ) : (
                            <span className="text-muted">
                              No avis specialiste available
                            </span>
                          )}
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Item*/}

                      {/*begin::Item*/}
                      <div className="mb-5">
                        {/*begin::Header*/}
                        <div
                          className="accordion-header py-3 d-flex collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_1_item_6"
                        >
                          <span className="accordion-icon">
                            <KTSVG
                              className="svg-icon-4"
                              path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                          </span>
                          <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Examens Paracliniques
                          </h3>
                        </div>
                        {/*end::Header*/}

                        {/*begin::Body*/}
                        <div
                          id="kt_accordion_1_item_6"
                          className="collapse fs-6 show ps-10"
                          data-bs-parent="#kt_accordion_1"
                        >
                          {DetailFeuilleurgencesLabo.length === 0 ? (
                            <div className="text-center text-muted">
                              No laboratory records
                            </div>
                          ) : (
                            DetailFeuilleurgencesLabo.slice(0, 3).map(
                              (datalabo, index) => (
                                <div key={index}>
                                  <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div className="d-flex flex-column justify-content-center align-items-between">
                                      <span className="text-muted fw-bold fs-5">
                                        Le{" "}
                                        {moment(
                                          datalabo.date,
                                          "YYYY-MM-DD HH:mm:ss"
                                        ).format("D MMMM YYYY")}
                                      </span>
                                      <div className="details">
                                        {datalabo.details.map(
                                          (detail, detailIndex) => (
                                            <React.Fragment key={detailIndex}>
                                              {detailIndex > 0 ? (
                                                <span className="text-black fw-bold fs-7">
                                                  {" "}
                                                  -{" "}
                                                </span>
                                              ) : null}
                                              <span className="text-black fw-bold fs-5 ">
                                                {detail.identifiant_analyse}
                                              </span>
                                            </React.Fragment>
                                          )
                                        )}
                                      </div>
                                      <span className="text-muted fw-bold fs-7">
                                        {datalabo.laboratoire}
                                      </span>
                                    </div>
                                    {datalabo.file !== null && (
                    <>
                      {ios ? (
                        <span
                          onClick={() => {
                            openBrowser(
                              `${process.env.REACT_APP_DOMAINNAME}/${datalabo.file}`
                            );
                          }}
                        >
                            <CustomIndicator/>
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            openBrowser(
                              `${process.env.REACT_APP_DOMAINNAME}/${datalabo.file}`
                            );
                          }}
                        >
                            <CustomIndicator/>
                        </span>
                      )}
                    </>
                  )}
                                  </div>
                                  {index !==
                                    DetailFeuilleurgencesLabo.length - 1 && ( // Check if this is not the last element
                                    <div className="separator my-3"></div>
                                  )}
                                </div>
                              )
                            )
                          )}
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Item*/}

                      {/*begin::Item*/}
                      <div className="mb-5">
                        {/*begin::Header*/}
                        <div
                          className="accordion-header py-3 d-flex collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_1_item_7"
                        >
                          <span className="accordion-icon">
                            <KTSVG
                              className="svg-icon-4"
                              path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                          </span>
                          <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Pharmacie Consommés
                          </h3>
                        </div>
                        {/*end::Header*/}

                        {/*begin::Body*/}
                        <div
                          id="kt_accordion_1_item_7"
                          className="collapse fs-6 show ps-10"
                          data-bs-parent="#kt_accordion_1"
                        >
                          Some plugins may ask for a purchase code for
                          registration/activation once installed, however, you
                          can simply ignore these messages as bundled plugins do
                          not require activation or registration. The plugin
                          will still work as intended with the theme once the
                          theme has been activated/registered.
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="mb-5">
                        {/*begin::Header*/}
                        <div
                          className="accordion-header py-3 d-flex collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_1_item_8"
                        >
                          <span className="accordion-icon">
                            <KTSVG
                              className="svg-icon-4"
                              path="/media/icons/duotone/Navigation/Right-2.svg"
                            />
                          </span>
                          <h3 className="fs-4 text-gray-800 fw-bold mb-0 ms-4">
                            Résultats
                          </h3>
                        </div>
                        {/*end::Header*/}

                        {/*begin::Body*/}
                        <div
                          id="kt_accordion_1_item_8"
                          className="collapse fs-6 show ps-10"
                          data-bs-parent="#kt_accordion_1"
                        >
                          <div className="d-flex flex-column   mb-5">
                            <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                              <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                              Décision :{" "}
                              <span
                                className="text-black  fw-bold  fs-7 me-5"
                                style={{ marginLeft: "5px" }}
                              >
                                {feuille.decision}
                              </span>
                            </div>
                            <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                              <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                              Observation :{" "}
                              <span
                                className="text-black  fw-bold  fs-7 me-5"
                                style={{ marginLeft: "5px" }}
                              >
                                {feuille.observation}
                              </span>
                            </div>
                            <div className="d-flex align-items-center text-muted  fw-bold fs-6 py-3">
                              <i className="fa fa-genderless text-primary fs-1 me-2"></i>
                              Conclusion :{" "}
                              <span
                                className="text-black  fw-bold  fs-7 me-5"
                                style={{ marginLeft: "5px" }}
                              >
                                {feuille.conclusion}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Item*/}
                    </div>
                  </>
                ))}
                {/*end::Accordion*/}
              </div>
            </div>
            {/*end::Card*/}
          </div>
        </div>
      </React.Fragment>
    </>
  );
};
export default DetailFeuilleUrgence;
