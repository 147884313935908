/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { Link } from "react-router-dom";
import moment from "moment";


type Props = {
  className: string;
  title: string;
  description: string | undefined;
  additional: string;
  number1: string;
  des2: string;
  ToRoute: string;
  imageUrl: string;
  innerPadding?: string;
  data:object
};

const PatientComponent: React.FC<Props> = ({
  className,
  title,
  description,
  additional,
  number1,
  des2,
  ToRoute,
  imageUrl,
  innerPadding,
  data,
}) => {
  return (
    <Link  to={{
      pathname: ToRoute,
      state: { patientData: data },
    }} className="">
      <div className="" style={{ marginTop: "25px", borderRadius: "30px" }}>
        <div className={`card ${className}  border-0 shadow bg-success`}>
          <div
            className={`card-header border-0 p-7 ${innerPadding} `}
            style={{ justifyContent: "start" }}
          >
            <div className="d-flex">
              {/* begin::Symbol */}
              <div className="symbol symbol-60px symbol-2by3 me-4 d-flex align-items-center">
                <span className="symbol-label bg-light-white">
                  <KTSVG
                    path={imageUrl}
                    className="svg-icon-1 svg-icon-success"
                  />
                </span>

                {/* <img src={toAbsoluteUrl(imageUrl)} alt="" className="mw-100" style={{ height:"70px",width:"100px" }} /> */}
              </div>
              {/* end::Symbol */}

              {/* begin::Section */}
              <div className="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                {/* begin::Title */}
                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                  <a
                    href=""
                    className="text-white fw-bolder text-hover-primary fs-6"
                  >
                    {title}
                  </a>
                  <span className="text-white fw-bold fs-7 my-1">
                    {description}
                  </span>
                  <span className="text-white fw-bold fs-7">
                    {des2}
                  </span>
                  <span className="text-white fw-bold fs-7">
                    <span className="text-white fw-bold">Admis Le{" "}
                              {moment(
                               additional,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("D MMMM YYYY HH:mm")}</span>
                  </span>
                </div>
                {/* end::Title */}

                {/* begin::Info */}

                {/* end::Info */}
              </div>
              {/* end::Section */}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export { PatientComponent };
