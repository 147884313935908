/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import moment from "moment";

type Props = {
  className: string;
  specialite: string | null;
  avis_demandeur: string | null;
  specialiste_demandeur: string;
  date_demande: string;
  medecin_venu: string;
  specialiste_avise: string;
  avis_reponse: string | null;
};
const FeedsWidget4: React.FC<Props> = ({
  className,
  specialite,
  avis_demandeur,
  specialiste_demandeur,
  date_demande,
  medecin_venu,
  specialiste_avise,
  avis_reponse,
}) => {
  return (
    <div className={`card ${className}`}>
      <div
        className="card-body"
        style={{ padding: "1.rem", paddingBottom: "0px", paddingTop: "15px" }}
      >
        <div className="">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-40px me-5 py-5">
              <div
                  className="btn btn-icon btn-sm symbol symbol-40px"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
              >
                <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/avatars/avatar_doc_man.jpg")}
                />
              </div>

            </div>
            <div className="d-flex flex-column flex-grow-1">
              <span
                  className="text-gray-800 text-hover-primary mb-1 fs-6 fw-bolder"
              >
                {specialiste_demandeur}
              </span>
              <span className="text-muted fw-bold">
                {specialite} || {" "}
                {medecin_venu == null ? <><span className="bullet bullet-dot bg-danger h-10px w-10px me-3"
                                                title="Le médecin n'est pas encore venu"></span><span>Demandé : {moment(date_demande, "YYYY-MM-DD HH:mm:ss").format(
                    "D MMMM HH:mm"
                )} </span></> : <> {moment(medecin_venu, "YYYY-MM-DD HH:mm:ss").format(
                    "D MMMM YY  HH:mm"
                )} <span className="bullet bullet-dot  h-10px w-10px me-5" style={{backgroundColor: "#0ba371"}}
                         title="Le médecin est venu"></span> </>}
                
              </span>
            </div>
          </div>

          <div>
            <p className="text-gray-800 fs-8 fw-normal">
              {avis_reponse ? avis_reponse : "Aucun avis "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export {FeedsWidget4};
