/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {KTSVG, toAbsoluteUrl} from "../../../helpers";

type Props = {
  className: string;
  innerPadding?: string;
};

const StatsWidget3: React.FC<Props> = ({ className, innerPadding = "" }) => {
  const [userdata,setuserdata]= useState(sessionStorage.getItem('user_detail'))

  // let profileImage: string;
  // if (userdata.sexe === 'm') {
  //   profileImage = toAbsoluteUrl("/media/avatars/avatar_doc_man.jpg");
  // } else if (userdata.sexe === 'f') {
  //   profileImage = toAbsoluteUrl("/media/avatars/avatar_doc_female.jpg");
  // } else {
  //   // If sexe is not "m" or "f", display a default image
    
  // }
  let profileImage = toAbsoluteUrl("/media/avatars/avatar_doc_man.jpg");
  return (
    <div className={`card bg-success ${className}`}>
      {/* begin::Body */}
      <div className={`card-body ${innerPadding}`}>
        {/* begin::Section */}
        <div className="d-flex align-items-center">
          {/* begin::Symbol */}


          <div className="symbol symbol-50px symbol-lg-75px symbol-fixed position-relative me-5">
            <img  src={profileImage} alt="image"/>
            <div
                className="position-absolute translate-middle bottom-0 start-100 rounded-circle border border-3 border-body h-9px w-9px"
                style={{ backgroundColor: "#17C635" }}
            >
            </div>
          </div>

          {/* end::Symbol */}

          {/* begin::Title */}
          <div>
            <a
              href="/profile"
              className="fs-4 text-white text-hover-primary fw-bolder"
            >
              Dr.
              {" "}{userdata ? JSON.parse(userdata).user_first_name : "undefdddined"} {" "}
              {userdata ? JSON.parse(userdata).user_last_name : "undefdddined"}
            </a>
          </div>
          {/* end::Title */}
        </div>
        {/* end::Section */}

        <div className="separator separator-dashed border-white my-5"></div>

        {/* begin::Info */}
        <div className="fw-bolder text-white  d-flex flex-row justify-content-center align-items-center ">
          <i className="far fa-user text-white fs-2 me-2"></i>
          <span className="d-block">{userdata ? JSON.parse(userdata).discipline : "undefdddined"}</span>

        </div>
        {/* end::Info */}

        {/* begin::Progress */}


        {/* end::Progress */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { StatsWidget3 };
