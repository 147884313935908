// CustomIndicator.tsx

import React from 'react';
import {KTSVG, toAbsoluteUrl} from '../../../_start/helpers';

interface CustomIndicatorProps {
}

const CustomIndicator: React.FC<CustomIndicatorProps> = ({  }) => {
    return (
        <img alt="" className="w-20px me-3" src={toAbsoluteUrl("/media/svg/files/pdf.svg")}/>
    );
};

export default CustomIndicator;
