/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  EngageWidget1,
  EngageWidget5,
  ListsWidget2,
  StatsWidget1,
  StatsWidget2,
  TablesWidget1,
  TablesWidget2,
  ListsWidget1,
  StatsWidget6,
  StatsWidget3
} from "../../../../_start/partials/widgets";
import { CreateAppModal } from "../_modals/create-app-stepper/CreateAppModal";
import { PageTitle } from "../../../../_start/layout/core";

export const StartDashboardPages: React.FC = () => {
  const [servicename, setservicename] = useState(
    sessionStorage.getItem("service_name")
  );
  const [show, setShow] = useState(false);
  return (
    <>
      {/* begin::Row */}
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-12">
          <StatsWidget3 className=" mb-7"/>
        </div>
        <div className="col-xl-12">
          <StatsWidget6 className=""/>
        </div>
      </div>
      {/* <div className="row g-0 g-xl-5 g-xxl-8">
       <ListsWidget1 className={""}/>
      </div> */}

      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-4">
          <StatsWidget1 className="card-stretch mb-5 mb-xxl-8" />
        </div>

        <div className="col-xl-8">
          <StatsWidget2 className="card-stretch mb-5 mb-xxl-8" />
        </div>
      </div>  */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-xl-12">
          <ListsWidget2 className="card-stretch mb-5 mb-xxl-8" />
        </div> */}

      {/* <div className="col-xl-8">
          <TablesWidget2 className="card-stretch mb-5 mb-xxl-8" />
        </div> */}
      {/* </div> */}
      {/* end::Row */}

      {/* begin::Modals */}
      {/* <CreateAppModal show={show} handleClose={() => setShow(false)} /> */}
      {/* end::Modals */}
    </>
  );
};
