import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {Link, Redirect} from "react-router-dom";
import {KTSVG, toAbsoluteUrl} from "../../../../_start/helpers";

import i18next from "../../../i18n";
import i18n from "../../../i18n";
import axios from ".././../../axios";

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
    const [loading, setLoading] = useState(true);
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [irrors, seterror] = useState("");
    const [selected, setselected] = useState("");

    useEffect(() => {
        const storedLanguage = sessionStorage.getItem("selectedLanguage") || "fr";
        if (storedLanguage) {
            setselected(storedLanguage);
            i18n.changeLanguage(storedLanguage);
        }
    }, []);

    const handleLogin = () => {
        const formattedUsername = username.trim().toUpperCase();
        const params = new URLSearchParams();
        params.append("username", formattedUsername);
        params.append("password", password);
        axios
            .post("/auth_mobile/login", params)
            .then((response) => {
                if (response.data.params.token) {
                    sessionStorage.setItem("token", response.data.params.token);
                    sessionStorage.setItem("medcin", response.data.params.user.medecin);
                    sessionStorage.setItem("is_intern", response.data.params.user.is_medecin_interne);
                    sessionStorage.setItem(
                        "user_detail",
                        JSON.stringify(response.data.params.user)
                    );
                    console.log(response.data.params.token);
                    setLoading(false);
                    window.location.reload();
                } else if (response.status === 300) {
                    seterror(i18next.t("error1_login"));
                } else {
                    alert("An error occurred.");
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    seterror(i18next.t("error1_login"));
                } else {
                    seterror("An error occurred while making the request.");
                } // Log the error for debugging

                setLoading(true);
            });
    };

    return (
        <div className="d-flex flex-column flex-lg-row-fluid">
            {/* Content */}
            <div className="d-flex flex-center flex-column flex-column-fluid">
                {/* Wrapper */}
                <div className="w-lg-500px p-10 p-lg-15 mx-auto">
                    {/* Form */}
                    <form
                        className="form w-100"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setLoading(false);
                            handleLogin();
                        }}

                        id="kt_login_signin_form"
                    >

                        {/* Heading */}
                        <div className="mb-10">
                            <h1 className="text-dark mb-3 display-6">
                                {i18next.t('title_login')}
                            </h1>
                            {/* Link */}
                            <div className="text-gray-400 fw-semibold fs-4">
                                {i18next.t('newHere_login')}
                                <Link
                                    to="/auth/registration"
                                    className="text-primary fw-bold"
                                    id="kt_login_signin_form_singup_button"
                                >
                                    {i18next.t('receivepass_login')}
                                </Link>
                            </div>
                        </div>
                        {/* Heading */}
                        {/* Input group */}
                        {irrors ? (
                            <div className="mb-lg-15 alert alert-danger ">
                                <div className="alert-text fs-7 font-weight-bold  ">{i18next.t('error1_login')}</div>
                            </div>
                        ) : ""}
                        <div className="fv-row mb-10">
                            {/* Label */}
                            <label className="form-label fs-6 fw-bold text-dark">{i18next.t('cin_login')}</label>
                            {/* Input */}
                            <input className="form-control form-control-lg form-control-solid" type="text" name="cin" autoComplete="off" onChange={(e) => { setusername(e.target.value) }} />
                        </div>
                        {/* Input group */}
                        {/* Input group */}
                        <div className="fv-row mb-10">
                            {/* Wrapper */}
                            <div className="d-flex flex-stack mb-2">
                                {/* Label */}
                                <label className="form-label fw-bold text-dark fs-6">{i18next.t('password_login')}</label>
                                {/* Link */}
                                <Link
                                    to="/auth/forgot-password"
                                    // className="text-primary fs-6 fw-bolder  "
                                    className="form-label fs-7  fw-bold text-primary text-hover-primary"
                                    id="kt_login_signin_form_password_reset_button"
                                >
                                    {i18next.t('forgoutPassword_login')}
                                </Link>


                            </div>
                            {/* Input */}
                            <input className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" onChange={(e) => { setpassword(e.target.value) }} />
                        </div>

                        {/* Input group */}
                        {/* Actions */}
                        <div className="">
                            {/* Submit button */}
                            <button type="submit" id="kt_sign_in_submit" className="btn bg-primary fw-bolder fs-6 px-8 py-4 my-3 me-3">
                                {loading ? <span className="indicator-label text-white">  {i18next.t('login_login')}</span> : <span className="indicator-progress text-white" style={{ display: "block" }}>
                  {i18next.t('pleasewait_login')}{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>}
                            </button>
                        </div>
                        {/* Actions */}
                    </form>
                    {/* Form */}
                </div>
                {/* Wrapper */}
            </div>
            {/* Content */}

        </div >
    );
}
