import React from "react";
import ReactDOM from "react-dom";
// import i18n from "./app/i18n";
// Redux
// https://github.com/rt2zz/redux-persist

// Axios
import axios from "axios";
// Apps
import { App } from "./app/App";
import "./_start/assets/sass/style.scss";
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Start mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/* const mock = */
/**
 * Inject Start interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

ReactDOM.render(
  <>
    <App basename={PUBLIC_URL} />
  </>,
  document.getElementById("root")
);
