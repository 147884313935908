/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_start/helpers";
import { PageTitle } from "../../_start/layout/core";
import TitlePage from "./Goback";

export const Privacy: React.FC = () => {
  return (
      <TitlePage title="Conditions générales d'utilisation et mentions légales"/>

  );
};
