import { toAbsoluteUrl } from "../../../helpers";

export function FallbackView() {
  return (
    <div className="splash-screen">
     
      <span>Loading ...</span>
    </div>
  );
}
