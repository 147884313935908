import React, {useState, useEffect} from "react";

import {Link, useHistory, useLocation} from "react-router-dom";
import {LocationState} from "../../_start/layout/core";
import {FeedsWidget4} from "../../_start/partials/widgets";
import {KTSVG} from "../../_start/helpers";
import moment from "moment/moment";
import {lesfeuilles} from "./TypeFeuilles";
import {Dossiermedical} from "../../_start/layout/core";


export const Tousdossiersmedical = () => {
    const history = useHistory()
    const location = useLocation();
    const dossierMedicale = (location.state as LocationState)?.dossiermedical;

    const [currentDateIndex, setCurrentDateIndex] = useState(0);
    const uniqueDates = Array.from(
        new Set(dossierMedicale?.flatMap((entry) => entry.date))
    );
    const [currentDate, setCurrentDate] = useState(uniqueDates[currentDateIndex]);
    const [filteredData, setFilteredData] = useState<Dossiermedical[]>([]);

    useEffect(() => {
        setCurrentDate(uniqueDates[currentDateIndex]);
    }, [currentDateIndex, uniqueDates]);

    useEffect(() => {
        const formattedCurrentDate = new Date(currentDate)
            .toISOString()
            .slice(0, 10);
        const filteredData = dossierMedicale?.filter(
            (item) =>
                new Date(item.date).toISOString().slice(0, 10) === formattedCurrentDate
        );
        setFilteredData(filteredData || []);
    }, [currentDate, currentDateIndex, dossierMedicale]);

    const handlePrevious = () => {
        setCurrentDateIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleNext = () => {
        setCurrentDateIndex((prevIndex) =>
            Math.min(prevIndex + 1, uniqueDates.length - 1)
        );
    };

    const isAtBeginning = currentDateIndex === 0;
    const isAtEnd = currentDateIndex === uniqueDates.length - 1;

    // Additional console.log for debugging
    console.log(currentDate, "1");
    console.log(filteredData, "2");

    return (
        <>
            <ul className="pagination mb-4">
                <li
                    className={`page-item next ${isAtEnd ? "disabled" : ""}`}
                    onClick={handleNext}
                >
          <span className="page-link">
            <i className="previous"></i>
          </span>
                </li>

                <li className="page-item">
          <span className="page-link text-white bg-success">
            {moment(currentDate, "YYYY-MM-DD HH:mm:ss").format("D MMMM YYYY")}
          </span>
                </li>
                <li
                    className={`page-item previous ${isAtBeginning ? "disabled" : ""}`}
                    onClick={handlePrevious}
                >
          <span className="page-link">
            <i className="next"></i>
          </span>
                </li>
            </ul>

            {filteredData?.map((dossier, index) => (
                <>
                    {dossier.items.map((item, innerIndex) => (
                        <Link
                            key={innerIndex}
                            to={{
                                pathname: item.type === "CDMFeuilleUrgence" ? "/DetailFeuilleUrgence" : "/DetailFeuilledesoin",
                                state: {OnedossiermedicaltoDetail: item},
                            }}
                            style={{
                                opacity: item.type === "CDMFeuilleAnesthesieBloc" ? 0.5  : 1,
                                pointerEvents: item.type === "CDMFeuilleAnesthesieBloc" ? 'none' : 'auto',
                            }}
                            className="text-decoration-none"
                        >
                            <div className="col-lg-12 " style={{marginTop: "25px"}} key={index}>
                                <div className="card card-custom card-stretch shadow">

                                    <div
                                        className="card-body"
                                    >
                                        <div className="d-flex " style={{
                                            opacity: item.type === "CDMFeuilleAnesthesieBloc" ? 0.5  : 1,
                                            pointerEvents: item.type === "CDMFeuilleAnesthesieBloc" ? 'none' : 'auto',
                                        }}>
                                            <div className="symbol symbol-65px me-5">
                      <span className="symbol-label bg-success">
                        <KTSVG
                            path="/media/icons/duotone/Communication/archive.svg"
                            className="svg-icon-1 svg-icon-white"
                        />
                      </span>
                                            </div>
                                            <div
                                                className="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                                                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                                                    {item.type === "CDMFeuilleUrgence" ? (
                                                        <Link
                                                            to={{
                                                                pathname: `/DetailFeuilleUrgence`,
                                                                state: {OnedossiermedicaltoDetail: item},
                                                            }}
                                                            className="text-gray-800 fw-bolder text-hover-primary fs-6"
                                                        >
                                                            {item.type
                                                                .replace("CDM", "")
                                                                .replace(/([A-Z])/g, " $1")
                                                                .trim()}
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to={{
                                                                pathname: `/DetailFeuilledesoin`,
                                                                state: {OnedossiermedicaltoDetail: item},
                                                            }}
                                                            className="text-gray-800 fw-bolder text-hover-primary fs-6"
                                                        >
                                                            {item.type
                                                                .replace("CDM", "")
                                                                .replace(/([A-Z])/g, " $1")
                                                                .trim()}
                                                        </Link>
                                                    )}

                                                    <span className="text-muted fw-bold fs-7">
                          {"Heure de Creation : "}
                                                        {moment(
                                                            item.date_creation,
                                                            "YYYY-MM-DD HH:mm:ss"
                                                        ).format("HH")}
                                                        {"H "}
                                                        {/* {dossier.items.length === 1 ? "Feuille" : "Feuilles"} */}
                        </span>
                                                    <span className="text-muted fw-bold fs-7 my-1 ">
                          {" "}
                                                        {lesfeuilles[item.type] ? (
                                                            <span
                                                                title={lesfeuilles[item.type].clean_name}
                                                                className={`badge ${
                                                                    lesfeuilles[item.type].color
                                                                }`}
                                                                style={{
                                                                    padding: "0.1em 0.80em",
                                                                    marginRight: "5px",
                                                                }}
                                                            >
                              {" "}
                                                                {lesfeuilles[item.type].abbreviation}
                            </span>
                                                        ) : (
                                                            <p>Unknown Type: {item.type}</p>
                                                        )}
                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </>
            ))}

        </>
    );
};
