import axios from "axios";
import { handlesignout } from "./pages/Handlesignout";


const getTokenFromSessionStorage = () => {
  return sessionStorage.getItem("token");
};

// Create an instance of Axios with custom configurations
const instance = axios.create({
  baseURL: "https://api.akditaldoc.ma/mobile/myakdital-doctor/v1",

  headers: {
    "Content-Type": "application/json",
    "Token-group": "1GV",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = getTokenFromSessionStorage();

    if (config.method === "post" && !config.url?.includes("/auth/login")) {
       config.headers["Content-Type"] = "application/x-www-form-urlencoded";
     } else {
       config.headers["Content-Type"] = "application/json"; // Adjusted content type for post requests
     }

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      handlesignout();
    }
    return Promise.reject(error);
  }
);

export default instance;
