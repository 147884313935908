import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "../axios";
import {
  Radiodata,
  AvisData,
  LocationState,
  Operationdata,
  LaboratoryData,
  AdmissionData,
  Prescription,
  Ordonnance,
  DossierItem,
  Dossiermedical,
} from "../../_start/layout/core";

import moment from "moment";
import "moment/locale/fr";
import { lesfeuilles } from "./TypeFeuilles";
import LaboratoireMypage from "./components/LaboratoireMypage";
import RadioMypage from "./components/RadioMypage";
import OperationsMypage from "./components/OperationsMypage";
import AvisSpecMypage from "./components/AvisSpecMypage";
import DossierMedicalMyPage from "./components/DossierMedicalMyPage";
import InfosAdmissionMyPage from "./components/InfosAdmissionMyPage";
import MedicalOrdonnance from "./components/MedicalOrdonnance";
import TitlePage from "./Goback";

function Mypage() {
  const location = useLocation();
  const patientData = (location.state as LocationState)?.patientData;
  const [error, seterror] = useState("");
  const [motif_admission, setmotifadmission] = useState<AdmissionData[]>([]);
  const [chambreservice, setchambreservice] = useState([]);
  const [operation, setoperation] = useState<Operationdata[]>([]);
  const [ordonnance, setoordonnance] = useState<Ordonnance[]>([]);
  const [radiology, setradiology] = useState<Radiodata[]>([]);
  const [laboratory, setlaboratory] = useState<LaboratoryData[]>([]);
  const [medical_advice, setmedical_advice] = useState<AvisData[]>([]);
  const [prescription, setprescription] = useState<Prescription[]>([]);
  const [dossiermedical, setdossiermedical] = useState<Dossiermedical[]>([]);
  const [loaderspinner, setloaderspinner] = useState(true);
  useEffect(() => {
    Getdatasejour();
  }, []);

  const [ios, setios] = useState(false);

  useEffect(() => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      setios(true);
      console.log("is ios");
    } else {
      setios(false);
      console.log("is not ios");
    }
  }, []);
  const [is_intern_extern, setisintern] = useState<number | null>(null);

  useEffect(() => {
    const is_intern_str = sessionStorage.getItem("is_intern");
    const is_intern_num = is_intern_str ? parseInt(is_intern_str, 10) : null;
    setisintern(is_intern_num);
  }, []);

  const Getdatasejour = () => {
    const getTokenFromSessionStorage = () => {
      return sessionStorage.getItem("token");
    };

    const params = {
      tokenConsolidationSejour: patientData?.sejour_token_consolidation,
    };
    // Retrieve the token
    const token = getTokenFromSessionStorage();
    if (patientData) {
      // Check if patientData is defined
      axios
        .get("/patient/info-sejours", { params })
        .then((response) => {
          if (response.data.params) {
            setloaderspinner(false);
            console.log(response.data.params);
            sessionStorage.setItem(
              "TokenConsolidationSejour",
              response.data.debug.tokenConsolidationSejour
            );
            setmotifadmission(response.data.params.reason_off_admission);
            setchambreservice(response.data.params.service_and_Room);
            setoperation(response.data.params.operations);
            setradiology(response.data.params.radiology);
            setoordonnance(response.data.params.ordonances);
            setlaboratory(response.data.params.laboratory);
            setdossiermedical(response.data.params.dossier_medical);
            setmedical_advice(response.data.params.medical_advice);
            setprescription(response.data.params.prescription);
          } else if (response.status === 300) {
            seterror("error");
          } else {
            alert("An error occurred.");
          }
        })
        .catch((error) => {
          console.error("Axios Error:", error);
          seterror("Network Error");
        });
    } else {
      // Handle the case where patientData is undefined
      console.error("patientData is undefined.");
    }
  };

  moment.locale("fr");
  return (
    <div className="mb-n2">
      <TitlePage title=""></TitlePage>
      <div className="row g-5">
        <InfosAdmissionMyPage
          patientData={patientData}
          motif_admission={motif_admission}
        />
        <LaboratoireMypage
        
          laboratory={laboratory}
          loaderspinner={loaderspinner}
        />
        <RadioMypage radiology={radiology} loaderspinner={loaderspinner} />
        <OperationsMypage operation={operation} loaderspinner={loaderspinner} />
        <AvisSpecMypage
          medical_advice={medical_advice}
          loaderspinner={loaderspinner}
        />
        {is_intern_extern === 1 ?  (<DossierMedicalMyPage
          dossiermedical={dossiermedical}
          lesfeuilles={lesfeuilles}
          loaderspinner={loaderspinner}
        />) : "" }
        
        <MedicalOrdonnance
          ordonnance={ordonnance}
          loaderspinner={loaderspinner}
        />
      </div>
    </div>
  );
}

export default Mypage;
