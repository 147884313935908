import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Make sure to import Link from 'react-router-dom'
import moment from "moment/moment"; // Make sure to import moment
import ContentLoader from "react-content-loader";
import {KTSVG, toAbsoluteUrl} from "../../../_start/helpers";
import { LaboratoryData } from "../../../_start/layout/core";
import { Browser } from "@capacitor/browser";
import CustomIndicator from "./CustomIndicator";

// Import any additional components or icons used in your original code

interface LaboratoireMypage {
  laboratory: LaboratoryData[];
  loaderspinner: boolean;
}

const LaboratoireMypage: React.FC<LaboratoireMypage> = ({
  laboratory,
  loaderspinner,
}) => {
  const [ios, setIos] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      setIos(true);
      console.log("is ios");
    } else {
      setIos(false);
      console.log("is not ios");
    }

    return () => {
      setIsMounted(false);
    };
  }, []);

  const openBrowser = async (url: string) => {
    try {
      const pov = await Browser.open({ url: url });
      if (isMounted) {
        // Update state or perform other actions if component is still mounted
      }
      return pov;
    } catch (error) {
      console.error("Error opening browser:", error);
    }
  };
  return (
    <div className="col-lg-4">
      <div className="card card-custom card-flush shadow mb-5">
        <div className="card-header">
          <h3 className="card-title symbol symbol-40px me-5">
            <span className="symbol-label bg-success">
              <i
                className="fas fa-microscope fs-1x text-white"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span
              className="text-success fw-bolder"
              style={{ marginLeft: "10px" }}
            >
              Laboratoire{" "}
              <span className="fs-6 text-success fw-bold">
                ({laboratory.length})
              </span>
            </span>
          </h3>
          <div className="card-toolbar">
            {/* view all */}
            <button
              type="button"
              className="btn btn-sm btn-color-white"
              style={{ display: "contents" }}
            >
              {loaderspinner ? (
              <ContentLoader />
          ) : laboratory.length === 0 ? (
              <div className="text-center text-muted"></div>
          ): (
              <Link
                className="text-muted"
                to={{
                  pathname: `/Laboratoire`,
                  state: { labodata: laboratory },
                }}
              >
                Voir Tous
              </Link>)}
            </button>
            {/* end::Dropdown */}
          </div>
        </div>
        <div className="card-body">
          {loaderspinner ? (
            <ContentLoader />
          ) : laboratory.length === 0 ? (
            <div className="text-center text-muted">No laboratory records</div>
          ) : (
            laboratory.slice(0, 3).map((datalabo, index) => (
              <div key={index}>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="d-flex flex-column justify-content-center align-items-between">
                    <span className="text-black fw-bold fs-7">
                      Le{" "}
                      {moment(datalabo.date, "YYYY-MM-DD HH:mm:ss").format(
                        "D MMMM YYYY"
                      )} | {datalabo.laboratoire}
                    </span>
                    <div className="details">
                      {datalabo.details.map((detail, detailIndex) => (
                        <React.Fragment key={detailIndex}>
                          {detailIndex > 0 ? (
                            <span className="text-muted fw-bold fs-7"> - </span>
                          ) : null}
                          <span className="text-success fw-bold fs-5 ">
                            {detail.identifiant_analyse}
                          </span>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  {datalabo.file !== null && (
                    <>

<button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        data-kt-menu-flip="top-end"
                                      >
                                        <Link
                                          to={{
                                            pathname: `/AffichePdfBilan`,
                                            state: {
                                              pdfaffiche: `${process.env.REACT_APP_DOMAINNAME}/${datalabo.file}`,
                                            },
                                          }}
                                          //  href={`http://192.168.206.215/files/private-download/${datalabo.file}`}
                                        >
                                          {/* You can replace the KTSVG component with your SVG or image */}
                                          {/* Assuming KTSVG is a component that renders an SVG */}
                                          <KTSVG
                                            path="/media/icons/duotone/Files/DownloadedFile.svg"
                                            className="svg-icon-1"
                                          />
                                        </Link>
                                      </button>













                      
                    </>
                  )}
                </div>
                {index !== laboratory.length - 1 && index !== 2 && (
                    <div className="separator separator-dotted my-3"></div>
                )}

              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default LaboratoireMypage;
