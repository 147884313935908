import React from 'react';
import { KTSVG } from '../../../_start/helpers'; // Adjust the path accordingly
import moment from 'moment/moment'; // Make sure to import moment
import { LocationState , PatientData , AdmissionData } from '../../../_start/layout/core'; // Replace 'your-types' with the appropriate type definition

interface InfosAdmissionMyPage {
  patientData: LocationState['patientData'] | undefined;
  motif_admission: AdmissionData[];
}

const InfosAdmissionMyPage: React.FC<InfosAdmissionMyPage> = ({ patientData, motif_admission }) => {
  return (
    <div className="col-lg-12 mb-3">
      <div className="card card-custom border-0 shadow bg-success h-150px">
        <div className="card-body d-flex align-items-start ">
          <div className="symbol symbol-60px symbol-2by3 me-4 d-flex align-items-center">
                <span className="symbol-label bg-light-white">
                  <KTSVG
                      path="/media/icons/duotone/Communication/Add-user.svg"
                      className="svg-icon-1 svg-icon-success"
                  />
                </span>

            {/* <img src={toAbsoluteUrl(imageUrl)} alt="" className="mw-100" style={{ height:"70px",width:"100px" }} /> */}
          </div>
          <div>
            <span className="text-white fw-bolder fs-5">
              {patientData?.patient_first_name} {patientData?.patient_last_name}
            </span>
            <br />
            {motif_admission.map((motif, index) => (
              <div key={index}>
                <span className="text-white fw-bold fs-7">
                  Date D'entrée :{' '}
                  {moment(motif.entree, 'YYYY-MM-DD HH:mm:ss').format('D MMMM YYYY')}
                </span>{' '}
                <br />
                <span className="text-white fw-bold fs-7">Motif : {motif.motif_dmission}</span>
                <br />
                {motif.services_rooms.slice(0, 1).map((serviceroom, index) => (
                  <div key={index}>
                    <span className="text-white fw-bold fs-7">{serviceroom.sercice_name} {serviceroom.chambre_name} - {serviceroom.lit_name}</span>
                    {/* <br />
                    <span className="text-white fw-bold fs-7">
                      
                    </span>
                    <br /> */}
                  </div>
                ))}
              </div>
            ))}
          </div>
          {/* <span className="text-muted fw-bold fs-7 ">
              Du 15 Janvier 2023 Au 17 Janvier 2023
            </span> */}

          {/* <span className="text-muted fw-bold fs-7">
              Médecin traitant(e): <span className="text-info fw-bold">Dr {Sejour?.particient_first_name} {Sejour?.particient_last_name}</span>
            </span> */}
        </div>
      </div>
    </div>
  );
};

export default InfosAdmissionMyPage;
