import React, {useEffect, useState} from 'react';
import moment from 'moment/moment'; // Make sure to import moment
import { KTSVG } from '../../../_start/helpers';
import ContentLoader from 'react-content-loader';
import { Radiodata } from '../../../_start/layout/core';
import { Link } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import CustomIndicator from "./CustomIndicator";





interface RadioMypages {
  radiology: Radiodata[];
  loaderspinner: boolean;
}



const RadioMypage: React.FC<RadioMypages> = ({ radiology, loaderspinner }) => {
  const [ios, setIos] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      setIos(true);
      console.log("is ios");
    } else {
      setIos(false);
      console.log("is not ios");
    }

    return () => {
      setIsMounted(false);
    };
  }, []);

  const openBrowser = async (url: string) => {
    try {
      const pov = await Browser.open({ url: url });
      if (isMounted) {
        // Update state or perform other actions if component is still mounted
      }
      return pov;
    } catch (error) {
      console.error("Error opening browser:", error);
    }
  };
  return (
      <div className="col-lg-4">
        <div className="card card-custom card-flush shadow mb-5">
          <div className="card-header">
            <h3 className="card-title symbol symbol-40px me-5">
            <span className="symbol-label bg-success">
              <i className="fas fa-x-ray fs-1x text-white" style={{ fontSize: "18px" }}></i>
            </span>
              <span className="text-success fw-bolder" style={{ marginLeft: "10px" }}>
              Radiologie <span className="fs-6 text-success fw-bold">({radiology.length})</span>
            </span>
            </h3>
            <div className="card-toolbar">
              {/* view all */}
              <button
                  type="button"
                  className="btn btn-sm btn-color-white"
                  style={{ display: "contents" }}
              >
                {loaderspinner ? (
              <ContentLoader />
          ) : radiology.length === 0 ? (
              <div className="text-center text-muted"></div>
          ): (
                <Link
                    className="text-muted"
                    to={{
                      pathname: `/Radiologie`,
                      state: { Radiodata: radiology },
                    }}
                >
                  Voir Tous
                </Link>)}
              </button>
              {/* end::Dropdown */}
            </div>
          </div>
          <div className="card-body">
            {loaderspinner ? (
                <ContentLoader />
            ) : radiology.length === 0 ? (
                <div className="text-center text-muted">No radiology records</div>
            ) : (
                radiology.slice(0, 2).map((radio, index) => (
                    <div key={index}>
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                      >
                        <div>
                          <span className="text-success  fw-bold fs-5">{radio.type_examen}</span>
                          <div className="details">
                            <span className="text-black fw-bold fs-7">{radio.medecin_radiologue}</span>
                            <br />
                            <span className="text-black fw-bold fs-7">
                          Le{" "}{moment(radio.date, 'YYYY-MM-DD HH:mm:ss').format('D MMMM YYYY')}
                      </span>
                          </div>
                        </div>
                        {radio.fileCompteRendu !== null && (
                            <>
                              {ios ? (
                                  <span
                                      onClick={() => {
                                        openBrowser(
                                            `${process.env.REACT_APP_DOMAINNAME}/${radio.fileCompteRendu}`
                                        );
                                      }}
                                  >
                            <CustomIndicator/>
                        </span>
                              ) : (
                                  <a
                                      href={`${process.env.REACT_APP_DOMAINNAME}/${radio.fileCompteRendu}`}
                                  >
                                    <CustomIndicator/>
                                  </a>
                              )}
                            </>

                        )}
                      </div>
                      {index !== radiology.length - 1 && index !== 2 && (
                          <div className="separator separator-dotted my-3"></div>
                      )}
                    </div>
                ))
            )}
          </div>
        </div>
      </div>
  );
};

export default RadioMypage;
