import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import i18next from "../../../i18n";
import axios from ".././../../axios";

export function Registration() {

  const [loading, setLoading] = useState(true);
  const [cin, setcin] = useState("");
  const [date_naissance, setdate_naissance] = useState("");
  const [irrors, seterror] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [redirectToAuthCode, setRedirectToAuthCode] = useState<boolean>();

  const items: Array<string | String> =
      [
        "ggg",
        i18next.t('error1_login')
      ];


  useEffect(() => {
    // handleReceivepass();
  }, []);

  const handleReceivepass = () => {
    setLoading(false);
    const formData = new FormData();
    formData.append('cin', cin);
    formData.append('date_naissance', date_naissance);

    axios.post('/auth_mobile/request_password',

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
    )
        .then((response) => {
          if (response.data.debug) {
            sessionStorage.setItem('pass', response.data.debug);
            const newPhoneNumber = response.data.params.telephone;
            setPhoneNumber(newPhoneNumber);
            console.log(response.data.debug);
            seterror(i18next.t('done') + newPhoneNumber + i18next.t('done2'));
            setLoading(true);
            setTimeout(() => {
              setRedirectToAuthCode(true);
              sessionStorage.setItem('cin',cin);
              sessionStorage.setItem('date_naissance',date_naissance);
              sessionStorage.setItem('newPhoneNumber',phoneNumber);
            }, 2000);
          }
        })
        .catch((error) => {

          if (error.response && error.response.status === 401) {
            seterror(items[0].toString());
          }
          else if(error.response && error.response.status === 400) {
            seterror(items[1].toString());
          }
          setLoading(true);
        });
  };

  return (

      <div className="d-flex flex-column flex-lg-row-fluid">
        {/* Content */}
        <div className="d-flex flex-center flex-column flex-column-fluid">
          {/* Wrapper */}
          <div className="w-lg-500px p-10 p-lg-15 mx-auto">
            {/* Form */}
            <form
                className="form w-100"
                onSubmit={(e) => {
                  e.preventDefault();
                  setLoading(false);
                  handleReceivepass();
                }}

                id="kt_login_signin_form"
            >

              {/* Heading */}
              <div className=" mb-10">
                {/* Title */}
                <h1 className="text-dark mb-3 display-6">
                  {i18next.t('receivepass')}
                </h1>
                {/* Link */}
                <div className="text-gray-400 fw-semibold fs-4">
                  {i18next.t('textpassword')}
                </div>
              </div>
              {/* Heading */}
              {/* Input group */}
              {console.log(irrors)}

              {irrors === i18next.t('done') + phoneNumber + i18next.t('done2') ? (
                  <div className="mb-lg-15 alert alert-success">
                    <div className="alert-text font-weight-bold">{irrors}</div>
                  </div>
              ) : (irrors === items[1] || irrors === items[2] ? (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{irrors}</div>
                  </div>
              ) : (
                  <></>
              ))}

              <div className="fv-row mb-10">
                {/* Label */}
                <label className="form-label fs-6 fw-bold text-dark">{i18next.t('cin')}</label>
                {/* Input */}
                <input className="form-control form-control-lg form-control-solid" type="text" name="cin" autoComplete="off"  onChange={(e) => { setcin(e.target.value) }} />
              </div>
              {/* Input group */}
              {/* Input group */}
              <div className="fv-row mb-10">
                {/* Wrapper */}
                <div className="d-flex flex-stack mb-2">
                  {/* Label */}
                  <label className="form-label fw-bold text-dark fs-6 mb-0">{i18next.t('datenaiss')}</label>
                  {/* Link */}
                </div>
                {/* Input */}
                <input className="form-control form-control-lg form-control-solid" type="date" name="password" autoComplete="off" onChange={(e) => { setdate_naissance(e.target.value) }} />
              </div>

              {/* Input group */}
              {/* Actions */}
              <div className="">
                {/* Submit button */}
                <button type="submit" id="kt_sign_in_submit" className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3 ">
                  {loading ? <span className="indicator-label">  {i18next.t('send')}</span> : <span className="indicator-progress" style={{ display: "block" }}>
                  {i18next.t('wait')}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>}
                </button>
                {/* Redirection après un délai */}
                {redirectToAuthCode && <Redirect to="/auth/auth-code" />}
                {/* ... */}

                <Link to="/auth/login">
                  <button
                      type="button"
                      id="kt_login_password_reset_form_cancel_button"
                      className="btn btn-light-primary fw-bolder fs-6 px-8 py-4 my-3"

                  >
                    {i18next.t('cancel')}
                  </button>
                </Link>{" "}

              </div>
              {/* Actions */}
            </form>
            {/* Form */}
          </div>
          {/* Wrapper */}
        </div>
        {/* Content */}

      </div >

  );
}
