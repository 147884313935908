import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthPage } from "../modules/auth";
import { Privacy } from "../pages/Privacy";
import { MasterLayout2 } from "../../_start/layout/MasterLayout2";

type ComponentType = React.ComponentType<any>;
const withMasterLayout = (Component: ComponentType) => (props: any) => (
    <MasterLayout2>
        <Component {...props} />
    </MasterLayout2>
);
export function PublicRoutes() {
  return (
    <Switch>
      <Route path="/auth" component={AuthPage} />
        <Route path="/condition-utilisation" component={withMasterLayout(Privacy)} />

      <Redirect to="/auth" />



    </Switch>
  );
}
