import React, { useState } from 'react';
import moment from 'moment/moment'; // Make sure to import moment
import { KTSVG } from '../../../_start/helpers';
import { Ordonnance } from '../../../_start/layout/core';
import ContentLoader from 'react-content-loader';
import axios from "../../axios"
import {Link} from "react-router-dom";
 // Adjust the path accordingly



interface MedicalOrdonnance {
    ordonnance: Ordonnance[];
  loaderspinner: boolean;
}

const MedicalOrdonnance: React.FC<MedicalOrdonnance> = ({ ordonnance, loaderspinner }) => {
  const [medicalAdvice, setMedicalAdvice] = useState('');
  const [notification, setNotification] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMedicalAdvice(event.target.value);
  };

  const handleSendClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (medicalAdvice.trim() === "") {
      setNotification("Ajoutez un résumé");
      return;
    }

    setNotification("Chargement...")
    
    axios
      .post("/medecin/add-avis-specialist", {
        
        resume: medicalAdvice
      })
      .then((response) => {
        if (response.data.params) {
          setNotification("Avis ajouté avec succès");
          setMedicalAdvice("");
          setError("");
        } else if (response.status === 300) {
          setError("error");
        } else {
          alert("An error occurred.");
        }
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        setError("Network Error");
        setError("");
      });
  };

  

  return (
    <div className="col-lg-4">
    <div className="card card-custom card-flush shadow mb-5">
      <div className="card-header ">
        <h3 className="card-title symbol symbol-40px  me-3">
          <span className="symbol-label bg-success">
            <i className="fas fa-notes-medical text-white" style={{ fontSize: "18px" }}></i>
          </span>
          {/* <KTSVG path="/media/icons/duotone/Files/File.svg"className="svg-icon-1 svg-icon-info"/> */}

          <span
            className="text-success  fw-bolder"
            style={{ marginLeft: "10px" }}
          >
            Medical Advice
          </span>
        </h3>
        <div className="card-toolbar">
          {/* view all */}
          <button
              type="button"
              className="btn btn-sm btn-color-white"
              style={{ display: "contents" }}
          >
            <Link
                className="text-muted"
                to={{
                  pathname: `/MedicalOrdonnance`,
                  state: { ordonnance: ordonnance },
                }}
            >
            </Link>
          </button>
          {/* end::Dropdown */}
        </div>
      </div>

      <div className="card-body">
        {loaderspinner ? (
          <ContentLoader />
        ) : ordonnance.length === 0 ? (
          <div className="text-center text-muted">
            No Ordonnances records
          </div>
        ) : (
          ordonnance.map((ordonnance, index) => (
            <div key={index}>
              <div className="d-flex flex-column justify-content-center align-items-between">
                <span className="text-muted fw-bold fs-5">
                  Le{" "}
                  {moment(
                    ordonnance.date_creation,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("D MMMM YYYY")}
                </span>
                <div className="details">
                  <React.Fragment>
                    <span className="text-black  fw-bold  fs-7">
                      {ordonnance.observation == null
                        ? "Null"
                        : ordonnance.observation}
                    </span>

                    <span className="text-black  fw-bold  fs-5 "></span>
                  </React.Fragment>
                </div>

                <span className="text-muted  fw-bold  fs-7">
                  {ordonnance.order_item?.map(
                    (ordonnanceItems, index) => (
                      <>
                      
                      <span
                        key={index}
                        className="text-muted fw-bold fs-7 me-3"
                      >
                        <b className='text-black' style={{ color:"black" }}>Autre</b> : {ordonnanceItems.autre}<br></br>
                        <b style={{ color:"black" }}>Nom</b> : {ordonnanceItems.name}<br></br>
                      </span></>
                      
                    )
                  )}
                </span>
              </div>
            </div>
          ))
        )}

        <br />
        <label className="form-label">Avis Medical : </label>
        <input
          type="text"
          className="form-control"
          placeholder="préscription"
          value={medicalAdvice}
          onChange={handleInputChange}
        />
        <span>{notification}</span>
        <button className="btn btn-success text-white mt-5" onClick={(e) => handleSendClick(e)}>
          Envoyer
        </button>
      </div>
    </div>
  </div>
  );
};

export default MedicalOrdonnance;
