import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageTitle } from "../../../_start/layout/core";
import { Overview } from "./components/Overview";
import i18n from "../../i18n";
import i18next from "i18next";
import SyncLanguage from "../../pages/SyncLanguage";
import TitlePage from "../../pages/Goback";


const ProfilePage: React.FC = () => {
  SyncLanguage();

  return (
    <Switch>
      <Route path="/profile/overview">
        <TitlePage title={`${i18next.t("profile")}`}></TitlePage>
        <Overview />
      </Route>
      =
      <Redirect from="/profile" exact={true} to="/profile/overview" />
      <Redirect to="/profile/overview" />
    </Switch>
  );
};

export { ProfilePage };
