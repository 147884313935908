import React from "react";
import { Link } from "react-router-dom"; // Make sure to import Link from 'react-router-dom'
import { KTSVG } from "../../../_start/helpers"; // Adjust the path accordingly
import moment from "moment/moment"; // Make sure to import moment
import { lesfeuilles } from "../TypeFeuilles";
import { Dossiermedical, DossierItem } from "../../../_start/layout/core";
import ContentLoader from "react-content-loader";
// Replace 'your-types' with the appropriate type definition

interface DossierMedicalMyPage {
  dossiermedical: Dossiermedical[];
  lesfeuilles: typeof lesfeuilles;
  loaderspinner: boolean;
}

const DossierMedicalMyPage: React.FC<DossierMedicalMyPage> = ({
  dossiermedical,
  loaderspinner,
  lesfeuilles,
}) => {
  return (
    <div className="col-lg-4">
      <div className="card card-custom card-flush shadow mb-5">
        <div className="card-header ">
          <h3 className="card-title symbol symbol-40px me-3">
            <span className="symbol-label bg-success">
              <i
                className="fas fa-stethoscope fs-1x text-white"
                style={{ fontSize: "18px" }}
              ></i>
            </span>
            <span
              className="text-success fw-bolder"
              style={{ marginLeft: "10px" }}
            >
              Dossier Medical{" "}
              <span className="fs-6 text-success fw-bold">
                ({dossiermedical.length} j)
              </span>
            </span>
          </h3>
          <div className="card-toolbar">
            {/* view all */}
            <button
              type="button"
              className="btn btn-sm btn-color-white"
              style={{ display: "contents" }}
            >
              {loaderspinner ? (
                ""
              ) : dossiermedical.length === 0 ? (
                <div className="text-center text-muted"></div>
              ) : (
                <Link
                  className="text-muted"
                  to={{
                    pathname: `/DossiersMedical`,
                    state: { dossiermedical: dossiermedical },
                  }}
                >
                  Voir Tous
                </Link>
              )}
            </button>
          </div>
        </div>
        <div className="card-body">
          {loaderspinner ? (
            <ContentLoader />
          ) : dossiermedical.length === 0 ? (
            <div className="text-center text-muted">
              No dossiermedical records
            </div>
          ) : (
            dossiermedical
              .reverse()
              .slice(0, 3)
              .map((dossier, index) => (
                <div className="d-flex mb-7" key={index}>
                  <div className="symbol symbol-65px me-5">
                    <span className="symbol-label bg-success">
                      <i
                        className="fas fa-file-medical-alt"
                        style={{ color: "white", fontSize: "x-large" }}
                      ></i>
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                    <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                      <Link
                        to={{
                          pathname: `/DossiersMedical`,
                          state: { dossiermedical: dossiermedical },
                        }}
                        className="text-gray-800 fw-bolder text-hover-primary fs-6"
                      >
                        {moment(dossier.date, "YYYY-MM-DD").format(
                          "D MMMM YYYY"
                        )}
                      </Link>
                      <span className="text-muted fw-bold fs-7 my-1">
                        {dossier.items.map(
                          (dossieritem: DossierItem, index) => (
                            <React.Fragment key={index}>
                              {" "}
                              {lesfeuilles[dossieritem.type] ? (
                                <span
                                  title={
                                    lesfeuilles[dossieritem.type].clean_name
                                  }
                                  className={`badge ${
                                    lesfeuilles[dossieritem.type].color
                                  }`}
                                  style={{
                                    padding: "0.1em 0.80em",
                                    marginRight: "5px",
                                  }}
                                >
                                  {" "}
                                  {lesfeuilles[dossieritem.type].abbreviation}
                                </span>
                              ) : (
                                <p>Unknown Type: {dossieritem.type}</p>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </span>
                      <span className="text-muted fw-bold fs-7">
                        {" ("}
                        {dossier.items.length}
                        {") "}
                        {dossier.items.length === 1 ? "Feuille" : "Feuilles"}
                      </span>
                    </div>

                    <div className="text-end py-lg-0 py-2">
                      <td className="text-start pe-0">
                        <Link
                          to={{
                            pathname: `/DossiersMedical`,
                            state: { dossiermedical: dossiermedical },
                          }}
                        >
                          <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <KTSVG
                              path="/media/icons/duotone/Navigation/Arrow-right.svg"
                              className="svg-icon-4"
                            />
                          </a>
                        </Link>
                      </td>
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  );
};

export default DossierMedicalMyPage;
