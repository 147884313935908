import React, { useEffect, useState } from "react";
import i18n from "../i18n";
import i18next from "i18next";

export default function SyncLanguage() {
  const storedLanguage = sessionStorage.getItem("selectedLanguage") || "fr";
  const [currentLanguage, setCurrentLanguage] = useState("");

  useEffect(() => {
    const storedLanguage = sessionStorage.getItem("selectedLanguage") || "fr";
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  // Update current language when stored language changes
  useEffect(() => {
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, [storedLanguage]);
}
